<div class="header-row">
    <h1>Product asset export setups</h1>

    <nuc-button-bar>
        <nuc-button-primary
            *ngIf="permissions.POST_PRODUCT_ASSET_EXPORT_SETUP | userIsAllowedTo"
            text="Add setup"
            (click)="onCreateSetupClicked()">
        </nuc-button-primary>
    </nuc-button-bar>
</div>

<ng-container *ngIf="permissions.GET_PRODUCT_ASSET_EXPORT_SETUPS | userIsAllowedTo; else noPermissionState">
        <nuc-table [items]="items"
                   [selectionMode]="ESelectionMode.EXPAND"
                   [columns]="columns"
                   [loading]="loading"
                   [emptyStateTitle]="searchValue ? 'No matching results.' : 'This section is empty. Add the first item.'"
                   [tableId]="tableId"
                   [actions]="actions"
                   (actionClicked)="onActionClicked($event)"
                   [prefixStorageKey]="storageKey"
                   (sortOptionChange)="onSortOptionChanged($event)" >
            <nuc-input button-bar-left
                       [(data)]="searchValue"
                       prefix="nucicon_search"
                       (debounceValueChanged)="onSearchBarValueUpdated($event)"
                       placeholder="Search">
            </nuc-input>

            <paginator button-bar-right
                       [viewId]="tableId"
                       [disableNextPage]="disableNextPage"
                       [loading]="loading">
            </paginator>
        </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission_small.svg"
                               small="true"
                               title="You or your team does not have permissions to view this section. Contact the team owner to enable this section.">
    </nuc-empty-state-component>
</ng-template>
