import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {ApiService} from './api.service';
import {AnimatedContentTemplateModel} from '../../models/api/animated-content-template.model';

@Injectable({
    providedIn: 'root'
})
export class AnimatedContentTemplateApiService extends ApiService<AnimatedContentTemplateModel> {
    constructor() {
        super([ApiConstants.API_METHOD_ANIMATED_CONTENT_TEMPLATES], AnimatedContentTemplateModel);
    }
}
