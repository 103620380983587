<nuc-button-bar>
    <nuc-button-primary *ngIf="permissions.POST_CONNECTION | userIsAllowedTo" text="Add connection" (click)="addConnectionClicked()"></nuc-button-primary>
</nuc-button-bar>
<nuc-table emptyStateTitle="No connections setup yet"
           [items]="connections"
           [columns]="columns"
           [loading]="!subscription?.closed"
           [actions]="actions"
           [selectionMode]="selectionMode"
           [tableId]="tableId"
           [prefixStorageKey]="storageKey"
           (itemClicked)="onItemClicked($event)"
           (actionClicked)="onActionClicked($event)"
           (sortOptionChange)="onSortOptionChanged($event)">
    <ng-container button-bar-right="">
        <paginator [viewId]="viewId"
                   [disableNextPage]="disableNextPage"
                   [loading]="!subscription?.closed">
        </paginator>
    </ng-container>
</nuc-table>
