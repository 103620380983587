import {inject, Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {finalize} from 'rxjs/operators';
import {CampaignModel} from '../../../../models/api/campaign.model';
import {CampaignsApiService} from '../../../../api/services/campaigns.api.service';
import {Toaster} from '../../../../classes/toaster.class';
import {RelayterTableDataProvider} from '../../../../classes/relayter-table-data-provider.service';
import {TableSortOptions} from '../../../../api/table-sort-options';

@Injectable()
export class CampaignDataProvider extends RelayterTableDataProvider<CampaignModel> {
    private campaignsApiService = inject(CampaignsApiService);

    constructor(public sortOptions: TableSortOptions) {
        super(sortOptions);
    }

    public retrieveData(): void {
        this.loading = true;

        this.campaignsApiService.getCampaigns(this.searchValue, this.pageSize, 0, this.filterValues, this.sortOptions,
            this.apiCursor.getCursor(this.pageIndex))
            .pipe(
                finalize(() => this.loading = false),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: (result) => {
                    this.data = result.items;
                    this.disableNextPage = !result.hasNext;
                    if (this.data.length > 0) {
                        this.setCursor();
                    }
                },
                error: Toaster.handleApiError
            });
    }
}
