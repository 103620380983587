<form class="mapping-form" [formGroup]="formGroup">
    <nuc-form-field label="Product datafield">
        <nuc-dropdown formControlName="dataField"
                      placeholder="Select a product datafield"
                      [searchable]="true"
                      [nullOption]="false"
                      [items]="dataFieldOptions"
                      [required]="true"
                      (requestData)="searchProductDataFields($event)">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="Property">
        <nuc-input
            formControlName="property"
            placeholder="Fill in the property path">
        </nuc-input>
    </nuc-form-field>
</form>
