<ng-container *ngIf="dataSubscription?.closed; else loadingState">

    <nuc-button-bar>
        <nuc-button-secondary *ngIf="formConfig" icon="nucicon_eye" (click)="openFormPreviewModal()"></nuc-button-secondary>
        <nuc-button-secondary *ngIf="formConfig ? (PERMISSIONS.PUT_FORM | userIsAllowedTo) : (PERMISSIONS.POST_FORM | userIsAllowedTo)"
                              text="Edit form"
                              (click)="onEditFormClick()">
        </nuc-button-secondary>
    </nuc-button-bar>

    <nuc-empty-state-component *ngIf="!formConfig"
                               imageUrl="assets/images/empty_states/data-management.svg"
                               title="Setup your form"
                               subtitle="Please setup your form in edit mode.">
    </nuc-empty-state-component>

    <data-fields-information-form
        *ngIf="formConfig"
        [form]="form"
        [formConfig]="formConfig"
        [dataFields]="[]"
        [variants]="variants">
    </data-fields-information-form>

</ng-container>

<ng-template #formPreview>
    <data-fields-information-form
        *ngIf="formConfig"
        [form]="form"
        [formConfig]="formConfig"
        [dataFields]="[]"
        [variants]="variants">
    </data-fields-information-form>
</ng-template>

<ng-template #loadingState>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>
