<ng-container *ngIf="permissions.GET_CAMPAIGN_PUBLICATIONS | userIsAllowedTo; else noPermissionState">
    <nuc-button-bar>
        <paginator [viewId]="tableId"
                   [loading]="!publicationsSubscription?.closed"
                   [disableNextPage]="disableNextPage">
        </paginator>
    </nuc-button-bar>

    <div class="grid-view" *ngIf="publicationsSubscription?.closed; else loadingIndicator">

        <ng-container *ngIf="publications?.length; else emptyState">

            <nuc-media-card-view *ngFor="let publication of publications"
                                 (click)="campaignPublicationClicked(publication)"
                                 [title]="publication.name"
                                 [hoverImage]="false"
                                 [hoverCard]="true"
                                 [media]="publication | publicationMedia"
                                 size="MEDIUM">
                <div content>
                    <span>{{publication.workflow.name | titlecase}}</span>
                </div>

                <div footer class="footer">
                    <nuc-button-secondary
                        *ngIf="permissions.UPDATE_CAMPAIGN_PUBLICATION | userIsAllowedTo"
                        icon="nucicon_edit"
                        nucTooltip="Edit"
                        (click)='$event.stopPropagation(); openEditModal(publication._id)'>
                    </nuc-button-secondary>

                    <nuc-button-secondary
                        icon="nucicon_trash_fill"
                        nucTooltip="Delete"
                        *ngIf="permissions.DELETE_CAMPAIGN_PUBLICATION | userIsAllowedTo"
                        (click)='$event.stopPropagation(); openDeletePublicationDialog(publication)'>
                    </nuc-button-secondary>

                    <nuc-button-secondary
                        icon="nucicon_duplicate"
                        nucTooltip="Duplicate"
                        *ngIf="publication.channelIsPrintPublication()"
                        (click)='$event.stopPropagation(); openCopyPublicationDialog(publication)'>
                    </nuc-button-secondary>
                </div>
            </nuc-media-card-view>

        </ng-container>

    </div>

</ng-container>

<ng-template #emptyState>
    <nuc-empty-state-component
        imageUrl="assets/images/empty_states/campaign_detail.svg"
        title="No campaign publications"
        subtitle="Let's create a publication">
    </nuc-empty-state-component>
</ng-template>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>

<ng-template #loadingIndicator>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>
