import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {CampaignModel} from '../../models/api/campaign.model';
import {Observable} from 'rxjs';
import {ARPagedResponseDataModel} from '@relayter/core';
import {ApiService} from './api.service';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {NewCursor} from '../new-api-cursor';
import {TableSortOptions} from '../table-sort-options';

@Injectable({
    providedIn: 'root'
})
export class CampaignsApiService extends ApiService<CampaignModel> {
    constructor() {
        super([ApiConstants.API_GROUP_CAMPAIGNS], CampaignModel);
    }

    public getCampaigns(search: string, limit?: number, offset?: number,
                        filters?: Record<string, any>, tableSortOptions: TableSortOptions = new TableSortOptions(),
                        cursor?: NewCursor): Observable<ARPagedResponseDataModel<CampaignModel>> {

        const request = new ApiPagedRequest(this.groups, this.model, limit, offset, tableSortOptions, cursor, search, filters);

        return this.apiRequestService.find(request);
    }
}
