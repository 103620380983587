@if (form) {
    <form [formGroup]="form">
        <nuc-form-field label="Event">
            <nuc-dropdown formControlName="event"
                          [items]="events"
                          placeholder="Select an event">
            </nuc-dropdown>
        </nuc-form-field>
        <nuc-form-field label="Endpoint">
            <nuc-input formControlName="endpoint"
                       placeholder="Fill in endpoint for delivering events">
            </nuc-input>
        </nuc-form-field>
    </form>
}
