import {GroupFormComponent} from './group-form/group-form.component';
import {NgModule} from '@angular/core';
import {GroupsComponent} from './groups.component';
import {RDModule} from '@relayter/rubber-duck';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ComponentsModule} from '../../../components/components.module';
import {CommonModule} from '@angular/common';
import {GroupDetailComponent} from './group-detail/group-detail.component';
import {PipesModule} from '../../../pipes/pipes.module';

@NgModule({
    imports: [RDModule, FormsModule, ReactiveFormsModule, ComponentsModule, CommonModule, PipesModule],
    providers: [],
    declarations: [GroupsComponent, GroupFormComponent, GroupDetailComponent],
    exports: [GroupsComponent]
})
export class GroupsModule {
}
