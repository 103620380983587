import {Component, OnInit} from '@angular/core';
import {PaginatorService} from '../../../../../components/paginator/paginator.service';
import {StaticContentRulesetItemTableComponent} from '../static-content-ruleset-item-table.component';
import {switchMap, takeUntil} from 'rxjs/operators';
import {Toaster} from '../../../../../classes/toaster.class';
import {StaticContentRulesetItemGroupModel} from '../../../models/api/static-content-ruleset-item-group.model';
import {StaticContentRulesetBaseItem, StaticContentRulesetItemModel} from '../../../models/api/static-content-ruleset-item.model';
import {AppConstants} from '../../../../../app.constants';
import {RLDatePipe} from '../../../../../pipes/rl-date.pipe';
import {AnchorOption, ScaleOption} from '../../../static-content-ruleset.constants';
import {EColumnSize, FullModalService, IReorderEvent, NucDialogService} from '@relayter/rubber-duck';
import {UserSettingsStorageService} from '../../../../../api/services/user-settings-storage.service';
import {StaticContentRulesetService} from '../../../../../api/services/static-content-ruleset.service';
import {DesignLibraryService} from '../../../../../api/services/design-library.service';
import {PropertyService} from '../../../../../api/services/property.service';
import {UserIsAllowedToPipe} from '../../../../../pipes/user-is-allowed-to.pipe';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'static-content-ruleset-lib-item-table-component',
    templateUrl: './static-content-ruleset-lib-item-table.component.html',
    styleUrls: ['../static-content-ruleset-item-table.component.scss'],
    providers: [PaginatorService]
})
export class StaticContentRulesetLibItemTableComponent extends StaticContentRulesetItemTableComponent implements OnInit {
    public itemGroups: StaticContentRulesetItemGroupModel[] = [];

    constructor(userSettingsStorageService: UserSettingsStorageService,
                protected formatRulesetService: StaticContentRulesetService,
                protected indesignLibraryService: DesignLibraryService,
                protected propertyService: PropertyService,
                protected userIsAllowedToPipe: UserIsAllowedToPipe,
                protected route: ActivatedRoute,
                protected router: Router,
                protected dialogService: NucDialogService,
                protected fullModalService: FullModalService,
                protected paginatorService: PaginatorService) {
        super(userSettingsStorageService,
            formatRulesetService,
            indesignLibraryService,
            propertyService,
            userIsAllowedToPipe,
            route,
            router,
            dialogService,
            fullModalService,
            paginatorService);
    }

    public ngOnInit() {
        super.ngOnInit();
        this.getItemGroups();
    }

    public setupTableColumns(): void {
        this.columns.push(...[
            {
                title: 'Name',
                key: 'name',
                size: EColumnSize.BASE,
                sortProperty: 'name'
            },
            {
                title: 'Library item',
                key: 'libraryItem',
                size: EColumnSize.BASE,
                sortProperty: 'libraryItem'
            },
            {
                title: 'Layer',
                key: 'layer',
                sortProperty: 'layer'
            },
            {
                title: 'Item group',
                key: 'itemGroup'
            },
            {
                title: 'Anchor',
                key: 'anchor',
                format: (value) => AnchorOption.getByValue(value)?.getTitle(),
                sortProperty: 'anchor'
            },
            {
                title: 'X',
                key: 'defaultX',
                sortProperty: 'defaultX'
            },
            {
                title: 'Y',
                key: 'defaultY',
                sortProperty: 'defaultY'
            },
            {
                title: 'Scale',
                key: 'scale',
                format: (value) => ScaleOption.getByValue(value)?.getTitle(),
                sortProperty: 'scale'
            },
            {
                title: 'Width',
                key: 'defaultWidth',
                sortProperty: 'defaultWidth'
            },
            {
                title: 'Height',
                key: 'defaultHeight',
                sortProperty: 'defaultHeight'
            },
            {
                title: 'Date created',
                key: 'createdAt',
                sortProperty: 'createdAt',
                format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
            },
            {
                title: 'Date modified',
                key: 'updatedAt',
                sortProperty: 'updatedAt',
                format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
            }
        ]);
    }

    public getItemGroups(): void {
        this.formatRulesetService.getStaticContentRulesetItemGroups(
            this.ruleSetId,
            100)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((results) => {
                this.itemGroups = results.items;
            }, Toaster.handleApiError);
    }

    public itemGroupChange(itemGroup: StaticContentRulesetItemGroupModel) {
        this.itemGroup = itemGroup;
        this.setPageIndex();
    }

    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    protected setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(this.permissions.GET_STATIC_CONTENT_RULESET_ITEM)) {
            if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PUT_STATIC_CONTENT_RULESET_ITEM)) {
                this.actions = [AppConstants.TABLE_ACTION_TYPES.EDIT];
            }
            if (this.userIsAllowedToPipe.transform([AppConstants.PERMISSIONS.COPY_STATIC_CONTENT_RULESET_ITEM,
                AppConstants.PERMISSIONS.POST_STATIC_CONTENT_RULESET_ITEM])) {
                this.actions = this.actions?.concat(AppConstants.TABLE_ACTION_TYPES.COPY) || [AppConstants.TABLE_ACTION_TYPES.COPY];
            }
            if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_STATIC_CONTENT_RULESET_ITEM)) {
                this.actions = this.actions?.concat(AppConstants.TABLE_ACTION_TYPES.DELETE) || [AppConstants.TABLE_ACTION_TYPES.DELETE];
            }
        }
    }

    protected deleteRule(rule: StaticContentRulesetBaseItem): void {
        this.formatRulesetService.deleteStaticContentRulesetLibraryItem(this.ruleSetId, rule._id)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => {
                    Toaster.success('Successfully deleted rule');
                    this.getRulesetItems();
                },
                Toaster.handleApiError);
    }

    protected copyRule(rule: StaticContentRulesetItemModel): void {
        rule = rule as StaticContentRulesetItemModel;
        this.formatRulesetService.getStaticContentRuleset(this.ruleSetId).pipe(
            switchMap((ruleSet) => {
                rule._id = undefined;
                rule.name = StaticContentRulesetItemTableComponent.generateNewRuleName(rule.name, ruleSet.items.map((item) => item.name));
                return this.formatRulesetService.postStaticContentRulesetLibraryItem(this.ruleSetId, rule);
            }),
            takeUntil(this.onDestroySubject)
        ).subscribe(() => {
            Toaster.success('Successfully duplicate library rule');
            this.getRulesetItems();
        }, Toaster.handleApiError);
    }

    protected getFormatRulesetItem(item) {
        return this.formatRulesetService.getStaticContentRulesetLibraryItem(this.ruleSetId, item._id) as any;
    }

    protected getRulesetItems(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_STATIC_CONTENT_RULESET_ITEMS)) {
            return;
        }

        if (this.subscription) this.subscription.unsubscribe();

        this.subscription =
            this.formatRulesetService.getStaticContentRulesetLibraryItems(
                this.ruleSetId,
                this.pageSize,
                (this.pageIndex - 1) * this.pageSize,
                this.sortProperty,
                this.sortOrder,
                this.search,
                this.itemGroup?._id
            ).pipe(takeUntil(this.onDestroySubject)).subscribe(
                (result) => {
                    this.items = result.items;
                    this.total = result.total;
                    this.disableNextPage = this.pageSize * this.pageIndex >= this.total;
                },
                (error) => Toaster.handleApiError(error)
            );
    }

    public startOrderingItems() {
        this.sortOrder = '';
        this.sortProperty = '';
        this.getRulesetItems();
    }

    public orderChanged(event: IReorderEvent) {
        this.formatRulesetService.orderRulesetItems(this.ruleSetId, event.targetId, event.sourceId, event.location)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => {
                    this.getRulesetItems();
                },
                (error) => {
                    Toaster.handleApiError(error);
                });
    }
}
