import {Component, inject, OnInit} from '@angular/core';
import {Toaster} from '../../../../classes/toaster.class';
import {ActivatedRoute} from '@angular/router';
import {RLBaseComponent} from '../../../../components/rl-base-component/rl-base.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import FontsService from '../../../../api/services/fonts.service';
import {FontModel} from '../../../../models/api/font.model';

@Component({
    selector: 'font-detail-component',
    templateUrl: 'font-detail.component.html',
    styleUrls: ['font-detail.component.scss'],
    standalone: true
})
export class FontDetailComponent extends RLBaseComponent implements OnInit  {
    private route = inject(ActivatedRoute);
    private fontsService = inject(FontsService)

    public fontId: string;
    public font: FontModel;
    public numberStyles: number;

    public ngOnInit(): void {
        this.initFromRoute();
        this.getFont();
    }

    private initFromRoute(): void {
        this.fontId = this.route.snapshot.params['fontId'];
    }

    public getFont(): void {
        this.fontsService.findOne(this.fontId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                    next: (result) => {
                        this.font = result
                        this.numberStyles = this.font.files
                            .reduce((count, current) => count + current.styles.length, 0 )
                    },
                    error: Toaster.handleApiError
                }
            );
    }
}
