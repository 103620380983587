<div>
    <div class="connection-details-header">
        <h1>Connection details</h1>
        <nuc-button-secondary
            *ngIf="permissions.PATCH_CONNECTION | userIsAllowedTo"
            (click)="editConnection()"
            [disabled]="loading"
            text="Edit">
        </nuc-button-secondary>
    </div>
    <div class="connection-details" *ngIf="connection">
        <div class="left">
            <img class="logo" [src]="logo">
        </div>
        <div>
            <div class="properties">
                <div class="field">
                    <p class="body-strong">Name</p>
                    <p>{{connection().name}}</p>
                </div>
                <div class="field">
                    <p class="body-strong">Client Id</p>
                    <p>{{connection().clientId}}</p>
                </div>
                <div class="field">
                    <p class="body-strong">Client secret</p>
                    <p>{{connection().clientSecret}}</p>
                </div>
                <div class="field">
                    <p class="body-strong">Subscription key</p>
                    <p>{{connection().subscriptionKey}}</p>
                </div>
                <div class="field">
                    <p class="body-strong">Api key</p>
                    <ng-container>
                        <nuc-button-primary *ngIf="!connection().apiKey" text="Generate API key"
                                            (click)="requestApiKey(connection())"></nuc-button-primary>
                        <api-key-viewer *ngIf="connection().apiKey" [apiKey]="connection().apiKey"
                                        [hasRefreshToken]="connection().hasRefreshToken"
                                        (requestApiKey)="requestApiKey(connection())"
                                        (refreshApiKey)="refreshApiKey(connection())"
                                        [disabled]="loading"></api-key-viewer>
                    </ng-container>
                </div>
                <div *ngIf="connection().apiKey" class="align-self-end">
                    <nuc-button-destructive text="Delete Api key" (click)="unsetApiKey()"></nuc-button-destructive>
                </div>
            </div>
        </div>
    </div>
    <div class="webhooks" *ngIf="connection && connection().apiKey else noApiKeyView">
        <div class="header-row">
            <h1>Webhooks</h1>
            <nuc-button-bar>
                <nuc-button-primary text="Add webhook" (click)="addWebhook()"></nuc-button-primary>
            </nuc-button-bar>
        </div>
        <div class="items">
            <ng-container *ngIf="connection && webhooks else loadingIndicator">
                <ng-container *ngIf="webhooks.length; else emptyState">
                    <div class="webhook" *ngFor="let hook of webhooks">
                        <div class="properties">
                            <div>
                                <p class="body-strong">Subscription Id</p>
                                <p>{{hook.SubscriptionId}}</p>
                            </div>
                            <div>
                                <p class="body-strong">Webhook endpoint</p>
                                <p>{{hook.Data?.webhook}}</p>
                            </div>
                            <div>
                                <p class="body-strong">Categories</p>
                                <p>{{hook.categories.toString()}}</p>
                            </div>
                            <div>
                                <p class="body-strong">Subscription key</p>
                                <p>{{connection().subscriptionKey}}</p>
                            </div>
                            <div>
                                <p class="body-strong">Status</p>
                                <p>{{hook.Status}}</p>
                            </div>
                        </div>
                        <nuc-button-secondary icon="nucicon_edit"
                                              (click)="onEditWebhookClicked(hook._id)"
                                              nucTooltip="Edit webhook"></nuc-button-secondary>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #loadingIndicator>
                <rl-loading-indicator size="large"></rl-loading-indicator>
            </ng-template>
            <ng-template #emptyState>
                <nuc-empty-state-component imageUrl="/assets/images/general_empty_state.svg"
                                           title="Add your first webhook"
                                           [small]="true">
                </nuc-empty-state-component>
            </ng-template>
        </div>
    </div>
    <ng-template #noApiKeyView>
        <nuc-empty-state-component imageUrl="/assets/images/general_empty_state.svg"
                                   title="Api key is needed to view and create webhooks!"
                                   [small]="true">
        </nuc-empty-state-component>
    </ng-template>
</div>
