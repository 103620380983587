<div class="header">
    <h1>{{ruleSet?.name}}</h1>

    <div class="popout-button">
        <nuc-button-primary text="Add"
                            icon="nucicon_dots_vertical"
                            iconPosition="end"
                            [matMenuTriggerFor]="menu"
                            *ngIf="(permissions.PUT_STATIC_CONTENT_RULESET_ITEM | userIsAllowedTo) || (permissions.PUT_STATIC_CONTENT_RULESET_ASSET_ITEM | userIsAllowedTo) || (permissions.PUT_STATIC_CONTENT_RULESET_ITEM_GROUP | userIsAllowedTo)">
        </nuc-button-primary>

        <mat-menu #menu="matMenu" class="menu-items-container">
            <button mat-menu-item
                    *ngIf="permissions.PUT_STATIC_CONTENT_RULESET_ITEM | userIsAllowedTo"
                    (click)="openRuleItemModal()"
                    [disableRipple]="true">Add Library item rule</button>
            <button mat-menu-item
                    *ngIf="permissions.PUT_STATIC_CONTENT_RULESET_ASSET_ITEM | userIsAllowedTo"
                    (click)="openRuleAssetItemModal()"
                    [disableRipple]="true">Add Asset rule</button>
            <button mat-menu-item
                    *ngIf="permissions.PUT_STATIC_CONTENT_RULESET_ITEM_GROUP | userIsAllowedTo"
                    (click)="openRuleGroupItemModal()"
                    [disableRipple]="true">Add Library item group</button>
        </mat-menu>
    </div>
</div>

<nuc-tab-bar [tabs]="tabBarItems" [(activeTab)]="selectedTab"></nuc-tab-bar>
<hr>

<ng-container [ngSwitch]="selectedTab.index">
    <static-content-ruleset-lib-item-table-component
        #rulesetItemTable
        *ngSwitchCase="TAB_ITEMS"
        [ruleSetId]="ruleSetId"
        [ruleProperties]="ruleProperties"
        (itemClicked)="openRuleItemModal($event)"/>
    <static-content-ruleset-asset-item-table
        #rulesetAssetItemTable
        *ngSwitchCase="TAB_ASSET_ITEMS"
        [ruleSetId]="ruleSetId"
        [ruleProperties]="ruleProperties"
        (itemClicked)="openRuleAssetItemModal($event)"/>
    <static-content-ruleset-item-group-table
        #rulesetItemGroupTable
        *ngSwitchCase="TAB_GROUP_ITEMS"
        [ruleSetId]="ruleSetId"
        [ruleProperties]="ruleProperties"
        (itemClicked)="openRuleGroupItemModal($event)"/>
</ng-container>
