import {Component, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    EColumnSize,
    ESelectionMode,
    FullModalConfig,
    FullModalService,
    IItemClickEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {AppConstants} from '../../../../app.constants';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {Observable, of, Subscription} from 'rxjs';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {IPaginator, PaginatorService} from '../../../../components/paginator/paginator.service';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {ActivatedRoute, Router} from '@angular/router';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../../classes/toaster.class';
import {catchError} from 'rxjs/operators';
import {IActionClickEvent, ISortOptionEvent, ITableItem} from '@relayter/rubber-duck/lib/modules/table/components/table/table-config';
import {WorkflowConfigurationModel} from '../../../../models/api/workflow-configuration.model';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {
    IWorkflowConfigurationFormData,
    WorkflowConfigurationFormComponent
} from '../../../../forms/workflow-configuration-form/workflow-configuration-form.component';
import {EWorkflowConfigurationJobType, WorkflowConfigurationsService} from '../../../../api/services/workflow-configurations.service';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {MonitoredJobsService} from '../../../../api/services/monitored-jobs.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'workflow-configuration-overview-component',
    templateUrl: 'workflow-configuration-overview.component.html',
    styleUrls: ['workflow-configuration-overview.component.scss'],
    providers: [PaginatorService]
})
export class WorkflowConfigurationOverviewComponent extends RLTableComponent implements OnInit {
    public readonly tableId = 'workflow-configurations-table';
    public readonly selectionMode: ESelectionMode = ESelectionMode.EMIT;

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name',
            size: EColumnSize.LARGE
        },
        {
            title: 'Number of components',
            key: 'numberOfComponents',
            size: EColumnSize.SMALL
        },
        {
            title: 'Number of steps',
            key: 'numberOfSteps',
            size: EColumnSize.SMALL
        },
        {
            title: 'Layout',
            key: 'layout.name',
            sortProperty: 'layout.name',
            size: EColumnSize.SMALL,
            sortDuplicates: true
        },
        {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED),
            size: EColumnSize.SMALL,
            sortDuplicates: true
        },
        {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED),
            size: EColumnSize.SMALL,
            sortDuplicates: true
        }
    ];

    public workflows: WorkflowConfigurationModel[];
    public disableNextPage = true;
    public subscription: Subscription;

    public allowedActions: ITableAction[] = [];
    public pageIndex: number;
    public pageSize: number;
    public search: string;

    constructor(private fullModalService: FullModalService,
                private route: ActivatedRoute,
                private router: Router,
                private paginatorService: PaginatorService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private dialogService: NucDialogService,
                private monitoredJobsService: MonitoredJobsService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.listenToPagination();

        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PATCH_WORKFLOW_CONFIGURATION)) {
            this.allowedActions.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
        }

        if (this.userIsAllowedToPipe.transform([
            AppConstants.PERMISSIONS.POST_WORKFLOW_CONFIGURATION,
            AppConstants.PERMISSIONS.POST_WORKFLOW_CONFIGURATION_JOB])
        ) {
            this.allowedActions.push(AppConstants.TABLE_ACTION_TYPES.COPY);
        }
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;
        this.search = params['search'] || '';

        this.tableSortOptions.fromRoute(params, this.columns);

        this.setPageIndex(pageIndex);
    }

    private listenToPagination(): void {
        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result: IPaginator) => {
                if (result.pageIndex === 1 || result.pageSize !== this.pageSize) { // reset cursor when needed
                    this.newApiCursor.reset(result.pageIndex);
                }
                this.pageIndex = result.pageIndex;
                this.pageSize = result.pageSize;
                this.updateUrl();
                this.getWorkflowConfigurations();
            });
    }

    private updateUrl(): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.WORKFLOWS,
            AppConstants.CONTEXT_URL.CONFIGURATIONS,
            new MatrixUrlParams(this.pageIndex, null, this.tableSortOptions.sortPropertiesAsString, this.tableSortOptions.sortOrder, this.search)
        ]);
    }

    private setPageIndex(pageIndex = 1): void {
        this.newApiCursor.reset(pageIndex);
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private getWorkflowConfigurations(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_WORKFLOW_CONFIGURATIONS)) return;
        this.subscription?.unsubscribe();

        // If we don't have a cursor value (if we reload the page for example), fall back to offset
        const cursor = this.newApiCursor.getCursor(this.pageIndex);
        const offset = (this.pageIndex === 1 || cursor._id) ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.subscription = this.workflowConfigurationService.find(
            this.pageSize, offset, this.tableSortOptions, cursor, this.search
        ).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result: ARPagedResponseDataModel<WorkflowConfigurationModel>) => {
                    this.workflows = result.items;
                    this.disableNextPage = !result.hasNext;

                    if (this.workflows.length > 0) {
                        const workflow = this.workflows[this.workflows.length - 1];
                        this.newApiCursor.setCursor(this.pageIndex, workflow);
                    }
                },
                error: Toaster.handleApiError
            });
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(sortEvent);

        this.setPageIndex();
    }

    // eslint-disable-next-line no-unused-vars
    public handleAction(event: IActionClickEvent): void {
        if (event.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
            this.openEditWorkflowConfigurationForm(event.item);
        }
        if (event.action === AppConstants.TABLE_ACTION_TYPES.COPY) {
            this.openCopyDialog(event.item as WorkflowConfigurationModel)
        }
    }

    public onSearchBarValueUpdated(event: string): void {
        if (this.search !== event) {
            this.search = event;
            this.setPageIndex();
        }
    }

    public itemClicked(event: IItemClickEvent): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.WORKFLOWS,
            AppConstants.CONTEXT_URL.CONFIGURATIONS,
            event.item._id
        ], {skipLocationChange: false});
    }

    private getWorkflowConfigurationObservable(itemId: string): Observable<WorkflowConfigurationModel> {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_WORKFLOW_CONFIGURATION)) return;

        return this.workflowConfigurationService.findOne(itemId)
            .pipe(
                catchError((error) => {
                    Toaster.handleApiError(error);
                    return of(null);
                }),
                takeUntilDestroyed(this.destroyRef)
            );
    }

    private openEditWorkflowConfigurationForm(item: ITableItem) {
        this.getWorkflowConfigurationObservable(item._id)
            .subscribe(workflowConfiguration => {
                this.openWorkflowConfigurationForm(workflowConfiguration);
            });
    }

    private openCopyDialog(workflowConfiguration: WorkflowConfigurationModel): void {
        const copyDialogConfig = new NucDialogConfigModel(`Duplicate ${workflowConfiguration.name}`,
            'You are about to duplicate a workflow configuration with all the content. Are you sure?');
        const copyDialog = this.dialogService.openDialog(copyDialogConfig);
        copyDialogConfig.addAction('No', BUTTON_TYPE.SECONDARY).subscribe(() => copyDialog.close());
        copyDialogConfig.addAction('Yes', BUTTON_TYPE.PRIMARY).subscribe(() => {
            copyDialog.close();
            this.copyWorkflowConfiguration(workflowConfiguration._id);
        });
    }

    private copyWorkflowConfiguration(workflowConfigurationId: string): void {
        const jobData = {workflowConfigurationId};

        this.workflowConfigurationService.postJob(EWorkflowConfigurationJobType.COPY_WORKFLOW_CONFIGURATION_JOB, jobData)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (job: JobModel) => this.monitoredJobsService.getJobMonitor(job._id)
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe((jobModel: JobModel) => {
                        if (jobModel.status === EJobStatus.DONE) {
                            this.getWorkflowConfigurations();
                        }
                    }),
                error: Toaster.handleApiError
            });
    }

    public openWorkflowConfigurationForm(workflowConfiguration?: WorkflowConfigurationModel): void {
        const modalData = {
            workflowConfiguration
        } as IWorkflowConfigurationFormData;

        const modalConfig = new FullModalConfig(`${!!workflowConfiguration ? 'Edit' : 'Add'} workflow configuration`,
            `Enter the information to ${!!workflowConfiguration ? 'edit a' : 'create a new'} workflow configuration.`, modalData);
        modalConfig.confirmClose = true;

        this.fullModalService.open(WorkflowConfigurationFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(result => {
                if (result) this.getWorkflowConfigurations();
            });
    }
}
