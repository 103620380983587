import {autoserialize, autoserializeAs} from 'cerialize';

export enum EUsageType {
    PUBLICATION_ITEM_CREATION = 'PUBLICATION_ITEM_CREATION',
    STORAGE = 'STORAGE'
}

export enum EPeriodGranularity {
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
    ALL = 'ALL',
    CONTRACT = 'CONTRACT'
}

export class UsagePeriodModel {
    @autoserialize public value: string;
    @autoserialize public granularity: EPeriodGranularity;
}

export class UsageModel {
    @autoserialize public value: number;
    @autoserializeAs(UsagePeriodModel) public period: UsagePeriodModel;
    @autoserialize public type: EUsageType;
}
