<nuc-master-detail-view class="products-container"
                        [open]="!!selectedProduct"
                        [detailWidth]="DEFAULT_SIDEBAR_WIDTH"
                        (closeClicked)="selectedProduct = null"
                        [closeLocationClass]="'header-buttons'"
                        *ngIf="permissions.GET_PRODUCTS | userIsAllowedTo else noPermissionState">
    <div master class="master">

        <div class="header-row">
            <h1>Product overview</h1>
            <nuc-button-bar>
                <nuc-button-secondary text="Import products"
                                      (click)="openImportProductsModal()"
                                      *ngIf="permissions.POST_PRODUCT_JOB | userIsAllowedTo">
                </nuc-button-secondary>
                <nuc-button-secondary text="Export products"
                                      (click)="openExportProductsModal()"
                                      *ngIf="permissions.POST_PRODUCT_JOB | userIsAllowedTo">
                </nuc-button-secondary>
                <nuc-button-primary text="Add product"
                                    (click)="onAddProductClicked()"
                                    *ngIf="permissions.POST_PRODUCTS | userIsAllowedTo">
                </nuc-button-primary>
            </nuc-button-bar>
        </div>

        <!-- TODO: Fix this by refactoring collection-views-->
        <rl-collection-view [items]="products"
                            [propertySettingsContext]="EPropertySettingsContext.PRODUCT"
                            (collectionViewOptionsChanged)="onCollectionViewOptionsChanged()"
                            [selectedItem]="selectedProduct"
                            (collectionViewItemClicked)="onCollectionViewItemClicked($event)"
                            (collectionViewItemDoubleClicked)="onCollectionViewItemDoubleClicked($event)"
                            [(sort)]="sort"
                            [(sortDescending)]="sortDescending"
                            [sortingOptions]="sortingOptions"
                            [disableSort]="!!searchValue"
                            [isLoading]="!productsSubscription?.closed"
                            [isSearching]="isSearching"
                            [searchPlaceholder]="'Search products'"
                            [searchValue]="searchValue"
                            (onSearchValueUpdated)="onSearch($event)"
                            [advancedFilter]="true"
                            [disableNextPage]="disableNextPage"
                            [disablePageOptions]="!!searchValue"
                            [viewId]="tableId"
                            class="smooth-animated">
        </rl-collection-view>
    </div>

    <!--  TODO: [DATAFIELDS] Find a way to configure what dataField should be used as name/title -->

    <div detail *ngIf="selectedProduct" class="detail-sidebar" @myRemoveTrigger>
        <rl-product-detail-page-component [productId]="selectedProduct._id"
                                        (onProductDeleted)="onAssetDeleted()">
        </rl-product-detail-page-component>
        <nuc-button-bar class="asset-actions">
            <nuc-button-secondary *ngIf="permissions.GET_PRODUCT | userIsAllowedTo"
                                  text="View more on detail page"
                                  [routerLink]="['/products/' + selectedProduct._id]"></nuc-button-secondary>
        </nuc-button-bar>
    </div>

</nuc-master-detail-view>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
