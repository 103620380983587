<div class="header-row">
    <h1>Audit Trail</h1>
</div>

<ng-container *ngIf="permissions.GET_AUDIT_TRAIL | userIsAllowedTo; else noPermissionState">
    <nuc-table [items]="items"
               [selectionMode]="ESelectionMode.EXPAND"
               [columns]="columns"
               [loading]="!subscription?.closed"
               [emptyStateTitle]="searchValue ? 'No matching results.' : 'This section is empty. Add the first item.'"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               (sortOptionChange)="onSortOptionChanged($event)" >
        <nuc-input button-bar-left
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="onSearchBarValueUpdated($event)"
                   placeholder="Search">
        </nuc-input>

        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="!subscription?.closed">
        </paginator>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission_small.svg"
                               small="true"
                               title="You or your team does not have permissions to view this section. Contact the team owner to enable this section.">
    </nuc-empty-state-component>
</ng-template>
