@if (selectedContentIndex() < 0) {
    <div>
        <div class="content-overview-header">
            <nuc-label>Blocks</nuc-label>
            <nuc-button-secondary icon="nucicon_add" (click)="onAddContentButtonClicked()"></nuc-button-secondary>
        </div>

        <div class="overview-grid">
            @for (content of contents(); track $index) {
            <div class="list-item"
                 (mouseenter)="onContentHover($index)"
                 (mouseleave)="onContentLeave()">

                <p class="body-strong">{{$index + 1 | number: '2.0'}}. {{content.columns}}x{{content.rows}}</p>

                <p>{{ content.size.width }}x{{ content.size.height }}</p>

                <nuc-button-secondary
                    icon="nucicon_edit"
                    (click)="onContentSelect($index)">
                </nuc-button-secondary>

                <nuc-button-secondary
                    icon="nucicon_trash_fill"
                    (click)="onDeleteButtonClicked($index)">
                </nuc-button-secondary>
            </div>
            }
        </div>
    </div>
}

@if (selectedContentIndex() >= 0){
    <form [formGroup]="selectedAreaFormGroup">
        <div class="content-detail-header">
            <nuc-button-secondary icon="nucicon_chevron_left"
                                  (click)="onContentDeselect()"></nuc-button-secondary>
            <p class="section-header text-center">Edit content block</p>
            <nuc-button-secondary icon="nucicon_trash_fill" (click)="onDeleteButtonClicked(selectedContentIndex())"></nuc-button-secondary>
        </div>

        <form [formGroup]="selectedAreaFormGroup.controls.position" class="sub-form">
            <nuc-form-field label="X">
                <nuc-input [formControl]="selectedAreaFormGroup.controls.position.controls.x" type="number"></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Y">
                <nuc-input [formControl]="selectedAreaFormGroup.controls.position.controls.y" type="number"></nuc-input>
            </nuc-form-field>
        </form>

        <form [formGroup]="selectedAreaFormGroup.controls.size" class="sub-form">
            <nuc-form-field label="Width">
                <nuc-input [formControl]="selectedAreaFormGroup.controls.size.controls.width" type="number"></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Height">
                <nuc-input [formControl]="selectedAreaFormGroup.controls.size.controls.height" type="number"></nuc-input>
            </nuc-form-field>
        </form>

        <div class="other-controls">
            <nuc-form-field label="Columns">
                <nuc-input formControlName="columns" type="number"></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Rows">
                <nuc-input formControlName="rows" type="number"></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Column gutter">
                <nuc-input formControlName="columnGutter" type="number"></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Row gutter">
                <nuc-input formControlName="rowGutter" type="number"></nuc-input>
            </nuc-form-field>
        </div>
    </form>
}
