<form [formGroup]="sizeFormGroup" *ngIf="sizeFormGroup">
    <nuc-form-field label="Width">
        <nuc-input formControlName="width" type="number"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Height">
        <nuc-input formControlName="height" type="number"></nuc-input>
    </nuc-form-field>

    <ng-container formGroupName="margins">
        <p class="section-header span-2">Margins</p>
        <nuc-form-field class="span-2">
            <nuc-checkbox formControlName="allMarginsTheSame" text="All sides are the same"></nuc-checkbox>
        </nuc-form-field>

        <nuc-form-field label="Top">
            <nuc-input formControlName="marginTop" type="number"></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Bottom">
            <nuc-input formControlName="marginBottom" type="number"></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="{{pageType === EPageType.Single ? 'Left' : 'Inside'}}">
            <nuc-input formControlName="marginStart" type="number"></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="{{pageType === EPageType.Single ? 'Right' : 'Outside'}}">
            <nuc-input formControlName="marginEnd" type="number"></nuc-input>
        </nuc-form-field>
    </ng-container>
</form>
