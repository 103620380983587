import { Pipe, PipeTransform } from '@angular/core';
import {EDataFieldCollectionName} from '../app.enums';

@Pipe({
  name: 'collectionDisplayName',
  standalone: true
})
export class CollectionDisplayNamePipe implements PipeTransform {

  transform(value: string): string {
      switch (value) {
          case EDataFieldCollectionName.PRODUCT:
              return 'Product';
          case EDataFieldCollectionName.CAMPAIGN_ITEM:
              return 'Campaign item';
          case EDataFieldCollectionName.ASSET:
              return 'Asset';
          default:
              return value;
      }
  }

}
