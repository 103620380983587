import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {TemplateBodyModel, TemplateDetailModel, TemplateModel} from '../../models/api/template.model';
import {TemplateUsageModel} from '../../models/api/template-usage.model';
import {Serialize} from 'cerialize';

@Injectable({
    providedIn: 'root'
})
export class TemplateService extends BaseApiRequestService {
    /**
     * Get the details of a template
     * @param id
     * @returns {Observable<TemplateModel>}
     */
    public getTemplateDetails(id: string): Observable<TemplateDetailModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_TEMPLATES, id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, TemplateDetailModel);
        });
    }

    /**
     * Check if a template is in use
     * @param {string} templateId
     * @returns {Observable<TemplateUsageModel>}
     */
    public getTemplateUsage(templateId: string): Observable<TemplateUsageModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_TEMPLATES, templateId, ApiConstants.API_METHOD_USAGE]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, TemplateUsageModel);
        });
    }

    /**
     * Post a new template to the API
     * @param templateData
     * @returns {any}
     */
    public postTemplate(templateData: TemplateBodyModel): Observable<TemplateModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_TEMPLATES]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = Serialize(templateData);
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, TemplateModel);
        });
    }

    /**
     * Post a new template to the API
     * @param id
     * @param templateData
     * @returns {any}
     */
    public updateTemplate(id: string, templateData: TemplateBodyModel): Observable<TemplateModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_TEMPLATES, id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PUT;
        options.url = url;
        options.body = Serialize(templateData);
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, TemplateModel);
        });
    }

    /**
     * Deletes the template
     * @param {string} templateId
     * @returns {Observable<boolean>}
     */
    public deleteTemplate(templateId: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_TEMPLATES, templateId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }
}
