import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConstants} from '../../app.constants';
import {Subject, Subscription} from 'rxjs';
import {
    BUTTON_TYPE,
    EColumnType,
    ESelectionMode,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    IItemClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {MatrixUrlParams} from '../../models/ui/matrix-url-params.model';
import {ActivatedRoute, Router} from '@angular/router';
import {PackageModel} from '../../models/api/package.model';
import {CampaignDetailService} from '../../pages/relayter/campaigns/detail/campaign-detail.service';
import {takeUntil} from 'rxjs/operators';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {PackagesService} from '../../api/services/packages.service';
import {UserIsAllowedToPipe} from '../../pipes/user-is-allowed-to.pipe';
import {RLDatePipe} from '../../pipes/rl-date.pipe';
import {IPackageFormData, PackageFormComponent} from '../../forms/package-form/package-form.component';
import {RLTableComponent} from '../rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {
    IPackageExportFormComponentData,
    PackageExportFormComponent
} from '../../forms/package-export-form/package-export-form.component';
import {PaginatorService} from '../paginator/paginator.service';
import {CursorArray} from '../../api/api-cursor';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
    selector: 'rl-campaigns-packages-component',
    templateUrl: 'campaign-packages-overview.component.html',
    styleUrls: ['campaign-packages-overview.component.scss'],
    providers: [PaginatorService]
})

export class CampaignPackagesOverviewComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'campaign-packages-table';
    public readonly EXPORT_PACKAGE_DATA_ACTION: ITableAction = {title: 'Export package data', icon: 'nucicon_download'};
    private campaignId: string;

    public pageIndex: number;
    public pageSize: number;
    private sortColumn: ITableColumn;

    private apiCursor: CursorArray;
    public disableNextPage = true;

    public packages: PackageModel[];
    public packagesSubscription: Subscription;
    private onDestroySubject: Subject<void> = new Subject<void>();

    public selection = new SelectionModel<PackageModel>(true, [], true);

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            type: EColumnType.DEFAULT,
            sortProperty: 'name'
        },
        {
            title: 'Package setup',
            key: 'packageSetup.name',
            sortProperty: 'packageSetup.name',
            sortDuplicates: true
        }, {
            title: 'Description',
            key: 'description',
            sortProperty: 'description',
            sortDuplicates: true
        }, {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            sortDuplicates: true,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED),
        },
        {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            sortDuplicates: true,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED),
        }
    ];
    public ESelectionMode = ESelectionMode;
    public actions: ITableAction[] = [];

    constructor(private router: Router,
                private route: ActivatedRoute,
                private campaignDetailService: CampaignDetailService,
                private fullModalService: FullModalService,
                private packagesService: PackagesService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private dialogService: NucDialogService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.setTableActions();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(pagination => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) {
                    this.apiCursor = new CursorArray(this.pageIndex, this.sortColumn?.sortDuplicates);
                }
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;
                this.updateUrl();
                this.getPackages();
            });

        this.campaignDetailService.packageAdded$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => this.setPageIndex(this.pageIndex));
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
        if (this.packagesSubscription) {
            this.packagesSubscription.unsubscribe();
        }
    }

    private initFromRoute(): void {
        this.campaignId = this.route.parent.snapshot.params['campaign_id'];
        const params = this.route.snapshot.params;

        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : AppConstants.PAGE_INDEX_DEFAULT;
        this.sortColumn = this.columns.find((col) => col.sortProperty === params['sortProperty']);
        this.sortOrder = params['sortOrder'] && (params['sortOrder'] === 'asc' || params['sortOrder'] === 'desc') ? params['sortOrder'] : null;

        this.searchValue = params['search'];
        this.setPageIndex(pageIndex);
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PATCH_PACKAGES)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_PACKAGE)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.POST_CAMPAIGN_JOB)) {
            this.actions.push(this.EXPORT_PACKAGE_DATA_ACTION);
        }
        if (!this.actions?.length) this.actions = null;
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    /**
     * Get paged campaign packages from the api
     */
    private getPackages(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_PACKAGES)) {
            if (this.packagesSubscription) {
                this.packagesSubscription.unsubscribe();
            }
            const cursor = this.apiCursor.getCursor(this.pageIndex);

            this.packagesSubscription = this.packagesService.getPackagesForCampaign(this.campaignId,
                this.pageSize, null, cursor, this.sortColumn?.sortProperty, this.sortOrder, this.searchValue)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe({
                    next: (res: ARPagedResponseDataModel<PackageModel>) => {
                        this.packages = res.items;

                        if (this.packages.length > 0) {
                            const item = this.packages[this.packages.length - 1];
                            this.apiCursor.setCursor(this.pageIndex, this.sortColumn?.sortProperty, item);
                        }
                        this.disableNextPage = !res.hasNext;
                    },
                    error: (err: ARApiError) => Toaster.handleApiError(err)
                });
        }
    }

    /**
     * Create a MatrixUrlParams so the url always has the correct amount of parameters
     * @return {MatrixUrlParams}
     */
    private createMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, this.sortColumn?.sortProperty, this.sortOrder, this.searchValue);
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        if (sortEvent.column?.sortProperty) {
            this.sortColumn = sortEvent.column;
            this.sortOrder = sortEvent.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortColumn = null;
            this.sortOrder = null;
        }

        this.setPageIndex();
    }

    private updateUrl(): void {
        this.router.navigate([this.createMatrixUrl()], {replaceUrl: true, relativeTo: this.route});
    }

    public onItemClicked(event: IItemClickEvent): void {
        this.router.navigate([AppConstants.CONTEXT_URL.CAMPAIGNS, this.campaignId, AppConstants.CONTEXT_URL.PACKAGES, event.item._id]);
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const clickedPackage = event.item as PackageModel;
        this.packagesService.getPackageForCampaign(this.campaignId, clickedPackage._id).subscribe({
            next: (result: PackageModel) => {
                switch (event.action) {
                    case AppConstants.TABLE_ACTION_TYPES.EDIT:
                        this.openPackageForm(result);
                        break;
                    case AppConstants.TABLE_ACTION_TYPES.DELETE:
                        this.openDeleteDialog(result);
                        break;
                    case this.EXPORT_PACKAGE_DATA_ACTION:
                        this.openExportPackageForm([result]);
                        break;
                }
            },
            error: Toaster.handleApiError
        });
    }

    private openPackageForm(packageData: PackageModel): void {
        const data: IPackageFormData = {package: packageData, campaignId: this.campaignId};
        const config: FullModalConfig = new FullModalConfig('Edit package', 'Edit the information of the package.', data);

        this.fullModalService.open(PackageFormComponent, config).afterClosed().subscribe((result) => {
            if (result) {
                this.setPageIndex(this.pageIndex);
            }
        });
    }

    private openDeleteDialog(packageData: PackageModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete package',
            `Please confirm that you wish to delete ${packageData.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => {
            deleteDialog.close();
        });
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deletePackage(packageData);
        });
    }

    public openExportPackageForm(packages: PackageModel[], resetSelection = false): void {
        const modalData: IPackageExportFormComponentData = {
            campaignId: this.campaignId,
            campaignPackageIds: packages.map(item => item._id)
        };
        const modalConfig = new FullModalConfig('Export package',
            'Select the package type and export format of the items you wish to export.', modalData);

        this.fullModalService
            .open(PackageExportFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((result) => {
                if (result && resetSelection) this.selection.clear();
            });
    }

    private deletePackage(packageData: PackageModel): void {
        this.packagesService.deleteCampaignPackage(this.campaignId, packageData._id).subscribe({
            next: () => {
                this.setPageIndex();
                Toaster.success('Package removed successfully');
            },
            error: Toaster.handleApiError
        });
    }

    public onSearchBarValueUpdated(): void {
        this.setPageIndex();
    }
}
