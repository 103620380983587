import {Component, inject, OnInit} from '@angular/core';
import {NUCInputsModule, NUCTabBarModule} from '@relayter/rubber-duck';
import {AfterEffectsProjectFileCompositionModel, AfterEffectsProjectFileModel} from '../../../../models/api/after-effects-project-file.model';
import {Toaster} from '../../../../classes/toaster.class';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {AppConstants} from '../../../../app.constants';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {TabBarRoutableItem} from '../../team-settings/tab-bar-routable-item.model';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {filter} from 'rxjs/operators';
import {ITabBarItem} from '@relayter/rubber-duck/lib/modules/tab-bar/config/tab-bar-item.interface';
import {RLBaseComponent} from '../../../../components/rl-base-component/rl-base.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AfterEffectsProjectFilesApiService} from '../../../../api/services/after-effects-project-files.api.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'after-effects-project-files-detail-component',
    templateUrl: 'after-effects-project-files-detail.component.html',
    styleUrls: ['after-effects-project-files-detail.component.scss'],
    standalone: true,
    imports: [
        NUCInputsModule,
        NUCTabBarModule,
        RouterOutlet
    ],
    providers: [PaginatorService]
})
export class AfterEffectsProjectFilesDetailComponent extends RLBaseComponent implements OnInit  {
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private userIsAllowedToPipe = inject(UserIsAllowedToPipe);
    private afterEffectsProjectFilesApiService = inject(AfterEffectsProjectFilesApiService)

    private layersTab: TabBarRoutableItem;
    private previewTab: TabBarRoutableItem;

    public tabs: TabBarRoutableItem[] = [];
    public activeTab: TabBarRoutableItem;

    private afterEffectsProjectFileId: string;
    private afterEffectsProjectFileCompositionId: string;
    public afterEffectsProjectFile: AfterEffectsProjectFileModel;
    public compositionDataSubscription: Subscription;

    public compositions: AfterEffectsProjectFileCompositionModel[];
    public selectedComposition: AfterEffectsProjectFileCompositionModel;
    public totalCompositions: number;

    private setActiveTab(url: string): void {
        url = url?.split(';')[0].split('?')[0];

        if (url === this.layersTab.urlString) {
            this.activeTab = this.tabs[0];
        } else if (url === this.previewTab.urlString) {
            this.activeTab = this.tabs[1];
        }
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.getAfterEffectsProjectFile();
        this.getCompositions();
    }

    private initTabs(): void {
        this.layersTab = new TabBarRoutableItem(
            'Layers',
            [`/${AppConstants.CONTEXT_URL.TEMPLATING}`,
                AppConstants.CONTEXT_URL.AFTER_EFFECTS_PROJECT_FILES,
                this.afterEffectsProjectFileId,
                AppConstants.CONTEXT_URL.LAYERS],
            [AppConstants.PERMISSIONS.GET_AFTER_EFFECTS_PROJECT_FILE]
        );

        this.previewTab = new TabBarRoutableItem(
            'Preview', [`/${AppConstants.CONTEXT_URL.TEMPLATING}`,
                AppConstants.CONTEXT_URL.AFTER_EFFECTS_PROJECT_FILES,
                this.afterEffectsProjectFileId, AppConstants.CONTEXT_URL.PREVIEW],
            [AppConstants.PERMISSIONS.GET_AFTER_EFFECTS_PROJECT_FILE]
        );
        this.tabs = [this.layersTab, this.previewTab];

        this.setActiveTab(this.router.url);
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntilDestroyed(this.destroyRef)
            ).subscribe((event: NavigationEnd) => {
            this.setActiveTab(event.url);
        });
        this.tabs = this.tabs.filter((tab) => this.userIsAllowedToPipe.transform(tab.permissions));
    }

    private initFromRoute(): void {
        this.afterEffectsProjectFileId = this.route.snapshot.params['afterEffectsProjectFileId'];
        this.afterEffectsProjectFileCompositionId = this.route.snapshot.queryParams['itemId'];
    }

    public getAfterEffectsProjectFile(): void {
        this.afterEffectsProjectFilesApiService.findOne(this.afterEffectsProjectFileId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                    next: (result) => {
                        this.afterEffectsProjectFile = result
                    },
                    error: Toaster.handleApiError
                }
            );
    }

    public getCompositions(): void {
        if (this.compositionDataSubscription) this.compositionDataSubscription.unsubscribe();

        this.compositionDataSubscription =
            this.afterEffectsProjectFilesApiService.getAfterEffectsProjectFileCompositions(this.afterEffectsProjectFileId)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        this.compositions = result;
                        if (this.compositions?.length && !this.selectedComposition) {
                            this.selectedComposition = this.compositions.find((composition) =>
                                composition._id === this.afterEffectsProjectFileCompositionId) || this.compositions[0]
                            this.updateUrl();
                        }
                        this.initTabs();
                    },
                    error: Toaster.handleApiError
                });
    }

    public compositionChanged(composition: AfterEffectsProjectFileCompositionModel) {
        if (this.selectedComposition?._id !== composition?._id) {
            this.selectedComposition = composition;
            this.updateUrl();
        }
    }

    public onActiveTabChanged(newTab: ITabBarItem): void {
        if (newTab) {
            this.activeTab = this.tabs.find((tab) => tab.title === newTab.title);
            this.updateUrl();
        }
    }

    public updateUrl(): void {
        if (this.activeTab && this.selectedComposition) {
            this.router.navigate([...this.activeTab.url], {relativeTo: this.route, queryParams: {itemId: this.selectedComposition._id}});
        }
    }
}
