import {Routes} from '@angular/router';
import {RelayterContainerComponent} from '../relayter-container.component';
import {AccountComponent} from '../account/account.component';
import {UserManagementComponent} from '../user-management/user-management.component';
import {RolesOverviewComponent} from '../roles/roles-overview.component';
import {RoleDetailContainerComponent} from '../role-detail/role-detail-container.component';
import {RolePermissionsComponent} from '../role-detail/role-permissions/role-permissions.component';
import {RoleUsersComponent} from '../role-detail/role-users/role-users.component';
import {RLCanDeactivateManagementRouteGuard} from './guards/deactivate-management-route.guard';
import {UserDetailComponent} from '../user-management/users-overview/detail/user-detail.component';
import {ProductDetailComponent} from '../products/detail/products-detail.component';
import {CampaignsOverviewComponent} from '../campaigns/overview/campaigns-overview.component';
import {CampaignsDetailComponent} from '../campaigns/detail/campaigns-detail.component';
import {CampaignsBriefingComponent} from '../campaigns/briefing/campaigns-briefing.component';
import {TeamSettingsComponent} from '../team-settings/team-settings.component';
import {canActivate} from './guards/auth-guard';
import {ProductsOverviewComponent} from '../products/overview/products-overview.component';
import {AssetOverviewComponent} from '../assets/overview/asset-overview.component';
import {AssetDetailComponent} from '../assets/detail/asset-detail.component';
import {TemplateOverviewComponent} from '../templates/overview/template-overview.component';
import {GeneralInformationComponent} from '../team-settings/general-information/general-information.component';
import {MasterPageOverviewComponent} from '../master-pages/master-page-overview/master-page-overview.component';
import {GroupsComponent} from '../groups/groups.component';
import {GroupDetailComponent} from '../groups/group-detail/group-detail.component';
import {ContentRulesComponent} from '../content-rules/content-rules.component';
import {BriefingDataManagementComponent} from '../briefing-data-management/briefing-data-management.component';
import {ProductDataManagementComponent} from '../product-data-management/product-data-management.component';
import {LibraryDetailComponent} from '../library-items/library-detail/library-detail.component';
import {UsersOverviewComponent} from '../user-management/users-overview/users-overview.component';
import {PendingInvitesComponent} from '../user-management/pending-invites/pending-invites.component';
import {CustomWorkflowComponent} from '../campaigns/publication/custom-workflow/custom-workflow.component';
import {EFormContext} from '../../../models/api/form.model';
import {FormOverviewComponent} from '../data-management/form-overview/form-overview.component';
import {DataFieldsOverviewComponent} from '../../../components/data-fields-overview/data-fields-overview.component';
import {EDataFieldCollectionName} from '../../../app.enums';
import {LibraryOverviewComponent} from '../library-items/overview/library-overview.component';
import {PackageSetupOverviewComponent} from '../packages/package-setup-overview/package-setup-overview.component';
import {PackageSetupDetailsComponent} from '../packages/package-setup-details/package-setup-details.component';
import {
    CampaignPublicationsOverviewComponent
} from '../../../components/campaign-publications/campaign-publications-overview.component';
import {
    CampaignPackagesOverviewComponent
} from '../../../components/campaign-packages/campaign-packages-overview.component';
import {CampaignPackageComponent} from '../campaigns/campaign-package/campaign-package.component';
import {
    WorkflowAutomationOverviewComponent
} from '../workflow-automations/workflow-automation-overview/workflow-automation-overview.component';
import {
    WorkflowAutomationDetailsComponent
} from '../workflow-automations/workflow-automation-details/workflow-automation-details.component';
import {AssetDataManagementComponent} from '../asset-data-management/asset-data-management.component';
import {ConnectionsComponent} from '../team-settings/connections/connections.component';
import {VariantsOverviewComponent} from '../variants/variants-overview.component';
import {
    ConnectionDetailMediavaletComponent
} from '../team-settings/connections/details/media-valet/connection-detail-mediavalet.component';
import {TeamSettingsOverviewComponent} from '../team-settings/team-settings-overview/team-settings-overview.component';
import {
    StaticContentRulesetsOverviewComponent
} from '../../../modules/static-content-rulesets/static-content-rulesets-overview/static-content-rulesets-overview.component';
import {
    StaticContentRulesetDetailComponent
} from '../../../modules/static-content-rulesets/static-content-ruleset-detail/static-content-ruleset-detail.component';
import {ApiConstants} from '../../../api/api.constant';
import {CanLeaveComponentGuard} from './guards/can-leave-component.guard';
import {CampaignDetailBaseComponent} from '../campaigns/campaign-detail-base/campaign-detail-base.component';
import {
    WorkflowConfigurationOverviewComponent
} from '../workflow-configurations/overview/workflow-configuration-overview.component';
import {
    ConnectionDetailWebhookConsumerComponent
} from '../team-settings/connections/details/webhook-consumer/connection-detail-webhook-consumer.component';
import {inject} from '@angular/core';
import {campaignResolver, publicationResolver} from './resolvers';
import {
    MasterBriefingsOverviewComponent
} from '../team-settings/master-briefings-overview/master-briefings-overview.component';
import {
    WorkflowConfigurationDetailsComponent
} from '../workflow-configurations/details/workflow-configuration-details.component';
import {
    WorkflowConfigurationPropertiesComponent
} from '../workflow-configurations/details/workflow-configuration-properties/workflow-configuration-properties.component';
import {
    WorkflowConfigurationComponentDetailsComponent
} from '../workflow-configurations/details/workflow-configuration-component-details.component.ts/workflow-configuration-component-details.component';
import {
    ConnectionDetailSuperunieAdamComponent
} from '../team-settings/connections/details/superunie-adam/connection-detail-superunie-adam.component';
import {
    WorkflowConfigurationStepDetailsComponent
} from '../workflow-configurations/details/workflow-configuration-step-details/workflow-configuration-step-details.component';
import {
    ProductAssetExportSetupOverviewComponent
} from '../product-asset-export-setups/product-asset-export-setup-overview/product-asset-export-setup-overview.component';
import {AuditTrailOverviewComponent} from '../audit-trail/audit-trail-overview.component';
import {
    ConnectionDetailWebhookProducerComponent
} from '../team-settings/connections/details/webhook-producer/connection-detail-webhook-producer.component';
import {
    AfterEffectsProjectFilesCompositionLayersComponent
} from '../after-effects-project-files/compositions/layers/after-effects-project-files-composition-layers.component';
import {
    AfterEffectsProjectFilesOverviewComponent
} from '../after-effects-project-files/overview/after-effects-project-files-overview.component';
import {
    AfterEffectsProjectFilesDetailComponent
} from '../after-effects-project-files/detail/after-effects-project-files-detail.component';
import {
    AfterEffectsProjectFilesCompositionPreviewComponent
} from '../after-effects-project-files/compositions/preview/after-effects-project-files-composition-preview.component';
import {
    AnimatedContentTemplateOverviewComponent
} from '../templates/animated-content-template/animated-content-overview/animated-content-template-overview.component';
import {
    AnimatedContentRulesetsOverviewComponent
} from '../../../modules/animated-content-rulesets/animated-content-rulesets-overview/animated-content-rulesets-overview.component';
import {
    AnimatedContentRulesetDetailComponent
} from '../../../modules/animated-content-rulesets/animated-content-ruleset-detail/animated-content-ruleset-detail.component';
import {FontOverviewComponent} from '../fonts/overview/font-overview.component';
import {FontDetailComponent} from '../fonts/font-detail/font-detail.component';

// Variables for literals to make the linter happy
const DATA_FIELDS = 'data-fields';
const CAMPAIGN_ID = ':campaign_id';

export const RELAYTER_ROUTE_DEFINITIONS: Routes = [
    {
        path: '',
        component: RelayterContainerComponent,
        canActivate: [canActivate],
        children: [
            {
                path: 'my-account',
                component: AccountComponent,
                title: 'My Account',
                data: {
                    breadcrumb: 'My Account'
                }
            },
            {
                path: 'settings',
                data: {
                    breadcrumb: 'Settings'
                },
                children: [
                    {
                        path: '',
                        redirectTo: 'user-management',
                        pathMatch: 'full'
                    },
                    {
                        path: 'user-management',
                        title: 'Settings - User management',
                        data: {
                            breadcrumb: 'User management'
                        },
                        children: [
                            {
                                path: '',
                                component: UserManagementComponent,
                                children: [
                                    {
                                        path: 'users-overview',
                                        component: UsersOverviewComponent,
                                        title: 'Settings - User management - Users',
                                        data: {
                                            breadcrumb: 'Users'
                                        }
                                    },
                                    {
                                        path: 'pending-invites',
                                        component: PendingInvitesComponent,
                                        title: 'Settings - User management - Pending invites',
                                        data: {
                                            breadcrumb: 'Pending invites'
                                        }
                                    }
                                ]
                            },
                            {
                                path: 'users-overview/:id',
                                component: UserDetailComponent,
                                title: 'Settings - User management - User',
                                data: {
                                    breadcrumb: 'User'
                                },
                                canDeactivate: [() => inject(RLCanDeactivateManagementRouteGuard).canDeactivate]
                            }
                        ]
                    },
                    {
                        path: 'role-management',
                        data: {
                            breadcrumb: 'Role management'
                        },
                        children: [
                            {
                                path: '',
                                component: RolesOverviewComponent,
                                title: 'Settings - Role Management'
                            },
                            {
                                path: ':id',
                                component: RoleDetailContainerComponent,
                                children: [
                                    {
                                        path: 'permissions',
                                        component: RolePermissionsComponent,
                                        title: 'Settings - Role Permissions',
                                        data: {
                                            breadcrumb: 'Permissions'
                                        },
                                        canDeactivate: [() => inject(RLCanDeactivateManagementRouteGuard).canDeactivate]
                                    },
                                    {
                                        path: 'users',
                                        component: RoleUsersComponent,
                                        title: 'Settings - Role Users',
                                        data: {
                                            breadcrumb: 'Users'
                                        }
                                    }
                                ]
                            }]
                    },
                    {
                        path: 'audit-trail',
                        title: 'Audit Trail',
                        data: {
                            breadcrumb: 'Audit Trail'
                        },
                        component: AuditTrailOverviewComponent
                    },
                    {
                        path: 'team-setup',
                        component: TeamSettingsOverviewComponent,
                        children: [
                            {
                                path: '',
                                component: TeamSettingsComponent,
                                title: 'Settings',
                                children: [
                                    {
                                        path: 'general-information',
                                        component: GeneralInformationComponent,
                                        title: 'Settings - Team setup - General information',
                                        data: {
                                            breadcrumb: 'General information'
                                        }
                                    },
                                    {
                                        path: 'connections',
                                        component: ConnectionsComponent,
                                        title: 'Settings - Team setup - Connections',
                                        data: {
                                            breadcrumb: 'Connections'
                                        }
                                    },
                                    {
                                        path: 'master-briefing',
                                        component: MasterBriefingsOverviewComponent,
                                        title: 'Settings - Team setup - Master briefing',
                                        data: {
                                            breadcrumb: 'Master briefing'
                                        }
                                    }
                                ]
                            },
                            {
                                path: 'connections',
                                title: 'Settings - Team setup - Connections',
                                data: {
                                    breadcrumb: 'Connections'
                                },
                                children: [
                                    {
                                        path: ':connectionId',
                                        children: [
                                            {
                                                path: 'mediavalet',
                                                title: 'Settings - Team setup - Connections detail - mediaValet',
                                                data: {
                                                    breadcrumb: 'mediaValet'
                                                },
                                                component: ConnectionDetailMediavaletComponent
                                            },
                                            {
                                                path: 'webhook-consumer',
                                                title: 'Settings - Team setup - Connections detail - Webhook consumer',
                                                data: {
                                                    breadcrumb: 'Webhook Consumer'
                                                },
                                                component: ConnectionDetailWebhookConsumerComponent
                                            },
                                            {
                                                path: 'superunie-adam',
                                                title: 'Settings - Team setup - Connections detail - Superunie Adam',
                                                data: {
                                                    breadcrumb: 'Superunie Adam'
                                                },
                                                component: ConnectionDetailSuperunieAdamComponent
                                            },
                                            {
                                                path: 'webhook-producer',
                                                title: 'Settings - Team setup - Connections detail - Webhook producer',
                                                data: {
                                                    breadcrumb: 'Webhook producer'
                                                },
                                                component: ConnectionDetailWebhookProducerComponent
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'content-groups',
                        data: {
                            breadcrumb: 'Content groups'
                        },
                        children: [
                            {
                                path: '',
                                component: GroupsComponent,
                                title: 'Settings - Content groups'
                            },
                            {
                                path: ':groupId',
                                component: GroupDetailComponent,
                                title: 'Settings - Content groups - Group details',
                                data: {
                                    breadcrumb: 'Group details'
                                }
                            }
                        ]
                    },
                    {
                        path: 'content-rules',
                        title: 'Settings - Content rules',
                        data: {
                            breadcrumb: 'Content rules'
                        },
                        component: ContentRulesComponent
                    },
                    {
                        path: 'briefing-data-management',
                        component: BriefingDataManagementComponent,
                        children: [
                            {
                                path: DATA_FIELDS,
                                component: DataFieldsOverviewComponent,
                                title: 'Settings - Briefing data management - Data fields',
                                data: {
                                    collectionName: EDataFieldCollectionName.CAMPAIGN_ITEM
                                }
                            },
                            {
                                path: 'briefing-form',
                                component: FormOverviewComponent,
                                title: 'Settings - Briefing data management - Briefing form',
                                data: {
                                    context: EFormContext.BRIEFING
                                }
                            }
                        ]
                    },
                    {
                        path: 'product-data-management',
                        component: ProductDataManagementComponent,
                        children: [
                            {
                                path: DATA_FIELDS,
                                component: DataFieldsOverviewComponent,
                                title: 'Settings - Product data management - Data fields',
                                data: {
                                    collectionName: EDataFieldCollectionName.PRODUCT
                                }
                            },
                            {
                                path: 'product-form',
                                component: FormOverviewComponent,
                                title: 'Settings - Product data management - Product form',
                                data: {
                                    context: EFormContext.PRODUCT
                                }
                            }
                        ]
                    },
                    {
                        path: 'asset-data-management',
                        component: AssetDataManagementComponent,
                        children: [
                            {
                                path: DATA_FIELDS,
                                component: DataFieldsOverviewComponent,
                                title: 'Settings - Asset data management - Data fields',
                                data: {
                                    collectionName: EDataFieldCollectionName.ASSET
                                }
                            },
                            {
                                path: 'asset-form',
                                component: FormOverviewComponent,
                                title: 'Settings - Asset data management - Asset form',
                                data: {
                                    context: EFormContext.ASSET
                                }
                            }
                        ]
                    },
                    {
                        path: 'variants',
                        component: VariantsOverviewComponent,
                        title: 'Settings - Variants',
                        children: []
                    }
                ]
            },
            {
                path: 'products',
                data: {
                    breadcrumb: 'Products'
                },
                children: [
                    {
                        path: '',
                        component: ProductsOverviewComponent,
                        title: 'Products'
                    },
                    {
                        path: ':id',
                        component: ProductDetailComponent,
                        title: 'Product details',
                        data: {
                            breadcrumb: 'Product details'
                        }
                    }
                ]
            },
            {
                path: 'assets',
                data: {
                    breadcrumb: 'Assets'
                },
                children: [
                    {
                        path: '',
                        component: AssetOverviewComponent,
                        title: 'Assets'
                    },
                    {
                        path: ':id',
                        component: AssetDetailComponent,
                        title: 'Asset details',
                        data: {
                            breadcrumb: 'Asset details'
                        }
                    }
                ]
            },
            {
                path: 'campaigns',
                data: {
                    breadcrumb: 'Campaigns'
                },
                children: [
                    {
                        path: '',
                        component: CampaignsOverviewComponent,
                        title: 'Campaigns'
                    },
                    {
                        path: CAMPAIGN_ID,
                        component: CampaignDetailBaseComponent,
                        data: {
                            breadcrumb: 'Campaign details'
                        },
                        resolve: {'campaign': campaignResolver},
                        children: [
                            {
                                path: '',
                                component: CampaignsDetailComponent,
                                children: [
                                    {
                                        path: 'publications',
                                        component: CampaignPublicationsOverviewComponent,
                                        title: 'Campaign Details - Publications'
                                    },
                                    {
                                        path: 'packages',
                                        component: CampaignPackagesOverviewComponent,
                                        title: 'Campaign Details - Packages'
                                    }
                                ]
                            },
                            {
                                path: 'briefing',
                                component: CampaignsBriefingComponent,
                                title: 'Campaign Briefing',
                                data: {
                                    breadcrumb: 'Briefing'
                                }
                            },
                            {
                                path: 'packages/:package_id',
                                component: CampaignPackageComponent,
                                title: 'Campaign Package',
                                data: {
                                    breadcrumb: 'Package'
                                }
                            },
                            {
                                path: 'publications/:publication_id',
                                title: 'Publication',
                                data: {
                                    breadcrumb: 'Publication'
                                },
                                component: CustomWorkflowComponent,
                                resolve: {'publication': publicationResolver},
                                canDeactivate: [() => inject(CanLeaveComponentGuard).canDeactivate]
                            }
                        ]
                    },
                    {
                        redirectTo: CAMPAIGN_ID,
                        path: ':campaign_id/publications'
                    }
                ]
            },
            {
                path: 'templating',
                data: {
                    breadcrumb: 'Templating'
                },
                children: [
                    {
                        path: '',
                        redirectTo: 'static-content-templates',
                        pathMatch: 'full'
                    },
                    {
                        path: 'fonts',
                        data: {
                            breadcrumb: 'Fonts'
                        },
                        children: [
                            {
                                path: '',
                                component: FontOverviewComponent,
                                title: 'Templating - Fonts'
                            },
                            {
                                path: ':fontId',
                                component: FontDetailComponent,
                                title: 'Templating - Fonts - Font',
                                data: {
                                    breadcrumb: 'Font'
                                }
                            }
                        ]
                    },
                    {
                        path: 'static-content-templates',
                        component: TemplateOverviewComponent,
                        title: 'Templating - Static content templates',
                        data: {
                            breadcrumb: 'Static content templates'
                        }
                    },
                    {
                        path: 'animated-content-templates',
                        component: AnimatedContentTemplateOverviewComponent,
                        title: 'Templating - Animated content templates',
                        data: {
                            breadcrumb: 'Templates'
                        }
                    },
                    {
                        path: 'design-libraries',
                        data: {
                            breadcrumb: 'Design libraries'
                        },
                        children: [
                            {
                                path: '',
                                component: LibraryOverviewComponent,
                                title: 'Templating - Design libraries'
                            },
                            {
                                path: ':libraryId',
                                component: LibraryDetailComponent,
                                title: 'Templating - Design libraries - Library',
                                data: {
                                    breadcrumb: 'Library'
                                }
                            }
                        ]
                    },
                    {
                        path: 'master-pages',
                        component: MasterPageOverviewComponent,
                        title: 'Templating - Master pages',
                        data: {
                            breadcrumb: 'Master pages'
                        }
                    },
                    {
                        path: 'static-content-rulesets',
                        data: {
                            breadcrumb: 'Static content rulesets'
                        },
                        children: [
                            {
                                path: '',
                                component: StaticContentRulesetsOverviewComponent,
                                title: 'Templating - Static content rulesets'
                            },
                            {
                                path: ':ruleSetId/items',
                                component: StaticContentRulesetDetailComponent,
                                title: 'Templating - Static content rulesets - Item',
                                data: {
                                    breadcrumb: 'Item',
                                    apiPath: ApiConstants.API_METHOD_ITEMS
                                }
                            },
                            {
                                path: ':ruleSetId/asset-items',
                                component: StaticContentRulesetDetailComponent,
                                title: 'Templating - Static content rulesets - Asset',
                                data: {
                                    breadcrumb: 'Asset',
                                    apiPath: ApiConstants.API_METHOD_ASSET_ITEMS
                                }
                            }
                        ]
                    },
                    {
                        path: 'animated-content-rulesets',
                        data: {
                            breadcrumb: 'Animated content rulesets'
                        },
                        children: [
                            {
                                path: '',
                                component: AnimatedContentRulesetsOverviewComponent,
                                title: 'Templating - Animated content rulesets',
                            },
                            {
                                path: ':animatedContentRulesetId',
                                component: AnimatedContentRulesetDetailComponent,
                                title: 'Templating - Animated content ruleset - Details',
                                data: {
                                    breadcrumb: 'Details'
                                }
                            },
                        ]
                    },
                    {
                        path: 'after-effects-project-files',
                        data: {
                            breadcrumb: 'After Effects project files'
                        },
                        children: [
                            {
                                path: '',
                                component: AfterEffectsProjectFilesOverviewComponent,
                                title: 'Templating - After Effects project files'
                            },
                            {
                                path: ':afterEffectsProjectFileId',
                                component: AfterEffectsProjectFilesDetailComponent,
                                title: 'Templating - After Effects project files - Project file',
                                data: {
                                    breadcrumb: 'Project file'
                                },
                                children: [
                                    {
                                        path: 'layers',
                                        component: AfterEffectsProjectFilesCompositionLayersComponent,
                                        title: 'Templating - After Effects project files - Project file - Compositions - Layers',
                                    },
                                    {
                                        path: 'preview',
                                        component: AfterEffectsProjectFilesCompositionPreviewComponent,
                                        title: 'Templating - After Effects project files - Project file - Compositions - Preview',
                                    }
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                path: 'workflows',
                data: {
                    breadcrumb: 'Workflows'
                },
                children: [
                    {
                        path: '',
                        redirectTo: 'workflow-automations',
                        pathMatch: 'full'
                    },
                    {
                        path: 'workflow-automations',
                        data: {
                            breadcrumb: 'Workflow automations'
                        },
                        children: [
                            {
                                path: '',
                                component: WorkflowAutomationOverviewComponent,
                                title: 'Workflows - Workflow automations'
                            },
                            {
                                path: ':workflowAutomationId',
                                component: WorkflowAutomationDetailsComponent,
                                title: 'Workflows - Workflows automations - Rules',
                                data: {
                                    breadcrumb: 'Rules'
                                }
                            }
                        ]
                    },
                    {
                        path: 'configurations',
                        data: {
                            breadcrumb: 'Configurations'
                        },
                        children: [
                            {
                                path: '',
                                component: WorkflowConfigurationOverviewComponent,
                                title: 'Workflows - Configurations'
                            },
                            {
                                path: ':workflowConfigurationId',
                                component: WorkflowConfigurationDetailsComponent,
                                data: {
                                    breadcrumb: 'Details'
                                },
                                children: [
                                    {
                                        path: '',
                                        redirectTo: 'steps',
                                        pathMatch: 'full'
                                    },
                                    {
                                        path: ':property',
                                        component: WorkflowConfigurationPropertiesComponent,
                                        title: 'Workflow - Configuration details'
                                    },
                                    {
                                        path: 'steps/:stepId',
                                        component: WorkflowConfigurationStepDetailsComponent,
                                        title: 'Workflows - Configuration details - Step',
                                        data: {
                                            breadcrumb: 'Step'
                                        }
                                    },
                                    {
                                        path: 'components/:componentId',
                                        component: WorkflowConfigurationComponentDetailsComponent,
                                        title: 'Workflows - Configuration details - Component',
                                        data: {
                                            breadcrumb: 'Component'
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'export-setups',
                data: {
                    breadcrumb: 'Exports'
                },
                children: [
                    {
                        path: '',
                        redirectTo: 'campaign-package-setups',
                        pathMatch: 'full'
                    },
                    {
                        path: 'campaign-package-setups',
                        data: {
                            breadcrumb: 'Campaign package setups'
                        },
                        children: [
                            {
                                path: '',
                                component: PackageSetupOverviewComponent,
                                title: 'Export setups - Campaign package setups'
                            },
                            {
                                path: ':packageSetupId',
                                component: PackageSetupDetailsComponent,
                                title: 'Export setups - Campaign package setups - Rule',
                                data: {
                                    breadcrumb: 'Rule'
                                }
                            }
                        ]
                    },
                    {
                        path: 'product-asset-export-setups',
                        data: {
                            breadcrumb: 'Product asset export setups'
                        },
                        children: [
                            {
                                path: '',
                                component: ProductAssetExportSetupOverviewComponent,
                                title: 'Export setups - Product asset export setups'
                            }
                        ]
                    },
                ]
            },
            {
                path: '',
                redirectTo: 'assets',
                pathMatch: 'full'
            }
        ]
    }
];
