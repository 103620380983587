import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {VariantModel} from '../../models/api/variant.model';

@Injectable({
    providedIn: 'root'
})
export class VariantsApiService extends ApiService<VariantModel> {
    private static API_GROUP_VARIANTS = 'variants';

    constructor() {
        super([VariantsApiService.API_GROUP_VARIANTS], VariantModel);
    }
}
