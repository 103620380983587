import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {VariantModel} from '../../../../../models/api/variant.model';
import {RLValidatorConstants} from '../../../../../classes/validators/rl-validators.constant';
import {EChannel} from '../../../../../app.enums';
import {ITemplateForm} from '../template-detail.component';
import {ITemplateVariantPresetForm} from './preset/template-variant-preset.component';
import {EEngineType} from '../../../../../models/api/template.model';

@Component({
    selector: 'template-variants',
    templateUrl: 'template-variants.component.html',
    styleUrls: ['template-variants.component.scss']
})
export class TemplateVariantsComponent implements OnInit {
    @Input() public variants: VariantModel[];
    @Input() public formGroup: FormGroup<ITemplateForm>;

    public variantPresetsFormArray: FormArray<FormGroup<ITemplateVariantPresetForm>>;
    public selectedVariants: VariantModel[] = [];
    public channel: EChannel;
    public engineType: EEngineType;

    public ngOnInit(): void {
        this.variantPresetsFormArray = this.formGroup.controls.variantPresets;
        this.channel = this.formGroup.controls.preset.controls.channel.value.getValue() as EChannel;
        this.engineType = this.formGroup.controls.preset.controls.engineType.value.getValue() as EEngineType;
        const selectedVariantIds = this.variantPresetsFormArray.value.map((v) => v.variant._id);
        this.selectedVariants = this.variants.filter((v) => selectedVariantIds.includes(v._id));
    }

    public onVariantsChanged(variants: VariantModel[]): void {
        // Determine which variant is added/removed

        // Check Removed
        const deletedVariant = this.selectedVariants.find(selectedVariant => !variants.find(variant => variant._id === selectedVariant._id));
        if (deletedVariant) {
            this.variantPresetsFormArray.controls.forEach((control, index) => {
                if (control.value.variant._id === deletedVariant._id) {
                    this.variantPresetsFormArray.removeAt(index);
                }
            });
        }

        // Check Added
        const addedVariant = variants.find(variant => !this.selectedVariants.find(selectedVariant => selectedVariant._id === variant._id));
        this.selectedVariants = variants;

        if (addedVariant) {
            const variantPresetForm = new FormGroup({
                variant: new FormControl(addedVariant),
                masterPage: new FormControl(null),
                designLibrary: new FormControl(null, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                ruleset: new FormControl({
                    value: null,
                    disabled: true
                }, RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
            });
            const insertAt = this.variants.findIndex(variant => variant._id === addedVariant._id);
            this.variantPresetsFormArray.insert(insertAt, variantPresetForm);
        }
    }
}
