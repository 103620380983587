<h2>General information</h2>

@if (team) {
    <div class="general-info-container">
        <p class="body-strong">Team name</p>
        <p>{{team.name}}</p>

        <p class="body-strong">Owner</p>
        <div>
            <p>{{team.owner.fullName}}</p>
            <p>{{team.owner.email}}</p>
        </div>

        @if (team.companyName) {
            <p class="body-strong">Company</p>
            <p>{{team.companyName}}</p>
        }

        @if (team.companyName) {
            <p class="body-strong">Country</p>
            <p>{{team.country}}</p>
        }

        @if (team.contract?.startDate) {
            <p class="body-strong">Contract start date</p>
            <p>{{team.contract.startDate | RLDatePipe: dateFormats.DEFAULT}}</p>
        }
    </div>

    <hr>

    <div class="">
        <h2>Team Usage</h2>

        <h3>Seats</h3>

        <div class="user-count">
            <nuc-progress-bar [value]="(team.userCount / team.userLimit) * 100"></nuc-progress-bar>
            <p class="body-strong">Used seats</p>
            <p>{{team.userCount}} {{team.userCount === 1 ? 'user' : 'users'}} out
                of {{team.userLimit}} <span class="body-strong grey">total seats</span>
            </p>
        </div>

        <h3>Storage</h3>

        <div class="team-storage">
            <nuc-status-bar [value]="(team.storageUsed / team.storageLimit) * 100"></nuc-status-bar>
            <p class="body-strong">Used storage</p>
            <p>
                <span [class.danger]="(team.storageUsed / team.storageLimit) * 100 > 90">
                    {{team.storageUsed | filesize}}
                </span>
                out of {{team.storageLimit | filesize}} <span class="body-strong grey">total storage</span>
            </p>
        </div>

        <h3>Content items</h3>

        <div class="team-creation">
            <nuc-status-bar [value]="(team.publicationItemCreatedInCurrentContractPeriod / team.publicationItemLimit) * 100"></nuc-status-bar>
            <p class="body-strong">Created Content items - in current period</p>
            <p>
                <span [class.danger]="(team.publicationItemCreatedInCurrentContractPeriod / team.publicationItemLimit) * 100 > 90">
                    {{team.publicationItemCreatedInCurrentContractPeriod}}
                </span>
                of {{team.publicationItemLimit}} <span class="body-strong grey">total creations</span>
            </p>
        </div>

        @if (team.indesignGenerationLimit) {
            <h3>InDesign generation</h3>

            <div class="team-creation">
                <nuc-status-bar [value]="(team.indesignGenerationsInCurrentContractPeriod / team.indesignGenerationLimit) * 100"></nuc-status-bar>
                <p class="body-strong">InDesign generations - in current period</p>
                <p>
                    <span [class.danger]="(team.indesignGenerationsInCurrentContractPeriod / team.indesignGenerationLimit) * 100 > 90">
                        {{team.indesignGenerationsInCurrentContractPeriod}}
                    </span>
                    of {{team.indesignGenerationLimit}} <span class="body-strong grey">total creations</span>
                </p>
            </div>
        }
    </div>
} @else if (!(PERMISSIONS.GET_TEAM_DETAILS | userIsAllowedTo)) {
    <nuc-empty-state-component [small]="true"
                               imageUrl="assets/images/empty_states/no_permission_small.svg"
                               [title]="'No permission to view this page. \nPlease contact your administrator.'">
    </nuc-empty-state-component>
} @else {
    <rl-loading-indicator></rl-loading-indicator>
}

<hr>
<p class="section-header">Generation</p>
<p>Usage statistics about the materials created in all your publications</p>

<form [formGroup]="formGroup" class="usage_filters">
    <nuc-form-field label="Calendar period">
        <nuc-dropdown [items]="periods" formControlName="period" [nullOption]="false"></nuc-dropdown>
    </nuc-form-field>
</form>
<div class="usage">
    <canvas baseChart
            height="300"
            [data]="barChartData"
            [options]="barChartOptions"
            [legend]="barChartLegend"
            [type]="barChartType">
    </canvas>
</div>
