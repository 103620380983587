import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Toaster} from '../../../../classes/toaster.class';
import {ARPagedResponseDataModel} from '@relayter/core';
import {TemplateService} from '../../../../api/services/templates.service';
import {EEngineType, EPageType, TemplateModel} from '../../../../models/api/template.model';
import {TemplateUsageModel} from '../../../../models/api/template-usage.model';
import {AppConstants} from '../../../../app.constants';
import {TableActionTypeModel} from '../../../../models/ui/table-action-type.model';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {
    BUTTON_TYPE,
    DialogCustomContentConfig,
    EColumnSize,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    ISortOptionEvent,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogCustomContentService,
    NucDialogService
} from '@relayter/rubber-duck';
import {of, Subscription} from 'rxjs';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {IPaginator, PaginatorService} from '../../../../components/paginator/paginator.service';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {concatMap, filter, switchMap} from 'rxjs/operators';
import {TemplateInUseDialogComponent} from './template-in-use/template-in-use-dialog.component';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {MonitoredJobsService} from '../../../../api/services/monitored-jobs.service';
import {TemplateDetailComponent} from '../template-detail/template-detail.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DataFilterModel} from '../../../../models/ui/data-filter.model';
import {EChannel, EDataFieldTypes} from '../../../../app.enums';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {AdvancedFiltersDataService} from '../../../../api/services/advanced-filters.data-service';
import {TemplatesApiService} from '../../../../api/services/templates-api.service';

@Component({
    selector: 'rl-template-overview',
    templateUrl: 'template-overview.component.html',
    styleUrls: ['template-overview.component.scss'],
    providers: [PaginatorService, AdvancedFiltersDataService]
})
export class TemplateOverviewComponent extends RLTableComponent implements OnInit {
    public templates: TemplateModel[] = [];

    // table related
    public tableId = 'static-content-template-overview';
    public viewId = this.tableId;
    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name',
            size: EColumnSize.LARGE
        },
        {
            title: 'Engine type',
            key: 'engineType',
            sortProperty: 'engineType',
            size: EColumnSize.SMALL
        },
        {
            title: 'Channel',
            key: 'channel',
            sortProperty: 'channel',
            size: EColumnSize.SMALL
        },
        {
            title: 'Tags',
            key: 'tags',
            sortProperty: 'tags',
            format: (v) => v.join(', ')
        },
        {
            title: 'Template type',
            key: 'templateType',
            sortProperty: 'templateType',
            format: (v) => v?.name
        },
        {
            title: 'Number of pages',
            key: 'numberOfPages',
            sortProperty: 'numberOfPages',
            format: (v) => v === 1 ? EPageType.Single : EPageType.Spread
        },
        {
            title: 'Page size',
            key: 'pageSize',
            sortProperty: 'pageSize.width',
            format: (value) => `${value.width} x ${value.height}`
        },
        {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            size: EColumnSize.SMALL,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        },
        {
            title: 'Date updated',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            size: EColumnSize.SMALL,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        }
    ];

    public actionTypes: TableActionTypeModel[] = [];
    public dataFilters: DataFilterModel[] = [
        new DataFilterModel('Engine type', 'engineType', EDataFieldTypes.ENUM, Object.values(EEngineType).map((v) => new DropdownItem(v, v))),
        new DataFilterModel('Channel', 'channel', EDataFieldTypes.ENUM, Object.values(EChannel).map((v) => new DropdownItem(v, v)))
        // TODO: add tags filter after story REL-2280 (get tags call is being built)
    ];
    public filterValues: Record<string, any>;

    public pageIndex: number;
    public pageSize: number;
    public totalItemCount: number;
    public disableNextPage = true;
    public subscription: Subscription;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private fullModalService: FullModalService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private templateService: TemplateService,
                private templatesApiService: TemplatesApiService,
                private dialogService: NucDialogService,
                private dialogCustomContentService: NucDialogCustomContentService,
                private paginatorService: PaginatorService,
                private monitoredJobsService: MonitoredJobsService,
                private advancedFiltersDataService: AdvancedFiltersDataService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    /**
     * On authenticated init
     * Check if the user is allowed to view this page component
     */
    public ngOnInit(): void {
        this.initFromRoute();
        this.setTableActions();
        this.listenToAdvancedFilter();

        this.paginatorService.getPagination(this.viewId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result: IPaginator) => {
                this.pageIndex = result.pageIndex;
                this.pageSize = result.pageSize;
                this.updateUrl();
                this.getTemplates();
            });
    }

    /**
     * Subscribes to route params
     * When the table options are changed, the url is updated
     * This subscription forces a refresh of the data
     */
    private initFromRoute(): void {
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_TEMPLATES)) return;

        const params = this.route.snapshot.params;
        this.searchValue = params['search'] ? params['search'] : '';
        this.sortOrder = params['sortOrder'] ? params['sortOrder'] : '';
        this.sortProperty = params['sortProperty'] ? params['sortProperty'] : '';
        this.pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;

        this.paginatorService.setPageIndex(this.viewId, this.pageIndex);
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(this.permissions.PUT_TEMPLATE)) {
            this.actionTypes = [AppConstants.TABLE_ACTION_TYPES.EDIT];
        }
        if (this.userIsAllowedToPipe.transform(this.permissions.DELETE_TEMPLATE)) {
            this.actionTypes.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }
    }

    /**
     * Reload the data
     */
    public reload(): void {
        // If the index did not change, force reload
        if (this.pageIndex !== 1) {
            this.templates = [];
            this.searchValue = '';
            this.pageIndex = 1;
            this.totalItemCount = 0;
        }
        this.paginatorService.setPageIndex(this.viewId, this.pageIndex);
    }

    public openTemplateEditor(template?: TemplateModel): void {
        const modalTitle = 'Static content template editor';
        const modalDescription = 'Define your static content template and setup a master page.';
        const config = new FullModalConfig(modalTitle, modalDescription);
        config.confirmClose = true;

        // get new template
        const observable = template ? this.templateService.getTemplateDetails(template._id) : of(null);

        observable.pipe(
            concatMap((template) => {
                config.data = {template};
                return this.fullModalService.open(TemplateDetailComponent, config).afterClosed();
            }),
            takeUntilDestroyed(this.destroyRef))
            .subscribe((refresh: boolean) => {
                if (refresh) this.paginatorService.setPageIndex(this.viewId, this.pageIndex);
            });
    }

    /**
     * Get a paged list of the templates, and set the this.templates with the result
     */
    private getTemplates(): void {
        this.subscription?.unsubscribe();

        this.subscription = this.templatesApiService.getTemplates(this.filterValues?.channel,
            this.filterValues?.engineType,
            this.pageSize,
            (this.pageIndex - 1) * this.pageSize,
            null,
            undefined,
            this.searchValue,
            null,
            this.sortProperty,
            this.sortOrder
        ).subscribe({
            next: (res: ARPagedResponseDataModel<TemplateModel>) => {
                this.totalItemCount = res.total;
                this.templates = res.items;
                this.disableNextPage = this.pageIndex * this.pageSize >= this.totalItemCount;
            },
            error: Toaster.handleApiError
        });
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        if (sortEvent.column?.sortProperty) {
            this.sortProperty = sortEvent.column.sortProperty;
            this.sortOrder = sortEvent.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = null;
            this.sortOrder = '';
        }

        this.pageIndex = 1;
        this.paginatorService.setPageIndex(this.viewId, this.pageIndex);
    }

    public onSearchBarValueUpdated(searchValue: string): void {
        this.searchValue = searchValue;

        this.pageIndex = 1;
        this.paginatorService.setPageIndex(this.viewId, this.pageIndex);
    }

    /**
     * Handles clicks on table row actions ( edit | delete )
     */
    public handleTableRowAction(event: IActionClickEvent): void {
        if (event.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
            this.editTemplate(event.item as TemplateModel);
        }
        if (event.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
            this.checkUsageAndDeleteTemplateDialog(event.item as TemplateModel);
        }
    }

    /**
     * Check if template is in use, if not open the template component
     * @param {TemplateModel} template
     */
    public editTemplate(template: TemplateModel): void {
        this.templateService.getTemplateUsage(template._id).subscribe({
            next: (templateUsage: TemplateUsageModel) => {
                if (templateUsage.campaigns.length > 0) {
                    // when editing, we don't have to care about the usage in workflow automations
                    this.openEditDialog(template);
                } else {
                    this.openTemplateEditor(template);
                }
            },
            error: Toaster.handleApiError
        });
    }

    private openEditDialog(template: TemplateModel): void {
        const editDialogConfig = new NucDialogConfigModel('Are you sure?',
            'This template is currently used in one or more publication items in Relayter, editing it may break their layout.');
        const editDialog = this.dialogService.openDialog(editDialogConfig);
        editDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => editDialog.close());
        editDialogConfig.addAction('Confirm', BUTTON_TYPE.PRIMARY).subscribe(() => {
            editDialog.close();
            this.openTemplateEditor(template);
        });
    }

    private checkUsageAndDeleteTemplateDialog(template: TemplateModel): void {
        this.templateService.getTemplateUsage(template._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (usage: TemplateUsageModel) => {
                    if (usage.campaigns.length > 0 || usage.workflowAutomations.length > 0) {
                        this.openTemplateInUseModal(usage, template);
                    } else {
                        this.openDeleteDialog(template);
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private openDeleteDialog(template: TemplateModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete static content template',
            `Please confirm that you wish to delete ${template.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteTemplate(template);
        });
    }

    private openTemplateInUseModal(templateUsage: TemplateUsageModel, template: TemplateModel): void {
        const dialogConfig = new DialogCustomContentConfig('Delete static content template',
            'This template is being used by the following items:',
            {templateUsage, template});
        this.dialogCustomContentService.open(TemplateInUseDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                filter((result: JobModel) => !!result),
                switchMap((result: JobModel) => this.monitoredJobsService.getJobMonitor(result._id)),
                filter((result: JobModel) => result.status === EJobStatus.DONE),
                takeUntilDestroyed(this.destroyRef)
            ).subscribe({
            next: () => {
                this.paginatorService.setPageIndex(this.viewId, this.pageIndex);
            },
            error: Toaster.handleApiError
        });
    }

    /**
     * Responder. Invokes the deletion of the template
     * @param {TemplateModel} template
     * @returns {void}
     */
    public deleteTemplate(template: TemplateModel): void {
        this.templateService.deleteTemplate(template._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res: boolean) => {
                    if (res) {
                        Toaster.success('Template successfully deleted');
                        if (this.templates.length > 1) {
                            this.paginatorService.setPageIndex(this.viewId, this.pageIndex);
                        } else {
                            this.reload();
                        }
                    } else {
                        Toaster.error('There was an error while deleting the template');
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private updateUrl(): void {
        this.router.navigate([this.createMatrixUrl()], {relativeTo: this.route});
    }

    /**
     * Create a MatrixUrlModel so the url always has the correct amount of parameters
     * @return {MatrixUrlParams}
     */
    public createMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder, this.searchValue);
    }

    private listenToAdvancedFilter(): void {
        this.advancedFiltersDataService.getFilterValues()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (filterValues) => {
                    this.filterValues = filterValues;
                    this.paginatorService.setPageIndex(this.viewId, 1);
                },
                error: Toaster.handleApiError
            });
    }
}
