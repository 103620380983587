<form [formGroup]="presetFormGroup" *ngIf="presetFormGroup">
    <nuc-form-field label="Template name">
        <nuc-input placeholder="Template name" formControlName="name" type="string"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Template type">
        <nuc-suggestive-input
            formControlName="templateType"
            (searchTermChange)="onTemplateTypeSearchChanged($event)"
            (valueAdded)="onTemplateTypeAdded($event)"
            [options]="templateTypes || []"
            [maxValues]="1"
            type="string">
        </nuc-suggestive-input>
    </nuc-form-field>

    <nuc-form-field label="Tags">
        <nuc-suggestive-input
            formControlName="tags"
            (searchTermChange)="onTagChanged($event)"
            [options]="tagOptions"
            placeholder="Add tags">
        </nuc-suggestive-input>
    </nuc-form-field>

    <nuc-form-field label="Engine type">
        <nuc-dropdown [items]="engineTypeOptions" placeholder="Select an engine type" formControlName="engineType"
                      [nullOption]="false"></nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="Channel">
        <nuc-dropdown [items]="channelOptions" placeholder="Select a channel" formControlName="channel"
                      [nullOption]="false"></nuc-dropdown>
    </nuc-form-field>

    <template-variant-preset [formGroup]="presetFormGroup" [channel]="channel"
                             [engineType]="presetFormGroup.controls.engineType.getRawValue().getTitle()"></template-variant-preset>
    <nuc-form-field label="Number of pages">
        <nuc-dropdown [items]="numberOfPagesOptions" placeholder="Select number of pages"
                      formControlName="numberOfPages"
                      [nullOption]="false"></nuc-dropdown>
    </nuc-form-field>
</form>
