import {autoserialize} from 'cerialize';
import {GroupModel} from './group.model';

export class ContentRuleModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public collectionName: string;
    @autoserialize public values: string[];
    @autoserialize public groups: GroupModel[];
    @autoserialize public writeAccess: boolean;
    @autoserialize public appliedAt: string;
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;
}

export class ContentRulePostModel {
    @autoserialize public name: string;
    @autoserialize public collectionName: string;
    @autoserialize public values: string[];
    @autoserialize public groups: string[];
    @autoserialize public writeAccess: boolean;

    constructor(name: string, collectionName: string, values: string[], groups: string[], writeAccess: boolean) {
        this.name = name;
        this.collectionName = collectionName;
        this.values = values;
        this.groups = groups;
        this.writeAccess = writeAccess;
    }
}

export class ContentRulePatchModel {
    @autoserialize public name: string;
    @autoserialize public values: string[];
    @autoserialize public groups: string[];
    @autoserialize public collectionName: string;

    constructor(name: string, collectionName: string, values: string[], groups: string[]) {
        this.name = name;
        this.collectionName = collectionName;
        this.values = values;
        this.groups = groups;
    }
}
