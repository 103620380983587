<div class="header-row">
    <h1>Role management</h1>
    <nuc-button-bar>
        <nuc-button-primary text="Create role"
                            *ngIf="permissions.POST_ROLE | userIsAllowedTo"
                            (click)="onCreateRoleButtonClicked()">
        </nuc-button-primary>
    </nuc-button-bar>
</div>

<div *ngIf="(permissions.GET_ROLES | userIsAllowedTo); else noPermissionState" class="roles-management">
    <nuc-table
        [emptyStateTitle]="searchValue ? 'No item matches your search criteria.' : null"
        [items]="items"
        [columns]="columns"
        [actions]="actions"
        [tableId]="tableId"
        [prefixStorageKey]="storageKey"
        [loading]="!rolesSubscription?.closed"
        (actionClicked)="handleTableRowAction($event)"
        (itemClicked)="onItemClicked($event)"
        (sortOptionChange)="onSortOptionChanged($event)">

        <nuc-input button-bar-left
                   class="search"
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="resetPageIndex()"
                   placeholder="Search roles">
        </nuc-input>

        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="!rolesSubscription?.closed">
        </paginator>
    </nuc-table>
</div>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
