import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {
    ConnectionModel, ConnectionEndpointBody,
} from '../../models/api/connection.model';
import {ApiService} from './api.service';
import {ApiPostRequest} from '../requests/api-post.request';
import {ApiDeleteRequest} from '../requests/api-delete.request';
import {ApiPatchRequest} from '../requests/api-patch.request';

@Injectable({
    providedIn: 'root'
})
export class ConnectionApiService extends ApiService<ConnectionModel> {
    constructor() {
        super([ApiConstants.API_GROUP_CONNECTIONS], ConnectionModel);
    }

    public createEndpoint(connectionId: string, endpoint: ConnectionEndpointBody): Observable<ConnectionModel> {
        const request = new ApiPostRequest(
            [...this.groups, connectionId, ApiConstants.API_METHOD_ENDPOINT], ConnectionModel, endpoint);

        return this.apiRequestService.post(request);
    }

    public updateEndpoint(connectionId: string, endpointId: string, endpoint: ConnectionEndpointBody): Observable<ConnectionModel> {
        const request = new ApiPatchRequest(
            [...this.groups, connectionId, ApiConstants.API_METHOD_ENDPOINT], ConnectionModel, endpointId, endpoint);

        return this.apiRequestService.patch(request);
    }

    public deleteEndpoint(connectionId: string, endpointId: string): Observable<boolean> {
        const request = new ApiDeleteRequest(
            [...this.groups, connectionId, ApiConstants.API_METHOD_ENDPOINT, endpointId]);

        return this.apiRequestService.delete(request);
    }
}
