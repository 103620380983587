<div class="grid">
    <template-editor></template-editor>

    <div class="card-container" *ngIf="tabs?.length">

        <div class="bottom-line">
            <nuc-tab-bar [tabs]="tabs" [(activeTab)]="activeTab"
                         (activeTabChange)="onActiveTabChanged($event)"></nuc-tab-bar>
        </div>

        @switch (activeTab) {
            @case (presetTab) {
                <template-preset [formGroup]="formGroup"></template-preset>
            }
            @case (sizesTab) {
                <template-size [formGroup]="formGroup"></template-size>
            }
            @case (contentTab) {
                <template-content [formGroup]="formGroup"></template-content>
            }
            @case (variantsTab) {
                <template-variants [formGroup]="formGroup" [variants]="variants"></template-variants>
            }
        }
    </div>

</div>
