<nuc-table *ngIf="selectedPropertyConfig()?.tableView else otherView"
           [items]="items()"
           [columns]="selectedPropertyConfig().tableView.columns"
           [tableId]="tableId"
           [prefixStorageKey]="storageKey"
           [selectionMode]="ESelectionMode.EMIT"
           [actions]="selectedPropertyConfig().tableView.actions"
           (actionClicked)="handleTableRowAction($event)">
    <ng-container button-bar-right>
        <nuc-button-secondary *ngIf="propertyMetadata().stepId"
                              icon="nucicon_edit"
                              nucTooltip="Edit component"
                              (click)="handleComponentAction('EDIT')"/>

        <nuc-button-secondary *ngIf="propertyMetadata().stepId"
                              icon="nucicon_trash_fill"
                              nucTooltip="Delete component"
                              (click)="handleComponentAction('DELETE')"/>

        <nuc-button-secondary *ngIf="propertyMetadata().componentId"
                              (click)="addAction()"
                              text="Add action" />
    </ng-container>
</nuc-table>

<ng-template #otherView>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/empty-states-workflow.svg"
                               [small]="true"
                               title="Not implemented yet">
    </nuc-empty-state-component>
</ng-template>
