import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators} from '@angular/forms';
import {RLValidatorConstants} from '../../../../classes/validators/rl-validators.constant';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA, RDModule} from '@relayter/rubber-duck';
import {WebhookModel, WebhookPatchBody, WebhookPostBody} from '../../../../models/api/connection.model';
import {distinctUntilChanged} from 'rxjs/operators';
import {ConnectionService} from '../../../../api/services/connection.service';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {Toaster} from '../../../../classes/toaster.class';
import {EDataFieldCollectionName, EFormStatus} from '../../../../app.enums';
import {DataFieldsApiService} from '../../../../api/services/data-fields.api.service';
import {DataFieldModel} from '../../../../models/api/data-field.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import { ARApiError } from '@relayter/core';

export interface IMediaValetWebhookFormComponentData {
    connectionId: string;
    webhookData?: WebhookModel;
}

@Component({
    selector: 'standard-media-valet-webhook-form',
    templateUrl: './media-valet-webhook-form.component.html',
    styleUrls: ['./media-valet-webhook-form.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, RDModule]
})
export class MediaValetWebhookFormComponent implements OnInit {
    protected destroyRef: DestroyRef = inject(DestroyRef);
    public readonly VALIDATOR_MESSAGES = RLValidatorConstants.MESSAGES;

    public form: FormGroup;
    public metaDataFormArray: FormArray;
    public categories: IDropdownItem<string>[] = [];

    private saveButton: ButtonConfig;
    private cancelButton: ButtonConfig;
    public editMode: boolean;
    public dataFieldOptions: DataFieldModel[] = [];

    constructor(private fb: FormBuilder,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IMediaValetWebhookFormComponentData,
                private fullModalService: FullModalService,
                private dataFieldsService: DataFieldsApiService,
                private connectionsService: ConnectionService) {
        this.editMode = !!this.modalData.webhookData;
    }

    public ngOnInit(): void {
        this.setupFormGroup();
        this.getDataFields();
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, this.editMode ? 'Save' : 'Create', null, null, !this.editMode);
        this.cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        this.form.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status) => {
            this.saveButton.disabled = status !== EFormStatus.VALID; // disable the button based on the form status
        });

        this.fullModalService.setConfirmClose(true);

        const cancel = new FullModalActionModel(this.cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close(false));

        const save = new FullModalActionModel(this.saveButton);
        save.observable.subscribe(() => {
            this.saveButton.loading = true;
            this.saveWebhook();
        });

        this.fullModalService.setModalActions([cancel, save]);
    }

    private setupFormGroup(): void {
        const webhook = this.modalData.webhookData;
        const event = webhook?.Events?.[0]?.Name || '';
        const categoriesItems = (webhook?.categories || []).map((v) => new DropdownItem(v, v, true));
        this.metaDataFormArray = new FormArray([], [this.noDuplicateValuesValidator('name'), this.noDuplicateValuesValidator('dataFieldName')]);

        this.form = new FormGroup({
            event: new FormControl({value: event, disabled: this.editMode}, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            categories: new FormControl({value: categoriesItems, disabled: this.editMode}, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            metaData: this.metaDataFormArray
        });
    }

    private noDuplicateValuesValidator(fieldName: string): ValidatorFn {
        return (formArray: FormArray): null => {
            const subFormGroups = formArray.controls;

            const duplicateFieldNames = subFormGroups
                .map((item) => item.value[fieldName])
                .filter((element, index, array) => {
                    return !!element && array.indexOf(element) !== index;
                });

            subFormGroups.forEach((subFormGroup) => {
                const nameControl = subFormGroup.get(fieldName);
                if (nameControl.errors && !nameControl.errors['duplicateValues']) {
                    // return if another validator has already found an error on the nameControl
                    return;
                }

                if (duplicateFieldNames.includes(nameControl.value)) {
                    nameControl.markAsDirty();
                    nameControl.setErrors({duplicateValues: true});
                } else {
                    nameControl.setErrors(null);
                }
            });
            return;
        };
    }

    private saveWebhook(): void {
        const metaData = this.metaDataFormArray.value.map((metaDataForm) => {
            return {
                name: metaDataForm.name,
                dataFieldName: metaDataForm.dataFieldName.getTitle()
            };
        });

        if (this.editMode) {
            const webhook = new WebhookPatchBody(metaData);
            this.connectionsService.updateWebhook(this.modalData.connectionId, this.modalData.webhookData._id, webhook)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => this.fullModalService.close(result),
                    error: (error) => this.handleWebhookError(error)
                });
            // save dataMappings
        } else { // create mode
            const webhook = new WebhookPostBody(this.form.value.event, this.form.value.categories.map(item => item.value), metaData);
            this.connectionsService.saveWebhook(this.modalData.connectionId, webhook)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => this.fullModalService.close(result),
                    error: (error) => this.handleWebhookError(error)
                });
        }
    }

    private handleWebhookError(error: ARApiError): void {
        if (error.code === 6001) {
            Toaster.error(error.message, 'mediaValet error');
        } else {
            Toaster.handleApiError(error);
        }
        this.saveButton.loading = false;
    }

    public onAddDataClicked(): void {
        const controlConfig = {
            name: [null, Validators.required],
            dataFieldName: [null, Validators.required]
        };
        this.metaDataFormArray.push(this.fb.group(controlConfig));
    }

    public onDeleteDataClicked(index: number): void {
        if (this.metaDataFormArray.controls[index]) this.metaDataFormArray.removeAt(index);
    }

    private getDataFields(): void {
        this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.ASSET)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (dataFields) => {
                    this.dataFieldOptions = dataFields;
                    if (this.editMode && this.modalData?.webhookData.metaData) {
                        // load dataFieldMappings
                        for (const dataMapping of this.modalData.webhookData.metaData) {
                            const dataFieldOption = this.dataFieldOptions.find((option) => option.getTitle() === dataMapping.dataFieldName);
                            this.metaDataFormArray.push(this.fb.group({
                                name: [dataMapping.name, Validators.required],
                                dataFieldName: [dataFieldOption, Validators.required]
                            }));
                        }
                    }
                },
                error: Toaster.handleApiError
            });
    }
}
