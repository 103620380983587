<ng-container *ngIf="layerOptions; else loadingIndicator">
        <form class="mainForm" [formGroup]="formGroup">
        <nuc-form-field label="Rule name">
            <nuc-input formControlName="name" placeholder="Rule name"></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Layer">
            <nuc-suggestive-input [options]="layerOptions"
                                  [enableNewValue]="true"
                                  prefix="nucicon_search"
                                  (searchTermChange)="searchLayers($event)"
                                  maxValues="1"
                                  [total]="totalLayers"
                                  (requestData)="getLayers($event.offset)"
                                  formControlName="layer"
                                  placeholder="Choose a layer"></nuc-suggestive-input>
        </nuc-form-field>

        <div class="position">
            <nuc-form-field label="X">
                <nuc-input formControlName="x" placeholder="x" type="string"></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Y">
                <nuc-input formControlName="y" placeholder="y" type="string"></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Width">
                <nuc-input formControlName="w" placeholder="Width" type="string"></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Height">
                <nuc-input formControlName="h" placeholder="Height" type="string"></nuc-input>
            </nuc-form-field>
        </div>

        <div class="format">
            <nuc-form-field label="Display Format">
                <nuc-dropdown [items]="formatOptions" [total]="formatOptions.length" placeholder="Format"
                              formControlName="format"></nuc-dropdown>
            </nuc-form-field>

            <nuc-form-field label="Horizontal margin">
                <nuc-input formControlName="horizontalMargin" placeholder="Horizontal margin" type="string"></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Vertical margin">
                <nuc-input formControlName="verticalMargin" placeholder="Vertical margin" type="string"></nuc-input>
            </nuc-form-field>
        </div>

        <div class="arrayoptions">
            <nuc-form-field label="Limit assets" >
                <nuc-input
                    formControlName="limit"
                    placeholder="0"
                    type="number"
                    nucTooltip="Limit the number of assets to generate. 0 means all assets will be generated."></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Skip assets">
                <nuc-input
                    formControlName="skip"
                    placeholder="0"
                    type="number"
                    nucTooltip="Skip assets from the linked assets. 0 means no assets will be skipped."></nuc-input>
            </nuc-form-field>
        </div>
    </form>

    <h2 class="header">Conditions</h2>
    <hr>
    <condition-group-form [conditionGroups]="conditionGroups" [ruleProperties]="ruleProperties"></condition-group-form>
    <div class="actions">
        <nuc-button-secondary (click)="addNewConditionGroup()" text="Add condition"></nuc-button-secondary>
    </div>
</ng-container>
<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>
