<div class="header-row">
    <h1>Static content template overview</h1>
    <nuc-button-bar>
        <nuc-button-primary *ngIf="permissions.POST_TEMPLATE | userIsAllowedTo"
                            text="Add template"
                            (click)="openTemplateEditor()">
        </nuc-button-primary>
    </nuc-button-bar>
</div>

<nuc-table *ngIf="permissions.GET_TEMPLATES | userIsAllowedTo else noPermissionState"
           [items]="templates"
           [columns]="columns"
           [actions]="actionTypes"
           [tableId]="tableId"
           [loading]="!subscription?.closed"
           [prefixStorageKey]="storageKey"
           emptyStateTitle="{{searchValue ? 'No matching results.' : 'This section is empty. Add the first item.'}}"
           (actionClicked)="handleTableRowAction($event)"
           (sortOptionChange)="onSortOptionChanged($event)">

    <div button-bar-left class="button-bar-left-container">
        <nuc-input [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="onSearchBarValueUpdated($event)"
                   placeholder="Search templates by name">
        </nuc-input>
        <div class="vertical-line"></div>
        <rl-data-filter [filters]="dataFilters"></rl-data-filter>
    </div>

    <paginator button-bar-right
               [viewId]="viewId"
               [loading]="!subscription?.closed"
               [disableNextPage]="disableNextPage">
    </paginator>
</nuc-table>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
