import {Component, OnInit} from '@angular/core';
import {WorkflowConfigurationModel} from '../../../../models/api/workflow-configuration.model';
import {RLCountsModel} from '../../../../api/response/rl-counts.model';
import {CustomWorkflowStepModel} from '../../../../models/api/custom-workflow-step.model';
import {EWorkflowConfigurationProperty} from './workflow-configuration-property.config';
import {ActivatedRoute, Router} from '@angular/router';
import {FullModalConfig, FullModalService} from '@relayter/rubber-duck';
import {WorkflowConfigurationsDetailsService} from './workflow-configurations-details.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {RLCountModel} from '../../../../api/response/rl-count.model';
import {
    IWorkflowConfigurationStepFormData,
    WorkflowConfigurationStepFormComponent
} from '../../../../forms/workflow-configuration-step-form/workflow-configuration-step-form.component';
import {Toaster} from '../../../../classes/toaster.class';
import {
    IWorkflowConfigurationTransitionFormData,
    WorkflowConfigurationTransitionFormComponent
} from '../../../../forms/workflow-configuration-transition-form/workflow-configuration-transition-form.component';
import {
    IWorkflowConfigurationActionFormData,
    WorkflowConfigurationActionFormComponent
} from '../../../../forms/workflow-configuration-action-form/workflow-configuration-action-form.component';
import {
    IWorkflowConfigurationComponentFormData,
    WorkflowConfigurationComponentFormComponent
} from '../../../../forms/workflow-configuration-component-form/workflow-configuration-component-form.component';
import {RLBaseComponent} from '../../../../components/rl-base-component/rl-base.component';
import {AppConstants} from '../../../../app.constants';
import {
    CustomWorkflowComponentModel,
    ECustomWorkflowComponentType
} from '../../../../models/api/custom-workflow-component.model';
import {
    WorkflowConfigurationFilterFormComponent
} from '../../../../forms/workflow-configuration-filter-form/workflow-configuration-filter-form.component';

interface IMenuItem {
    title: string;
    property: EWorkflowConfigurationProperty;
}

@Component({
    selector: 'workflow-configuration-details-component',
    templateUrl: 'workflow-configuration-details.component.html',
    styleUrls: ['workflow-configuration-details.component.scss'],
    providers: [WorkflowConfigurationsDetailsService]
})
export class WorkflowConfigurationDetailsComponent extends RLBaseComponent implements OnInit {
    private workflowConfigurationId: string;
    public workflowConfiguration: WorkflowConfigurationModel;
    public counts = new RLCountsModel();
    public activeStep: CustomWorkflowStepModel;
    public activeComponent: CustomWorkflowComponentModel;
    public stickyLogComponent: CustomWorkflowComponentModel;
    public overviewComponent: CustomWorkflowComponentModel;

    public menuItems: IMenuItem[] = [
        {title: 'step', property: EWorkflowConfigurationProperty.STEPS},
        {title: 'component', property: EWorkflowConfigurationProperty.COMPONENTS},
        {title: 'transition', property: EWorkflowConfigurationProperty.TRANSITIONS},
        {title: 'action', property: EWorkflowConfigurationProperty.ACTIONS},
        {title: 'filter', property: EWorkflowConfigurationProperty.FILTERS}
    ];

    constructor(private route: ActivatedRoute,
                private router: Router,
                private fullModalService: FullModalService,
                private workflowConfigurationsDetailsService: WorkflowConfigurationsDetailsService) {
        super();
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();

        let initialStepId = this.route.children[0]?.snapshot.params['stepId'];
        let initialComponentId = this.route.children[0]?.snapshot.params['componentId'];

        // this part is responsible for the workflow indicator
        this.workflowConfigurationsDetailsService.workflowConfiguration
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((workflowConfiguration) => {
                this.workflowConfiguration = workflowConfiguration;
                this.createFakeCountsAndTransitions();

                if (initialStepId) { // for the same time, loading the step detail page and set the indicator
                    this.activeStep = this.workflowConfiguration.steps.find((step) => step._id === initialStepId);

                    if (!this.activeStep) {
                        Toaster.error(`Cannot find step: ${initialStepId}`);
                        this.router.navigate([AppConstants.CONTEXT_URL.WORKFLOWS,
                            AppConstants.CONTEXT_URL.CONFIGURATIONS, this.workflowConfigurationId]);
                    }
                    initialStepId = null; // Afterward unset initial id because it's used once
                }

                if (initialComponentId) {
                    // first load workflow component page
                    this.activeComponent = this.workflowConfiguration.components.find((component) => component._id === initialComponentId);

                    if (!this.activeComponent) {
                        Toaster.error(`Cannot find component: ${initialComponentId}`);
                        this.router.navigate([AppConstants.CONTEXT_URL.WORKFLOWS,
                            AppConstants.CONTEXT_URL.CONFIGURATIONS, this.workflowConfigurationId]);
                    }
                    initialComponentId = null; // Afterward unset initial id because it's used once
                }

                // get the components
                this.stickyLogComponent =
                    this.workflowConfiguration.components.find((component) => component.componentType === ECustomWorkflowComponentType.STICKY_LOG);
                this.overviewComponent =
                    this.workflowConfiguration.components.find((component) => component.componentType === ECustomWorkflowComponentType.OVERVIEW);
            });
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.workflowConfigurationId = params['workflowConfigurationId'];
        this.workflowConfigurationsDetailsService.workflowConfigurationId.set(this.workflowConfigurationId);
    }

    private createFakeCountsAndTransitions(): void {
        this.counts.steps = this.workflowConfiguration.steps.map((step, index) => {
            const countModel = new RLCountModel();
            countModel._id = step._id;
            countModel.count = index !== 0 ? 1 : 0;

            return countModel;
        });
        this.counts.transitions = [];
    }

    public onMenuItemClicked(property: EWorkflowConfigurationProperty): void {
        switch (property) {
            case EWorkflowConfigurationProperty.STEPS:
                this.openCreateWorkflowConfigurationStep();
                return;
            case EWorkflowConfigurationProperty.TRANSITIONS:
                this.openCreateWorkflowConfigurationTransition();
                return;
            case EWorkflowConfigurationProperty.ACTIONS:
                this.openCreateWorkflowConfigurationAction();
                return;
            case EWorkflowConfigurationProperty.FILTERS:
                this.openCreateWorkflowConfigurationFilter();
                return;
            case EWorkflowConfigurationProperty.COMPONENTS:
                if (this.workflowConfiguration.components.length >= 2) {
                    Toaster.warn('Maximum of 2 components are allowed.');
                } else {
                    this.openCreateWorkflowConfigurationComponent();
                }
                return;
            default:
                return;
        }
    }

    public openCreateWorkflowConfigurationStep(): void {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .subscribe({
                next: (workflowConfiguration) => {
                    const modalConfig = new FullModalConfig('Add workflow configuration step',
                        'Enter the information to create a new workflow configuration step.',
                        {workflowConfigurationId: workflowConfiguration._id} as IWorkflowConfigurationStepFormData);

                    this.fullModalService.open(WorkflowConfigurationStepFormComponent, modalConfig).afterClosed().subscribe((result) => {
                        if (result) {
                            this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                        }
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public openCreateWorkflowConfigurationTransition(): void {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .subscribe({
                next: (workflowConfiguration) => {
                    const modalData = {
                        workflowConfiguration,
                        workflowConfigurationSteps: workflowConfiguration.steps
                    } as IWorkflowConfigurationTransitionFormData;

                    const modalConfig = new FullModalConfig('Add workflow configuration transition',
                        'Enter the information to create a new workflow configuration transition.',modalData);
                    modalConfig.confirmClose = true;

                    this.fullModalService.open(WorkflowConfigurationTransitionFormComponent, modalConfig).afterClosed().subscribe((result) => {
                        if (result) {
                            this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                        }
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public openCreateWorkflowConfigurationAction(): void {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .subscribe({
                next: (workflowConfiguration) => {
                    const modalData = {
                        workflowConfiguration
                    } as IWorkflowConfigurationActionFormData;

                    const modalConfig = new FullModalConfig('Add workflow configuration action',
                        'Enter the information to create a new workflow configuration action.', modalData);
                    modalConfig.confirmClose = true;

                    this.fullModalService.open(WorkflowConfigurationActionFormComponent, modalConfig).afterClosed().subscribe((result) => {
                        if (result) {
                            this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                        }
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public openCreateWorkflowConfigurationFilter(): void {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .subscribe({
                next: (workflowConfiguration) => {
                    const modalData = {
                        workflowConfiguration
                    } as IWorkflowConfigurationActionFormData;

                    const modalConfig = new FullModalConfig('Add workflow configuration filter',
                        'Enter the information to create a new workflow configuration filter.', modalData);
                    modalConfig.confirmClose = true;

                    this.fullModalService.open(WorkflowConfigurationFilterFormComponent, modalConfig).afterClosed().subscribe((result) => {
                        if (result) {
                            this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                        }
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public openCreateWorkflowConfigurationComponent(): void {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .subscribe({
                next: (configuration) => {
                    if (this.activeStep) {
                        const step = configuration.steps.find((c) => c._id === this.activeStep._id);
                        if (!step) {
                            Toaster.error('Could not find workflow configuration step');
                            return;
                        }
                    }

                    const modalData = {
                        workflowConfiguration: this.workflowConfiguration,
                        workflowConfigurationStep: this.activeStep
                    } as IWorkflowConfigurationComponentFormData;

                    const modalConfig = new FullModalConfig('Add workflow configuration component',
                        'Enter the information to create a new workflow component.', modalData);
                    modalConfig.confirmClose = true;

                    this.fullModalService.open(WorkflowConfigurationComponentFormComponent, modalConfig).afterClosed().subscribe((result) => {
                        if (result) {
                            this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                        }
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public setActiveStep(step: CustomWorkflowStepModel): void {
        if (this.activeStep?._id === step?._id && !this.activeComponent) return;

        // navigate to the correct path
        if (step) {
            // from step to step or from workflow to step
            this.activeStep = step;
            this.activeComponent = null;
            this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
            this.router.navigate([AppConstants.CONTEXT_URL.WORKFLOWS, AppConstants.CONTEXT_URL.CONFIGURATIONS,
                this.workflowConfigurationId, AppConstants.CONTEXT_URL.STEPS, step._id]);
        } else if (this.activeStep || this.activeComponent) {
            // from step to workflow
            this.activeStep = null;
            this.activeComponent = null;
            this.router.navigate([AppConstants.CONTEXT_URL.WORKFLOWS, AppConstants.CONTEXT_URL.CONFIGURATIONS, this.workflowConfigurationId]);
        }
    }

    public navigateToComponent(event: MouseEvent, component: CustomWorkflowComponentModel): void {
        event.stopPropagation();
        this.activeComponent = component;
        this.activeStep = null;
        this.router.navigate([AppConstants.CONTEXT_URL.WORKFLOWS, AppConstants.CONTEXT_URL.CONFIGURATIONS,
            this.workflowConfigurationId, AppConstants.CONTEXT_URL.COMPONENTS, component?._id]);
    }
}
