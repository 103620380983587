import {autoserialize, autoserializeAs} from 'cerialize';
import {UserModel} from './user.model';
import {VariantModel} from './variant.model';
import {AfterEffectsProjectFileModel} from './after-effects-project-file.model';
import {AnimatedContentRulesetModel} from './animated-content-ruleset.model';

export enum EAnimatedContentEngineType {
    AFTER_EFFECTS = 'AFTER_EFFECTS'
}

export class AnimatedContentTemplateVariantPresetModel {
    @autoserializeAs(VariantModel) public variant: VariantModel;
    @autoserializeAs(AfterEffectsProjectFileModel) afterEffectsProjectFile: AfterEffectsProjectFileModel;
    @autoserializeAs(AnimatedContentRulesetModel) public animatedContentRuleset: AnimatedContentRulesetModel;
}

export class AnimatedContentTemplateModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserializeAs(AnimatedContentRulesetModel) public animatedContentRuleset: AnimatedContentRulesetModel;
    @autoserialize public engineType: string;
    @autoserializeAs(AfterEffectsProjectFileModel) afterEffectsProjectFile: AfterEffectsProjectFileModel;
    @autoserializeAs(AnimatedContentTemplateVariantPresetModel) variantPresets: AnimatedContentTemplateVariantPresetModel[];
    @autoserializeAs(UserModel) public createdBy: UserModel;
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;
}
