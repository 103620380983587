import {Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {TeamAccountService} from '../../../../api/services/team-account.service';
import {TeamModel} from '../../../../models/api/team.model';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../../classes/toaster.class';
import {AppConstants} from '../../../../app.constants';
import {ChartData, ChartOptions, ChartType} from 'chart.js';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {FormControl, FormGroup} from '@angular/forms';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {BaseChartDirective} from 'ng2-charts';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {UsagesService} from '../../../../api/services/usages.service';
import {EPeriodGranularity, EUsageType, UsageModel} from '../../../../models/api/usage.model';
import {DatePipe, TitleCasePipe} from '@angular/common';

class PeriodGranularityForm {
    period: FormControl<DropdownItem<EPeriodGranularity>>;
}

@Component({
    selector: 'rl-general-information',
    templateUrl: './general-information.component.html',
    styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
    private destroyRef: DestroyRef = inject(DestroyRef);
    public readonly dateFormats = RLDatePipe.dateFormats;

    public barChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {duration: 0}
    };
    public barChartType: ChartType = 'bar';
    public barChartLegend = false;
    public barChartData: ChartData = {
        datasets: [{
            data: [],
            label: 'Total material created',
            backgroundColor: AppConstants.FIRST_BRAND_COLOR,
            hoverBackgroundColor: AppConstants.FIRST_BRAND_COLOR
        }],
    };

    public team: TeamModel;

    public formGroup = new FormGroup<PeriodGranularityForm>({period: new FormControl<DropdownItem<EPeriodGranularity>>(null)});

    public periods: DropdownItem<EPeriodGranularity>[] =
        [EPeriodGranularity.YEAR, EPeriodGranularity.MONTH, EPeriodGranularity.DAY]
            .map((period) => new DropdownItem<EPeriodGranularity>(this.titleCasePipe.transform(period), period));

    public PERMISSIONS = AppConstants.PERMISSIONS;

    constructor(private teamService: TeamAccountService,
                private usagesService: UsagesService,
                private datePipe: DatePipe,
                private titleCasePipe: TitleCasePipe,
                private userIsAllowedToPipe: UserIsAllowedToPipe) {
    }

    public ngOnInit(): void {

        if (this.userIsAllowedToPipe.transform(this.PERMISSIONS.GET_TEAM_DETAILS)) {

            this.teamService.getTeamDetails().pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (team) => this.team = team,
                    error: Toaster.handleApiError
                });
        }

        const month = this.periods.find((period) => period.getValue() === EPeriodGranularity.MONTH);

        this.formGroup.valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((value: { period: DropdownItem<EPeriodGranularity> }) => {
                this.getUsages(value.period.getValue());
            });

        this.formGroup.controls.period.patchValue(month);
    }

    private getUsages(period: EPeriodGranularity): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_STATISTICS)) {
            const now = new Date();
            // get the 5 years ago date time, first make a copy of now!
            const fiveYearsAgo = new Date(now.getTime());
            fiveYearsAgo.setFullYear(now.getFullYear() - 5);

            this.usagesService.getUsages(EUsageType.PUBLICATION_ITEM_CREATION, period, fiveYearsAgo)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (results: ARPagedResponseDataModel<UsageModel>) => {

                        const allDates = results.items.length > 0
                            ? this.getAllNeededDates(period, new Date(results.items[results.items.length - 1].period.value),
                                new Date(results.items[0].period.value))
                            : [];

                        this.barChartData.labels = allDates;
                        this.barChartData.datasets[0].data = allDates.map((date) => {
                            const foundUsage = results.items.find((item) => item.period.value === date);
                            return foundUsage?.value || 0;
                        });
                        this.chart.update();
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    private getAllNeededDates(period: EPeriodGranularity, minDate: Date, maxDate: Date): string[] {
        const allDates = [];

        const date = maxDate;

        while (date >= minDate) {

            switch (period) {
                case EPeriodGranularity.DAY: {
                    allDates.push(this.datePipe.transform(date, 'yyyy-LL-dd'));
                    date.setDate(date.getDate() - 1);
                    break;
                }
                case EPeriodGranularity.MONTH: {
                    allDates.push(this.datePipe.transform(date, 'yyyy-LL'));
                    date.setMonth(date.getMonth() - 1);
                    break;
                }
                case EPeriodGranularity.YEAR: {
                    allDates.push(this.datePipe.transform(date, 'yyyy'));
                    date.setFullYear(date.getFullYear() - 1);
                    break;
                }
            }
        }

        return allDates;
    }
}
