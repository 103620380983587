<div class="header">
    <h1>After Effects project files</h1>
    <nuc-button-bar>
        @if (permissions.POST_AFTER_EFFECTS_PROJECT_FILE_JOBS | userIsAllowedTo) {
            <nuc-button-primary text="Add After Effects project file"
                                (click)="openAfterEffectsProjectFile()"/>
        }
    </nuc-button-bar>
</div>

@if (permissions.GET_AFTER_EFFECTS_PROJECT_FILES | userIsAllowedTo) {
    <nuc-table [items]="items"
               [columns]="columns"
               [loading]="loading"
               [emptyStateTitle]="'This section is empty. Add the first item.'"
               [tableId]="tableId"
               [actions]="actions"
               (actionClicked)="onActionClicked($event)"
               (itemClicked)="onTableRowClicked($event)"
               [prefixStorageKey]="storageKey"
               (sortOptionChange)="onSortOptionChanged($event)">
        <nuc-input button-bar-left
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="onSearchBarValueUpdated($event)"
                   placeholder="Search">
        </nuc-input>

        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="loading">
        </paginator>
    </nuc-table>
} @else {
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission_small.svg"
                               small="true"
                               title="You or your team does not have permissions to view this section. Contact the team owner to enable this section.">
    </nuc-empty-state-component>
}
