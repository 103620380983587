import {ITabBarItem} from '@relayter/rubber-duck/lib/modules/tab-bar/config/tab-bar-item.interface';

export class TabBarRoutableItem implements ITabBarItem {
    public disabled?: boolean;
    public active?: boolean;

    constructor(public title: string, public url: string[], public permissions: string | string[], public index?: number) {
        this.title = title;
        this.url = url;
        this.permissions = permissions;
        this.index = index;
    }

    get urlString() {
        return this.url.join('/');
    }
}
