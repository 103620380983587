import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StaticContentRulesetsOverviewComponent} from './static-content-rulesets-overview/static-content-rulesets-overview.component';
import {RDModule} from '@relayter/rubber-duck';
import {ComponentsModule} from '../../components/components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StaticContentRulesetDetailComponent} from './static-content-ruleset-detail/static-content-ruleset-detail.component';
import {
    StaticContentRulesetItemGroupTableComponent
// eslint-disable-next-line max-len
} from './static-content-ruleset-detail/static-content-ruleset-item-table/static-content-ruleset-item-group-table/static-content-ruleset-item-group-table.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {StaticContentRulesetItemFormComponent} from './static-content-ruleset-item-form/static-content-ruleset-item-form.component';
import {StaticContentRulesetAssetItemFormComponent} from './static-content-ruleset-asset-item-form/static-content-ruleset-asset-item-form.component';
import {StaticContentRulesetFormComponent} from './static-content-ruleset-form/static-content-ruleset-form.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {StringTransformPipe} from './pipes/string-transform.pipe';
import {PipesModule} from '../../pipes/pipes.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {
    StaticContentRulesetAssetItemTableComponent
// eslint-disable-next-line max-len
} from './static-content-ruleset-detail/static-content-ruleset-item-table/static-content-ruleset-asset-item-table/static-content-ruleset-asset-item-table.component';
import {
    StaticContentRulesetItemGroupFormComponent
} from './static-content-ruleset-item-group-form/static-content-ruleset-item-group-form.component';
import {
    StaticContentRulesetLibItemTableComponent
// eslint-disable-next-line max-len
} from './static-content-ruleset-detail/static-content-ruleset-item-table/static-content-ruleset-lib-item-table/static-content-ruleset-lib-item-table.component';
import {MatMenuModule} from '@angular/material/menu';
import {FormComponentsModule} from '../../forms/form-components.module';

@NgModule({
    declarations: [
        StaticContentRulesetsOverviewComponent,
        StaticContentRulesetDetailComponent,
        StaticContentRulesetAssetItemTableComponent,
        StaticContentRulesetItemGroupTableComponent,
        StaticContentRulesetItemFormComponent,
        StaticContentRulesetAssetItemFormComponent,
        StaticContentRulesetLibItemTableComponent,
        StaticContentRulesetFormComponent,
        StaticContentRulesetItemGroupFormComponent,
        StringTransformPipe,
    ],
    exports: [
        StringTransformPipe
    ],
    imports: [
        CommonModule,
        RDModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        MatExpansionModule,
        MatMenuModule,
        OverlayModule,
        DragDropModule,
        PipesModule,
        FormComponentsModule
    ]
})
export class StaticContentRulesetsModule {
}
