<div class="header">
    <h2 class="detail-header">Step details: {{step().name}}</h2>

    <nuc-button-secondary text="Add component" (click)="addComponent()"></nuc-button-secondary>
</div>

<ng-container *ngIf="tabBarItems?.length else noComponentState">
    <nuc-tab-bar [tabs]="tabBarItems" [activeTab]="selectedTab" (activeTabChange)="onActiveTabChange($event)"></nuc-tab-bar>
    <hr class="full-width-tab-hr">

    <workflow-configuration-detail-property-component [propertyMetadata]="propertyMetadata"></workflow-configuration-detail-property-component>
</ng-container>

<ng-template #noComponentState>
    <nuc-empty-state-component title="This section is empty."
                               [small]="true"
                               imageUrl="assets/images/general_empty_state.svg">
    </nuc-empty-state-component>
</ng-template>

