import {Component, OnDestroy, OnInit} from '@angular/core';
import {StaticContentRulesetModel} from '../models/api/static-content-ruleset.model';
import {
    BUTTON_TYPE,
    EColumnSize,
    EColumnType,
    ESelectionMode,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    IItemClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {StaticContentRulesetFormComponent} from '../static-content-ruleset-form/static-content-ruleset-form.component';
import {filter, switchMap, takeUntil} from 'rxjs/operators';
import {AppConstants} from '../../../app.constants';
import {PaginatorService} from '../../../components/paginator/paginator.service';
import {RLTableComponent} from '../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../api/services/user-settings-storage.service';
import {CursorArray} from '../../../api/api-cursor';
import {ActivatedRoute, Router} from '@angular/router';
import {MatrixUrlParams} from '../../../models/ui/matrix-url-params.model';
import {ApiConstants} from '../../../api/api.constant';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {Toaster} from '../../../classes/toaster.class';
import {EFormatRulesetJobs, StaticContentRulesetService, IFormatRulesetCopyJobData} from '../../../api/services/static-content-ruleset.service';
import {Subject, Subscription} from 'rxjs';
import {ARPagedResponseDataModel} from '@relayter/core';
import {RLDatePipe} from '../../../pipes/rl-date.pipe';
import {EJobStatus, JobModel} from '../../../models/api/job.model';
import {MonitoredJobsService} from '../../../api/services/monitored-jobs.service';
import {ItemUsedModel} from '../../../models/api/item-used.model';

export interface IRulesetFormComponentData {
    ruleSet: StaticContentRulesetModel;
}

@Component({
    selector: 'static-content-rulesets',
    templateUrl: './static-content-rulesets-overview.component.html',
    styleUrls: ['./static-content-rulesets-overview.component.scss'],
    providers: [PaginatorService]
})
export class StaticContentRulesetsOverviewComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'format-ruleset-overview-table';

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            type: EColumnType.DEFAULT,
            sortProperty: 'name',
            size: EColumnSize.LARGE
        }, {
            title: 'Library items count',
            key: 'items.length'
        }, {
            title: 'Asset items count',
            key: 'assetItems.length'
        }, {
            title: 'Item group count',
            key: 'itemGroups.length'
        },{
            title: 'Engine types',
            key: 'engineTypes'
        }, {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        }
    ];

    public actions: ITableAction[];
    public ESelectionMode = ESelectionMode;
    public search: string;

    public items: StaticContentRulesetModel[] = [];
    public pageIndex: number;
    public pageSize: number = 2;
    public hasNext: boolean;
    private apiCursor: CursorArray;
    private sortColumn: ITableColumn;

    private onDestroySubject = new Subject<void>();
    public subscription: Subscription;

    constructor(userSettingsStorageService: UserSettingsStorageService,
                private fullModalService: FullModalService,
                private paginatorService: PaginatorService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private indesignRulesetService: StaticContentRulesetService,
                private dialogService: NucDialogService,
                private monitoredJobsService: MonitoredJobsService,
                private router: Router,
                private route: ActivatedRoute) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.setTableActions();

        this.paginatorService.getPagination(this.tableId).subscribe((result: { pageIndex: number; pageSize: number }) => {
            if (result.pageIndex === 1 || result.pageSize !== this.pageSize) { // reset cursor when needed
                this.apiCursor.reset(result.pageIndex, this.sortColumn?.sortDuplicates);
            }
            this.pageIndex = result.pageIndex;
            this.pageSize = result.pageSize;

            this.updateUrl();
            this.getFormatRulesets();
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        const sortProperty = params['sortProperty'] ? params['sortProperty'] : '';
        this.sortOrder = params['sortOrder'] && ([ApiConstants.API_QUERY_VALUE_ASC, ApiConstants.API_QUERY_VALUE_DESC]
            .includes(params['sortOrder'])) ? params['sortOrder'] : '';
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
        // we don't set the pageSize from route
        this.sortColumn = this.columns.find(column => column.sortProperty === sortProperty);
        // Init the cursors array with the current page index
        this.apiCursor = new CursorArray(this.pageIndex, this.sortColumn?.sortDuplicates);
    }

    private setTableActions(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_STATIC_CONTENT_RULESET)) return;

        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PUT_STATIC_CONTENT_RULESET)) {
            this.actions = [AppConstants.TABLE_ACTION_TYPES.EDIT];
        }
        if (this.userIsAllowedToPipe.transform([AppConstants.PERMISSIONS.POST_STATIC_CONTENT_RULESET_JOBS,
            AppConstants.PERMISSIONS.COPY_STATIC_CONTENT_RULESET])) {
            this.actions = this.actions?.concat(AppConstants.TABLE_ACTION_TYPES.COPY) || [AppConstants.TABLE_ACTION_TYPES.COPY];
        }
        if (this.userIsAllowedToPipe.transform([AppConstants.PERMISSIONS.GET_STATIC_CONTENT_RULESET_USAGE,
            AppConstants.PERMISSIONS.DELETE_STATIC_CONTENT_RULESET])) {
            this.actions = this.actions?.concat(AppConstants.TABLE_ACTION_TYPES.DELETE) || [AppConstants.TABLE_ACTION_TYPES.DELETE];
        }
    }

    private resetPageIndex(): void {
        this.paginatorService.setPageIndex(this.tableId, 1);
    }

    private getFormatRulesets(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_STATIC_CONTENT_RULESETS)) return;
        if (this.subscription) this.subscription.unsubscribe();

        // If we don't have a cursor value (if we reload the page for example), fall back to offset
        const cursor = this.apiCursor.getCursor(this.pageIndex);

        this.subscription = this.indesignRulesetService.getStaticContentRulesets(null, this.pageSize, 0, cursor, this.sortColumn?.sortProperty,
            this.sortOrder, this.search)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                (result: ARPagedResponseDataModel<StaticContentRulesetModel>) => {
                    this.hasNext = result.hasNext;
                    this.items = result.items;

                    if (this.items.length > 0) {
                        const item = this.items[this.items.length - 1];
                        this.apiCursor.setCursor(this.pageIndex, this.sortColumn?.sortProperty, item);
                    }
                }, Toaster.handleApiError);
    }

    private createMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(null, null, this.sortProperty, this.sortOrder, this.search);
    }

    private updateUrl(): void {
        this.router.navigate([
        AppConstants.CONTEXT_URL.TEMPLATING,
        ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS,
        this.createMatrixUrl()]);
    }

    public onTableRowClicked(event: IItemClickEvent): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_STATIC_CONTENT_RULESET)) return;

        this.router.navigate([
            AppConstants.CONTEXT_URL.TEMPLATING,
            ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS,
            event.item._id,
            ApiConstants.API_METHOD_ITEMS
        ]);
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        if (event.column?.sortProperty) {
            this.sortColumn = event.column;
            this.sortOrder = event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortColumn = null;
            this.sortOrder = '';
        }

        this.resetPageIndex();
    }

    public onSearchBarValueUpdated(event: string): void {
        if (this.search !== event) {
            this.search = event;
            this.resetPageIndex();
        }
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const indesignRuleset = event.item as StaticContentRulesetModel;
        this.indesignRulesetService.getStaticContentRuleset(indesignRuleset._id)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                (ruleset: StaticContentRulesetModel) => {
                    switch (event.action) {
                        case AppConstants.TABLE_ACTION_TYPES.EDIT:
                            this.openForm(ruleset);
                            break;
                        case AppConstants.TABLE_ACTION_TYPES.COPY:
                            this.openCopyDialog(indesignRuleset);
                            break;
                        case AppConstants.TABLE_ACTION_TYPES.DELETE:
                            this.openDeleteConfirmationDialog(indesignRuleset);
                            break;
                        default:
                            Toaster.notYetImplementedError();
                            break;
                    }
                },
                Toaster.handleApiError);
    }

    public openForm(ruleSet?: StaticContentRulesetModel): void {
        const data = {ruleSet} as IRulesetFormComponentData;
        const config = new FullModalConfig(ruleSet ? 'Edit Format Ruleset' : 'Create Format Ruleset',
            'Setup your static content ruleset details', data);
        config.confirmClose = true;

        this.fullModalService.open(StaticContentRulesetFormComponent, config).afterClosed()
            .pipe(filter((result) => !!result))
            .subscribe(() => this.resetPageIndex());
    }

    private openCopyDialog(ruleSet: StaticContentRulesetModel): void {
        const copyDialogConfig = new NucDialogConfigModel('Duplicating static content ruleset',
            'Are you sure you want to duplicate the selected static content ruleset?');
        const copyDialog = this.dialogService.openDialog(copyDialogConfig);
        copyDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => copyDialog.close());
        copyDialogConfig.addAction('Proceed', BUTTON_TYPE.PRIMARY).subscribe(() => {
            copyDialog.close();
            this.copyFormatRuleset(ruleSet);
        });
    }

    private copyFormatRuleset(formatRuleset: StaticContentRulesetModel): void {
        const jobData = {formatRulesetId: formatRuleset._id} as IFormatRulesetCopyJobData;
        this.indesignRulesetService.postJob(EFormatRulesetJobs.FORMAT_RULESET_COPY_JOB, jobData)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (job: JobModel) => this.monitoredJobsService.getJobMonitor(job._id)
                    .pipe(takeUntil(this.onDestroySubject))
                    .subscribe((jobModel: JobModel) => {
                        if (jobModel.status === EJobStatus.DONE) {
                            this.getFormatRulesets();
                        }
                    }),
                error: Toaster.handleApiError
            });
    }

    private openDeleteConfirmationDialog(ruleSet: StaticContentRulesetModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete Format Ruleset',
            `Please confirm that you wish to delete the static content ruleset: ${ruleSet.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).pipe(
            switchMap(() => {
                deleteDialog.close();
                return this.indesignRulesetService.getStaticContentRulesetUsage(ruleSet._id);
            }),
            takeUntil(this.onDestroySubject)
        ).subscribe({
            next: (result: ItemUsedModel) => {
                result.usage ? this.openCannotDeleteDialog() : this.deleteRuleset(ruleSet._id);
            },
            error: Toaster.handleApiError
        });
    }

    private openCannotDeleteDialog(): void {
        const cannotDeleteDialogConfig = new NucDialogConfigModel('Cannot delete', 'The static content ruleset is in use.');
        const cannotDeleteDialog = this.dialogService.openDialog(cannotDeleteDialogConfig);
        cannotDeleteDialogConfig.addAction('Close', BUTTON_TYPE.SECONDARY).subscribe(() => cannotDeleteDialog.close());
    }

    private deleteRuleset(ruleSetId: string): void {
        this.indesignRulesetService.deleteStaticContentRuleset(ruleSetId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => {
                Toaster.success('Successfully deleted static content ruleset');
                this.resetPageIndex();
            }, Toaster.handleApiError);
    }
}
