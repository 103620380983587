import {Component, OnInit, ViewChild} from '@angular/core';
import {CampaignModel} from '../../../../models/api/campaign.model';
import {ActivatedRoute} from '@angular/router';
import {CampaignService, ECampaignJobTypes, ICampaignItemImportJobData} from '../../../../api/services/campaigns.service';
import {Toaster} from '../../../../classes/toaster.class';
import {ARApiError, ARLogger} from '@relayter/core';
import {CampaignItemsService} from '../../../../api/services/campaign-items.service';
import {CampaignItemBodyModel, CampaignItemModel} from '../../../../models/api/campaign-item.model';
import {AppConstants} from '../../../../app.constants';
import {ErrorConstants} from '../../../../api/error.constants';
import {
    BUTTON_TYPE,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    ITableAction,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {Subscription} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {
    CampaignItemModalFormComponent,
    ICampaignItemFormData
} from '../../../../forms/campaign-item-form/campaign-item-form/campaign-item-modal-form.component';
import {EDataFieldCollectionName} from '../../../../app.enums';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {unlockedBadgeAnimation} from '../../../../animations/unlocked-badge-animation';
import {MonitoredJobsService} from '../../../../api/services/monitored-jobs.service';
import {IImportDataFormComponentData, ImportDataFormComponent} from '../../../../forms/import-data-form/import-data-form.component';
import {BriefingTableComponent, IBriefingTableOptions} from '../../../../components/briefing-table/briefing-table.component';
import {RLBaseComponent} from '../../../../components/rl-base-component/rl-base.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
    IImportFromMasterBriefingComponentData,
    ImportFromMasterBriefingComponent
} from '../../../../components/import-from-master-briefing/import-from-master-briefing.component';
import {
    ExportDataFieldCollectionComponent,
    IExportDataFieldCollectionData
} from '../../../../components/export-data-field-collection/export-data-field-collection.component';

@Component({
    selector: 'campaigns-briefing-component',
    templateUrl: 'campaigns-briefing.component.html',
    styleUrls: ['campaigns-briefing.component.scss'],
    animations: [unlockedBadgeAnimation.unlockBadge]
})
export class CampaignsBriefingComponent extends RLBaseComponent implements OnInit {
    @ViewChild(BriefingTableComponent) private briefingTable: BriefingTableComponent;

    // Table setup
    public readonly tableId = 'briefing-table';

    public actionTypes: ITableAction[];
    public briefingTableOptions: IBriefingTableOptions = {inlineEditing: true};

    public apiRequestSubscription: Subscription;

    public campaign: CampaignModel;

    constructor(private route: ActivatedRoute,
                private campaignService: CampaignService,
                private campaignItemService: CampaignItemsService,
                private dialogService: NucDialogService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private fullModalService: FullModalService,
                private monitoredJobsService: MonitoredJobsService) {
        super();
    }

    public ngOnInit(): void {
        this.campaign = this.route.parent.snapshot.data.campaign;
        this.setTableActions();
    }

    private setTableActions(): void {
        this.actionTypes = [];
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PUT_CAMPAIGN_ITEM)) {
            this.actionTypes.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
        } else if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_CAMPAIGN_ITEM)) {
            this.actionTypes.push(AppConstants.TABLE_ACTION_TYPES.VIEW);
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.POST_CAMPAIGN_ITEM)) {
            this.actionTypes.push(AppConstants.TABLE_ACTION_TYPES.COPY);
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_CAMPAIGN_ITEM)) {
            this.actionTypes.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }

        if (this.actionTypes.length === 0) {
            this.actionTypes = null;
        }
    }

    private refreshData(): void {
        this.briefingTable.getCampaignItems();
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        switch (event.action) {
            case AppConstants.TABLE_ACTION_TYPES.EDIT:
            case AppConstants.TABLE_ACTION_TYPES.VIEW: {
                this.campaignItemService.getDetails(this.campaign._id, event.item._id)
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe({
                        next: (campaignItem: CampaignItemModel) => {
                            this.openBriefingForm(campaignItem);
                        },
                        error: Toaster.handleApiError
                    });
                break;
            }
            case AppConstants.TABLE_ACTION_TYPES.COPY: {
                this.openCopyConfirmDialog(event.item as CampaignItemModel);
                break;
            }
            case AppConstants.TABLE_ACTION_TYPES.DELETE: {
                this.openDeleteTableRowDialog(event.item as CampaignItemModel);
                break;
            }
        }
    }

    private openCopyConfirmDialog(campaignItem: CampaignItemModel): void {
        const confirmDialogConfig = new NucDialogConfigModel('Copy briefing item',
            `Please confirm that you wish to copy the briefing item with briefing item id '${campaignItem.briefingItemId}'.`);
        const confirmDialog = this.dialogService.openDialog(confirmDialogConfig);
        confirmDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => confirmDialog.close());
        confirmDialogConfig.addAction('Copy', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            confirmDialog.close();
            this.copyBriefingItem(campaignItem._id);
        });
    }

    private openDeleteTableRowDialog(tableRow: CampaignItemModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete briefing item',
            'Please confirm that you wish to delete this briefing item.');
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteTableRow(tableRow);
        });
    }

    public openBriefingForm(campaignItem?: CampaignItemModel): void {
        const modalData: ICampaignItemFormData = {
            campaignId: this.campaign._id
        };
        if (campaignItem) {
            modalData.campaignItem = campaignItem;
        }
        const config = new FullModalConfig(
            campaignItem ? 'Edit briefing item' : 'Add briefing item',
            ' ',
            modalData);
        config.confirmClose = true;
        config.hideHeaderDivider = true;

        this.fullModalService.open(CampaignItemModalFormComponent, config)
            .afterClosed().pipe(filter((result) => !!result))
            .subscribe(() => {
                this.refreshData();
            });
    }

    public openExportBriefingModal(): void {
        const sortOptions = this.briefingTable.tableSortOptions;

        const modalData: IExportDataFieldCollectionData = {
            contextId: this.campaign._id,
            sortProperty: sortOptions.sortProperties,
            sortOrder: sortOptions.sortOrder,
            cursorDuplicates: sortOptions.hasDuplicates,
            dataTypes: sortOptions.dataTypes,
            dataFieldCollection: EDataFieldCollectionName.CAMPAIGN_ITEM
        };

        const modalConfig = new FullModalConfig('Download campaign briefing', 'Select file type.', modalData);
        this.fullModalService.open(ExportDataFieldCollectionComponent, modalConfig);
    }

    public openImportBriefingFileModal(): void {
        const defaultFields = { // key is field name, value is the config for the field
            Products: {
                identifier: true,
                context: EDataFieldCollectionName.PRODUCT,
                options: [new DropdownItem<string>('ID', '_id')],
                placeholder: 'Product identifier'
            },
            Assets: {
                identifier: true,
                context: EDataFieldCollectionName.ASSET,
                options: [new DropdownItem<string>('Name', 'name'), new DropdownItem<string>('ID', '_id')],
                placeholder: 'Asset identifier'
            }
        };
        const modalData: IImportDataFormComponentData = {
            identifierContext: EDataFieldCollectionName.CAMPAIGN_ITEM,
            defaultFields, campaignId: this.campaign._id
        };
        const modalConfig = new FullModalConfig('Import briefing', 'Import a .csv/.xlf to fill your briefing data.', modalData);
        modalConfig.confirmClose = true;
        const modalRef = this.fullModalService.open(ImportDataFormComponent, modalConfig);
        modalRef.afterClosed().pipe(filter((data) => !!data)).subscribe((data: any) => this.scheduleImportJob(data));
    }

    public openImportFromMasterBriefingModal(): void {
        const modalData: IImportFromMasterBriefingComponentData = {
            campaignId: this.campaign._id
        };
        const modalConfig =
            new FullModalConfig(
                'Import from master briefing',
                'Select the items you want to import to the campaign briefing.', modalData);
        modalConfig.confirmClose = true;
        modalConfig.hideHeaderDivider = true;
        const modalRef = this.fullModalService.open(ImportFromMasterBriefingComponent, modalConfig);
        modalRef.afterClosed()
            .pipe(
                filter((data) => !!data),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((scheduleJob: JobModel) => {
                if (scheduleJob) {
                    ARLogger.debug('Job scheduled: ' + scheduleJob._id);
                    this.monitorJob(scheduleJob._id);
                }
            });
    }

    public scheduleImportJob(data: any): void {
        const jobData = {
            campaignName: this.campaign.name,
            campaignStartDate: this.campaign.startDate,
            campaignEndDate: this.campaign.endDate,
            linkAllProductAssets: true,
            campaignItemIdentifier: data.formValue.identifier.field.getValue(),
            s3Key: data.s3Key
        } as ICampaignItemImportJobData;

        if (data.formValue.variant) {
            jobData.variant = data.formValue.variant.name;
        }

        const productMapping = data.formValue.mappings.find((mapping) => mapping.field?.getValue() === 'Products');
        if (productMapping) jobData.productIdentifier = productMapping.identifier?.getValue();

        const assetMapping = data.formValue.mappings.find((mapping) => mapping.field?.getValue() === 'Assets');
        if (assetMapping) jobData.assetIdentifier = assetMapping.identifier?.getValue();

        this.campaignService.postJob(ECampaignJobTypes.CAMPAIGN_IMPORT_JOB, jobData)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (scheduleJob: JobModel) => {
                    ARLogger.debug('Job scheduled: ' + scheduleJob._id);
                    this.monitorJob(scheduleJob._id);
                },
                error: Toaster.handleApiError
            });
    }

    private copyBriefingItem(campaignItemId: string): void {
        this.apiRequestSubscription = this.campaignItemService.getDetails(this.campaign._id, campaignItemId)
            .pipe(
                switchMap((campaignItem: CampaignItemModel) => {
                    const products = campaignItem.products.map(product => product._id);
                    const assets = campaignItem.assets.map(product => product._id);
                    const copyCampaignItem = new CampaignItemBodyModel(products, assets, campaignItem.dataFields, campaignItem.tags);
                    return this.campaignItemService.postCampaignItem(this.campaign._id, copyCampaignItem);
                }),
                takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    this.refreshData();
                    Toaster.success('Briefing item copied successfully');
                },
                error: Toaster.handleApiError
            });
    }

    public deleteTableRow(item: CampaignItemModel): void {
        this.apiRequestSubscription = this.campaignItemService.deleteCampaignItem(this.campaign._id, item._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    this.refreshData();
                    Toaster.success('Briefing item removed successfully');
                },
                error: (err: ARApiError) => {
                    if (err.code === ErrorConstants.API_ERROR_CODES.FORBIDDEN_NOT_ALLOWED_TO_DELETE_CAMPAIGN_ITEM_IN_USE) {
                        Toaster.handleApiWarning(err);
                    } else {
                        Toaster.handleApiError(err);
                    }
                }
            });
    }

    private monitorJob(jobId: string): void {
        this.monitoredJobsService.getJobMonitor(jobId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((jobModel: JobModel) => {
                if (jobModel.status === EJobStatus.DONE) {
                    this.refreshData();
                }
            });
    }
}
