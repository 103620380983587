<div class="header-row">
    <h1>Design library overview</h1>


    <nuc-button-bar>
        <div class="popout-button">
            <nuc-button-primary text="Add"
                                icon="nucicon_dots_vertical"
                                iconPosition="end"
                                [matMenuTriggerFor]="menu"
                                *ngIf="(permissions.POST_DESIGN_LIBRARY_JOBS | userIsAllowedTo)">
            </nuc-button-primary>

            <mat-menu #menu="matMenu" class="menu-items-container">
                <button mat-menu-item
                        *ngIf="permissions.POST_DESIGN_LIBRARY_JOBS | userIsAllowedTo"
                        (click)="openIndesignLibraryModal()"
                        [disableRipple]="true">Add InDesign library</button>
                <button mat-menu-item
                        *ngIf="permissions.POST_DESIGN_LIBRARY_JOBS | userIsAllowedTo"
                        (click)="openSVGLibraryModal()"
                        [disableRipple]="true">Add SVG Library</button>
            </mat-menu>
        </div>
    </nuc-button-bar>
</div>

<ng-container *ngIf="(permissions.GET_DESIGN_LIBRARIES | userIsAllowedTo); else noPermissionState">
    <nuc-table [loading]="!indesignLibrarySubject?.closed"
               [columns]="columns"
               [items]="items"
               [actions]="actions"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               [selectionMode]="ESelectionMode.EMIT"
               (itemClicked)="onTableRowClicked($event)"
               (sortOptionChange)="onSortOptionChanged($event)"
               (actionClicked)="handleTableRowAction($event)">
        <paginator button-bar-right
                   [loading]="!indesignLibrarySubject?.closed"
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage">
        </paginator>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
