<div class="header">
    <h1>{{animatedContentRuleset?.name}}</h1>

    <div class="popout-button">
        @if (permissions.POST_ANIMATED_CONTENT_RULESET_RULE | userIsAllowedTo) {
            <nuc-button-primary text="Add"
                                icon="nucicon_dots_vertical"
                                iconPosition="end"
                                [matMenuTriggerFor]="menu">
            </nuc-button-primary>
        }

        <mat-menu #menu="matMenu" class="menu-items-container">
            @if (permissions.POST_ANIMATED_CONTENT_RULESET_RULE | userIsAllowedTo) {
                <button mat-menu-item
                        (click)="openRuleModal()"
                        [disableRipple]="true">Add rule</button>
            }
        </mat-menu>
    </div>
</div>

@if (permissions.GET_ANIMATED_CONTENT_RULESET_RULES) {
    <nuc-table [items]="items"
               [columns]="columns"
               [loading]="loading"
               [tableId]="tableId"
               [actions]="actions"
               [prefixStorageKey]="storageKey"
               (actionClicked)="handleTableRowAction($event)"
               (sortOptionChange)="onSortOptionChanged($event)">
        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="loading">
        </paginator>
    </nuc-table>
} @else {
    <no-permission-state-component></no-permission-state-component>
}
