import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {EPeriodGranularity, EUsageType, UsageModel} from '../../models/api/usage.model';
import {Observable} from 'rxjs';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {QueryParams} from '../../classes/query-params';
import {environment} from '../../../environments/environment';
import {BaseApiRequestService} from './base-api-request.service';

@Injectable({providedIn: 'root'})
export class UsagesService extends BaseApiRequestService {

    public getUsages(usageType: EUsageType, granularity?: EPeriodGranularity, fromDate?: Date): Observable<ARPagedResponseDataModel<UsageModel>> {
        const queryParams = new QueryParams()
            .addParam('usageType', usageType)
            .addParam('granularity', granularity)
            .addParam('fromDate', fromDate);

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_USAGES
        ], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleArrayResponse(options, obs, UsageModel);
        });
    }
}
