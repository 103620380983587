<div class="header-row">
    <h1>Fonts overview</h1>


    <nuc-button-bar>
        <div class="popout-button">
            <nuc-button-bar>
                @if (permissions.POST_FONT_JOB | userIsAllowedTo) {
                    <nuc-button-primary text="Add font"
                                        (click)="openFontModel()"/>
                }
            </nuc-button-bar>
        </div>
    </nuc-button-bar>
</div>
@if (permissions.GET_FONTS | userIsAllowedTo) {
    <nuc-table [loading]="loading"
               [columns]="columns"
               [items]="items"
               [actions]="actions"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               [selectionMode]="ESelectionMode.EMIT"
               (itemClicked)="onTableRowClicked($event)"
               (sortOptionChange)="onSortOptionChanged($event)"
               (actionClicked)="handleTableRowAction($event)">
        <paginator button-bar-right
                   [loading]="loading"
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage">
        </paginator>
    </nuc-table>
} @else {
    <no-permission-state-component />
}

