<form [formGroup]="formGroup" *ngIf="formGroup">
    <nuc-label *ngIf="selectedVariant" icon="nucicon_variant" iconColorClass="variant-highlight">{{selectedVariant.getTitle()}}</nuc-label>
    <nuc-form-field label="Master page" *ngIf="engineType === EEngineType.INDESIGN">
        <nuc-dropdown
            [searchable]="true"
            placeholder="Select a master page"
            formControlName="masterPage"
            (requestData)="getMasterPages($event)"
            [items]="masterPages"
            [total]="totalMasterPages">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field
        *ngIf="formGroup.get('showMasterPage') && formGroup.value.masterPage && engineType === EEngineType.INDESIGN">
        <nuc-checkbox formControlName="showMasterPage" text="Show Master page on background"></nuc-checkbox>
    </nuc-form-field>

    <nuc-form-field label="Design library">
        <nuc-dropdown
            [searchable]="true"
            placeholder="Choose a design library"
            formControlName="designLibrary"
            (requestData)="getDesignLibraries($event)"
            [items]="libraries"
            [total]="totalLibraries">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="Static content ruleset">
        <nuc-dropdown placeholder="Choose a ruleset"
                      [searchable]="true"
                      formControlName="ruleset"
                      [items]="rulesets"
                      (requestData)="getRulesets($event)"
                      [total]="totalFormatRulesets">
        </nuc-dropdown>
    </nuc-form-field>
</form>
