<ng-container *ngIf="permissions.GET_WORKFLOW_CONFIGURATION | userIsAllowedTo; else noPermissionEmptyState">
    <ng-container *ngIf="workflowConfiguration; else loadingIndicator">
        <div class="header">
            <h1>{{workflowConfiguration.name}}</h1>

            <nuc-button-bar>
                <div class="popout-button"
                     *ngIf="menuItems && (permissions.PATCH_WORKFLOW_CONFIGURATION | userIsAllowedTo)">
                    <nuc-button-secondary text="Add item"
                                        icon="nucicon_dots_vertical"
                                        iconPosition="end"
                                        [matMenuTriggerFor]="menu">
                    </nuc-button-secondary>

                    <mat-menu #menu="matMenu" class="menu-items-container">
                        <button *ngFor="let menuItem of menuItems"
                                mat-menu-item
                                (click)="onMenuItemClicked(menuItem.property)"
                                [disableRipple]="true">
                            Add {{menuItem.title}}
                        </button>
                    </mat-menu>
                </div>
            </nuc-button-bar>
        </div>

        <div class="indicator" (click)="setActiveStep(null)">
            <div class="workflow-components-container">
                <nuc-button-secondary *ngIf="stickyLogComponent"
                                      text="Note log"
                                      [operating]="stickyLogComponent._id === activeComponent?._id"
                                      (click)="navigateToComponent($event, stickyLogComponent)">
                </nuc-button-secondary>
                <nuc-button-secondary *ngIf="overviewComponent"
                                      icon="nucicon_grid"
                                      [operating]="overviewComponent._id === activeComponent?._id"
                                      (click)="navigateToComponent($event, overviewComponent)">
                </nuc-button-secondary>
            </div>

            <rl-workflow-indicator *ngIf="workflowConfiguration?.steps?.length; else noSteps"
                                   [workflowConfiguration]="workflowConfiguration"
                                   (activeStepChange)="setActiveStep($event)"
                                   [activeStep]="activeStep"
                                   [counts]="counts">
            </rl-workflow-indicator>
        </div>

        <router-outlet></router-outlet>
    </ng-container>
</ng-container>

<ng-template #noSteps>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/empty-states-workflow.svg"
                               [small]="true"
                               title="No workflow configuration steps. Add your first step to show the workflow indicator">
    </nuc-empty-state-component>
</ng-template>

<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>

<ng-template #noPermissionEmptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="No permission"
                               subtitle="You do not have the permission to view this page.">
    </nuc-empty-state-component>
</ng-template>
