@if ((permissions.GET_AFTER_EFFECTS_PROJECT_FILE | userIsAllowedTo)) {
    <nuc-table [items]="layers"
               [columns]="columns"
               [loading]="!layersDataSubscription?.closed"
               [tableId]="tableId"
               [actions]="actions"
               [prefixStorageKey]="storageKey"
               (sortOptionChange)="onSortOptionChanged($event)">

        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="!layersDataSubscription?.closed">
        </paginator>
    </nuc-table>
} @else {
    <no-permission-state-component/>
}
