@if (formGroup) {
    <form [formGroup]="formGroup">
        <nuc-form-field label="Name">
            <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Composition">
            <nuc-dropdown
                formControlName="composition"
                placeholder="Select a composition"
                [nullOption]="false"
                [items]="compositions"
                [required]="true">
            </nuc-dropdown>
        </nuc-form-field>
    </form>

    <div class="container">
        <h2 class="header">Conditions</h2>
        <p>Define the conditions on which the selected composition will be generated</p>
        <hr>

        <condition-group-form
            [conditionGroups]="conditionGroups"
            [ruleProperties]="ruleProperties">
        </condition-group-form>

        <div class="actions">
            <nuc-button-secondary (click)="addNewConditionGroup()" text="Add condition"></nuc-button-secondary>
        </div>

        <h2 class="header">Values</h2>
        <p>Setup which text layers need to have dynamic content</p>
        <hr>

        @for (valueControl of valueGroups.controls; track valueControl) {
            <values-form
                class="form"
                [formGroup]="valueControl"
                [valueIndex]="$index"
                [tags]="tags"
                [ruleProperties]="ruleProperties"
                (deleteClicked)="deleteValueClicked($index)"
                tagLabel="Text layer"
                tagPlaceholder="Select a text layer">
            </values-form>
        }

        <div class="actions">
            <nuc-button-secondary
                (click)="addValueGroup()"
                text="Add value">
            </nuc-button-secondary>
        </div>

        <h2 class="header">Assets</h2>
        <p>Add images, sounds and or videos to the composition</p>
        <hr>

        @for (assetControl of assetGroups.controls; track assetControl) {
            <values-form
                class="form"
                [formGroup]="assetControl"
                [valueIndex]="$index"
                [tags]="assetTags"
                [ruleProperties]="assetRuleProperties"
                (deleteClicked)="deleteAssetClicked($index)"
                [formatEnabled]="false"
                tagLabel="Asset layer"
                tagPlaceholder="Select an asset layer">
            </values-form>
        }

        <div class="actions">
            <nuc-button-secondary (click)="addAssetGroup()" text="Add asset"></nuc-button-secondary>
        </div>
    </div>
}
