import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GroupService} from '../../../../api/services/group.service';
import {GroupModel} from '../../../../models/api/group.model';
import {UserModel} from '../../../../models/api/user.model';
import {Subscription} from 'rxjs';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {AppConstants} from '../../../../app.constants';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {EColumnSize, ITableColumn} from '@relayter/rubber-duck';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {RoleModel} from '../../../../models/api/role.model';
import {UserDataProvider} from '../../../../api/data-providers/user.data-provider';

@Component({
    selector: 'rl-group-detail-component',
    templateUrl: 'group-detail.component.html',
    styleUrls: ['group-detail.component.scss'],
    providers: [PaginatorService]
})

export class GroupDetailComponent extends RLTableComponent implements OnInit {
    public tableId = 'content-group-detail';
    private groupId: string;

    public group: GroupModel;
    public groupSubscription: Subscription;

    public users: UserModel[];

    public columns: ITableColumn[] = [
        {
            title: 'Full name',
            key: 'fullName',
            size: EColumnSize.LARGE,
            sortProperty: 'fullName'
        },
        {
            title: 'Email',
            key: 'email',
            size: EColumnSize.BASE,
            sortProperty: 'email'
        },
        {
            title: 'Roles',
            key: 'roles',
            size: EColumnSize.BASE,
            format: (roles: RoleModel[]) => roles?.map(role => role.name).join(', ')
        }
    ];

    public usersDataProvider = new UserDataProvider(this.tableSortOptions);

    constructor(private route: ActivatedRoute,
                private router: Router,
                private groupService: GroupService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.groupId = this.route.snapshot.params.groupId;
        this.usersDataProvider.groupId = this.groupId;

        this.getGroupDetails();
        this.subscribeToRoute();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((pagination) => {
                this.usersDataProvider.pageIndex = pagination.pageIndex;
                this.usersDataProvider.pageSize = pagination.pageSize;

                this.updateUrl();
                this.getData();
            });
    }

    private subscribeToRoute(): void {
        this.route.params.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
            this.usersDataProvider.pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : AppConstants.PAGE_INDEX_DEFAULT;
            this.tableSortOptions.fromRoute(params, this.columns);
            this.usersDataProvider.searchValue = params.search;

            this.updateUrl();
            this.setPageIndex(this.usersDataProvider.pageIndex);
        });
    }

    private updateUrl(): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.SETTINGS,
            AppConstants.CONTEXT_URL.CONTENT_GROUPS,
            this.groupId, this.getMatrixUrl()
        ], {replaceUrl: true});
    }

    private getMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.usersDataProvider.pageIndex, null, this.tableSortOptions.sortPropertiesAsString,
            this.tableSortOptions.sortOrder, this.usersDataProvider.searchValue);
    }

    /**
     * Set page index, default the first page
     *
     * @param {number} [pageIndex]
     */
    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex); // reset pageIndex
    }

    public getGroupDetails(): void {
        this.groupSubscription = this.groupService.getGroup(this.groupId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => this.group = result);
    }

    public getData(): void {
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_USERS)) return;

        this.usersDataProvider.retrieveData();
    }
}
