<div class="header-row">
    <h1>Variants</h1>
    <nuc-button-primary *ngIf="permissions.POST_VARIANT | userIsAllowedTo" (click)="openVariantForm()" text="Add variant"></nuc-button-primary>
</div>

<ng-container *ngIf="permissions.GET_VARIANTS | userIsAllowedTo else noPermissionState">
    <nuc-table [items]="variants"
               [columns]="columns"
               [loading]="!subscription?.closed"
               (sortOptionChange)="onSortOptionChanged($event)"
               [actions]="allowedActions"
               (actionClicked)="handleAction($event)"
               emptyStateTitle="This section is empty. Add the first item."
               [selectionMode]="selectionMode"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey">
        <ng-container button-bar-right>
            <paginator [viewId]="tableId"
                       [disableNextPage]="disableNextPage"
                       [loading]="!subscription?.closed">
            </paginator>
        </ng-container>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission_small.svg"
                               small="true"
                               title="You or your team does not have permissions to view this section. Contact the team owner to enable this section.">
    </nuc-empty-state-component>
</ng-template>
