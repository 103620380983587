import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UpdateUserModel, UserDetailModel} from '../../../../../models/api/user.model';
import {UserService} from '../../../../../api/services/users.service';
import {RolesService} from '../../../../../api/services/roles.service';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../../../classes/toaster.class';
import {RoleModel} from '../../../../../models/api/role.model';
import {AppConstants} from '../../../../../app.constants';
import {
    BUTTON_TYPE,
    EColumnType,
    ESelectionMode,
    ESortOrder,
    ISortOptionEvent,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {MatrixUrlParams} from '../../../../../models/ui/matrix-url-params.model';
import {RLTableComponent} from '../../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../../api/services/user-settings-storage.service';
import {PaginatorService} from '../../../../../components/paginator/paginator.service';
import {SelectionModel} from '@angular/cdk/collections';
import {IDeactivateComponent} from '../../../routes/guards/base/deactivate-component.interface';
import {Subscription} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'rl-user-detail.component',
    templateUrl: 'user-detail.component.html',
    styleUrls: ['user-detail.component.scss'],
    providers: [PaginatorService]
})

export class UserDetailComponent extends RLTableComponent implements OnInit, IDeactivateComponent {
    public tableId = 'user-detail';
    public items: RoleModel[] = [];
    private userId: string;
    public user: UserDetailModel;
    public pageIndex: number;
    public pageSize: number;
    public total: number;
    public columns: ITableColumn[] = [{
        title: 'Role',
        key: 'name',
        type: EColumnType.DEFAULT,
        sortProperty: 'name'
    }];
    public ESelectionMode = ESelectionMode;
    public selection = new SelectionModel<RoleModel>(true, [], false);
    public subscription: Subscription;

    constructor(userSettingsStorageService: UserSettingsStorageService,
                private router: Router,
                private route: ActivatedRoute,
                private userService: UserService,
                private roleService: RolesService,
                private dialogService: NucDialogService,
                private paginatorService: PaginatorService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.getUserDetail(this.userId);
        this.listenToPagination();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;

        this.userId = params['id'];
        this.pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;
        this.sortProperty = params['sortProperty'] ? params['sortProperty'] : null;
        this.sortOrder = params['sortOrder'] ? params['sortOrder'] : null;
        this.searchValue = params['search'] ? params['search'] : null;

        this.paginatorService.setPageIndex(this.tableId, this.pageIndex);
    }

    private listenToPagination(): void {
        this.paginatorService.getPagination(this.tableId).subscribe((result: {
            pageIndex: number;
            pageSize: number
        }) => {
            this.pageIndex = result.pageIndex;
            this.pageSize = result.pageSize;

            const matrixUrl = new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder, this.searchValue);
            this.router.navigate([matrixUrl], {relativeTo: this.route});

            this.getRoles();
        });
    }

    /**
     * Check if the confirm dialog needs to be shown on leaving the page
     * @return {boolean}
     */
    public canDeactivate(): boolean {
        if (!this.user) return true;

        const selectedValues = this.selection.selected;

        return selectedValues.every(selectedRole => this.user.roles.find(role => role._id === selectedRole._id)) &&
            this.user.roles.every(role => selectedValues.find(value => value._id === role._id));
    }

    public resetPageIndex(): void {
        this.paginatorService.setPageIndex(this.tableId, 1); // reset pageIndex
    }

    /**
     * Get the details of a user from the API
     * @param {string} id
     */
    private getUserDetail(id: string): void {
        this.userService.getUserDetails(id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (user: UserDetailModel) => {
                    this.setUserDetails(user);
                },
                error: (err: ARApiError) => {
                    Toaster.handleApiError(err);
                    this.router.navigateByUrl(AppConstants.USERS_PATH);
                }
            });
    }

    /**
     * get roles
     */
    private getRoles(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.subscription = this.roleService.getRoles(
            this.pageSize,
            (this.pageIndex - 1) * this.pageSize,
            this.sortProperty,
            this.sortOrder,
            this.searchValue)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (roles: ARPagedResponseDataModel<RoleModel>) => {
                    this.total = roles.total;
                    this.items = roles.items;
                },
                error: Toaster.handleApiError
            });
    }

    public openUpdateRolesDialog(): void {
        const confirmRolesDialogConfig = new NucDialogConfigModel('Are you sure?', 'You are about to change roles for a user, are you sure?');
        const confirmRolesDialog = this.dialogService.openDialog(confirmRolesDialogConfig);
        confirmRolesDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => confirmRolesDialog.close());
        confirmRolesDialogConfig.addAction('Confirm', BUTTON_TYPE.PRIMARY).subscribe(() => {
            confirmRolesDialog.close();
            this.updateUserRoles();
        });
    }

    /**
     * update the user api call and reset the updated items
     */
    private updateUserRoles(): void {
        const updatedUser = new UpdateUserModel();
        updatedUser.roles = this.selection.selected.map(item => item._id);

        this.userService.patchUserDetails(this.userId, updatedUser)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (user: UserDetailModel) => {
                    this.setUserDetails(user);
                    Toaster.success('Updated user roles successfully');
                },
                error: (err: ARApiError) => {
                    Toaster.handleApiError(err);
                }
            });
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.sortProperty = event.column?.sortProperty || '';
        this.sortOrder = (event.column?.sortProperty && event.sortOrder === ESortOrder.ASC) ? 'asc' : 'desc';

        this.resetPageIndex();
    }

    private setUserDetails(user: UserDetailModel): void {
        this.user = user;
        this.selection.setSelection(...user.roles);
    }
}
