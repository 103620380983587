import {ApiConstants} from '../api.constant';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {AuditTrailModel} from '../../models/api/audit-trail.model';

@Injectable({providedIn: 'root'})
export class AuditTrailService extends ApiService<AuditTrailModel> {

    constructor() {
        super([ApiConstants.API_GROUP_AUDIT_TRAIL], AuditTrailModel);
    }
}
