<ng-container *ngIf="layerOptions; else loadingIndicator">
    <form class="mainForm" [formGroup]="formGroup">
        <nuc-form-field label="Rule name">
            <nuc-input formControlName="name" placeholder="Rule name"></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Library item">
            <nuc-dropdown [items]="libraryItems"
                          [nullOption]="false"
                          [searchable]="true"
                          (requestData)="getLibraryItems($event)"
                          [total]="libraryItems.length"
                          placeholder="Select a library item"
                          formControlName="libraryItem"></nuc-dropdown>
        </nuc-form-field>

        <nuc-form-field label="Layer">
            <nuc-suggestive-input [options]="layerOptions"
                                  [enableNewValue]="true"
                                  prefix="nucicon_search"
                                  (searchTermChange)="searchLayers($event)"
                                  maxValues="1"
                                  [total]="totalLayers"
                                  (requestData)="getLayers($event.offset)"
                                  formControlName="layer"
                                  placeholder="Choose a layer"></nuc-suggestive-input>
        </nuc-form-field>

        <nuc-form-field label="Item group">
            <nuc-suggestive-input [options]="itemGroups"
                                  [enableNewValue]="false"
                                  prefix="nucicon_search"
                                  (searchTermChange)="searchItemGroups($event)"
                                  maxValues="1"
                                  [total]="itemGroups.length"
                                  (requestData)="getItemGroups($event.offset)"
                                  formControlName="itemGroup"
                                  placeholder="Choose an item group"></nuc-suggestive-input>
        </nuc-form-field>

        <div class="position">
            <nuc-form-field label="Anchor">
                <nuc-dropdown [items]="anchorOptions" [total]="anchorOptions.length" placeholder="Anchor"
                              formControlName="anchor"></nuc-dropdown>
            </nuc-form-field>

            <nuc-form-field label="X">
                <nuc-input formControlName="x" placeholder="x" type="number"></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Y">
                <nuc-input formControlName="y" placeholder="y" type="number"></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Scale">
                <nuc-dropdown [items]="scaleOptions" [total]="scaleOptions.length" placeholder="Scale"
                              formControlName="scale"></nuc-dropdown>
            </nuc-form-field>

            <nuc-form-field *ngIf="formGroup?.value.scale" label="W">
                <nuc-input formControlName="w" placeholder="w" type="number"></nuc-input>
            </nuc-form-field>

            <nuc-form-field *ngIf="formGroup?.value.scale" label="H">
                <nuc-input formControlName="h" placeholder="h" type="number"></nuc-input>
            </nuc-form-field>
        </div>

    </form>

    <h2 class="header">Conditions</h2>
    <p>Define the conditions on which this rule is triggered. The library item will be generated when all rule conditions are met</p>
    <hr>

    <condition-group-form [conditionGroups]="conditionGroups" [ruleProperties]="ruleProperties"></condition-group-form>

    <div class="actions">
        <nuc-button-secondary (click)="addNewConditionGroup()" text="Add condition"></nuc-button-secondary>
    </div>

    <h2 class="header">Values</h2>
    <hr>

    <values-form *ngFor="let valueControl of valueGroups.controls; let index = index;"
                 class="form"
                 [formGroup]="valueControl"
                 [valueIndex]="index"
                 [tags]="tags"
                 [ruleProperties]="ruleProperties"
                 (deleteClicked)="deleteValueClicked(index)"></values-form>

    <div class="actions">
        <nuc-button-secondary (click)="addValueGroup()" text="Add value"></nuc-button-secondary>
    </div>
</ng-container>
<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>
