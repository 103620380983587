// Modules
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RDModule} from '@relayter/rubber-duck';
import {CommonModule} from '@angular/common';
import {TemplateDetailComponent} from './template-detail.component';
import {TemplatePresetComponent} from './template-preset/template-preset.component';
import {TemplateSizeComponent} from './template-size/template-size.component';
import {TemplateContentComponent} from './template-content/template-content.component';
import {ComponentsModule} from '../../../../components/components.module';
import {TemplateVariantsComponent} from './template-variants/template-variants.component';
import {TemplateVariantPresetComponent} from './template-variants/preset/template-variant-preset.component';

// Pages

const components = [
    TemplateDetailComponent,
    TemplatePresetComponent,
    TemplateSizeComponent,
    TemplateContentComponent,
    TemplateVariantsComponent,
    TemplateVariantPresetComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RDModule,
        ComponentsModule,
    ],
    providers: [],
    declarations: components,
    exports: components
})

export class TemplateDetailsModule {
}
