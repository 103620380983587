import {Component, DestroyRef, Inject, OnInit} from '@angular/core';
import {ContentRuleModel, ContentRulePatchModel, ContentRulePostModel} from '../../../../models/api/content-rule.model';
import {FormControl, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {GroupModel} from '../../../../models/api/group.model';
import {ContentRuleService} from '../../../../api/services/content-rule.service';
import {GroupService} from '../../../../api/services/group.service';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {Toaster} from '../../../../classes/toaster.class';
import {distinctUntilChanged, finalize, map} from 'rxjs/operators';
import {ContentRuleCollection} from '../content-rule.constants';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {EFormStatus} from '../../../../app.enums';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export interface IContentRuleFormData {
    contentRule?: ContentRuleModel;
}

interface IContentRuleForm {
    name: FormControl<string>,
    collection: FormControl<ContentRuleCollection>,
    values: FormControl<DropdownItem<string>[]>,
    groups: FormControl<GroupModel[]>,
    writeAccess: FormControl<boolean>
}

@Component({
    selector: 'rl-content-rule-form.component',
    templateUrl: 'content-rule-form.component.html',
    styleUrls: ['content-rule-form.component.scss']
})

export class ContentRuleFormComponent implements OnInit {

    public collections = ContentRuleCollection.COLLECTIONS;

    public formGroup: FormGroup<IContentRuleForm> = new FormGroup({
        name: new UntypedFormControl('', Validators.required),
        collection: new UntypedFormControl(null, Validators.required),
        values: new UntypedFormControl(''),
        groups: new UntypedFormControl('', Validators.required),
        writeAccess: new UntypedFormControl(false, Validators.required)
    });

    public groups: GroupModel[] = [];

    private saveConfig: ButtonConfig;
    public tagOptions: DropdownItem<string>[];
    public contentRule: ContentRuleModel;

    constructor(private contentRuleService: ContentRuleService,
                private groupService: GroupService,
                private fullModalService: FullModalService,
                private destroyRef: DestroyRef,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IContentRuleFormData) {
        this.contentRule = modalData.contentRule;
    }

    public ngOnInit(): void {
        this.getGroups();
        this.initButtons();
    }

    private initButtons(): void {
        this.saveConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', false, false, true);
        const cancelConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancelAction = new FullModalActionModel(cancelConfig);
        const saveAction = new FullModalActionModel(this.saveConfig);

        cancelAction.observable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.saveContentRule());

        const actions = [cancelAction, saveAction];
        this.fullModalService.setModalActions(actions);
    }

    private initForm(): void {
        this.formGroup.statusChanges.pipe(
            map((status) => status === EFormStatus.VALID),
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((valid) => this.saveConfig.disabled = !valid);

        if (this.contentRule) {
            this.formGroup.controls.writeAccess.disable();
            this.formGroup.patchValue({
                ...this.contentRule,
                groups: this.contentRule.groups ? this.contentRule.groups.map((contentGroup) =>
                    this.groups.find((group) => group._id === contentGroup._id)) : [],
                collection: ContentRuleCollection.getCollection(this.contentRule.collectionName),
                values: this.contentRule.values ? this.contentRule.values.map((value) => new DropdownItem(value, value)) : []
            });
        }
    }

    public getGroups(): void {
        this.groupService.getAllGroups()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
                (results) => {
                    this.groups = results;
                    this.initForm();
                });
    }

    public saveContentRule(): void {
        const name = this.formGroup.value.name.trim();
        const groupIds = this.formGroup.value.groups.map((group: GroupModel) => group._id);
        const collection = this.formGroup.value.collection.getValue();
        const values = this.formGroup.value.values ? this.formGroup.value.values.map((value: DropdownItem<string>) => value.getValue()) : [];

        this.saveConfig.loading = true;
        if (this.contentRule) {
            const contentRule = new ContentRulePatchModel(name, collection, values, groupIds);
            this.contentRuleService.patchContentRule(this.contentRule._id, contentRule)
                .pipe(finalize(() => this.saveConfig.loading = false), takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        Toaster.success('Content rule updated successfully');
                        this.fullModalService.close(result, false);
                    },
                    error: Toaster.handleApiError
                });
        } else {
            const contentRule = new ContentRulePostModel(name, collection, values, groupIds, !!this.formGroup.value.writeAccess);
            this.contentRuleService.postContentRule(contentRule)
                .pipe(finalize(() => this.saveConfig.loading = false), takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        Toaster.success('Content rule created successfully');
                        this.fullModalService.close(result, false);
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    public onTagChanged(event: string): void {
        this.tagOptions = event && event.length && event.trim().length ? [new DropdownItem<string>(event.trim(), event.trim())] : [];
    }

}
