import {Component, computed, effect, Input, Signal} from '@angular/core';
import {
    BUTTON_TYPE,
    DialogCustomContentConfig,
    ESelectionMode,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    ITableItem,
    NucDialogConfigModel,
    NucDialogCustomContentService,
    NucDialogService
} from '@relayter/rubber-duck';
import {RLTableComponent} from '../../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../../api/services/user-settings-storage.service';
import {TransitionItemsService} from '../../../../../api/services/transition-items.service';
import {WorkflowConfigurationsService} from '../../../../../api/services/workflow-configurations.service';
import {UserIsAllowedToPipe} from '../../../../../pipes/user-is-allowed-to.pipe';
import {MonitoredJobsService} from '../../../../../api/services/monitored-jobs.service';
import {WorkflowConfigurationModel} from '../../../../../models/api/workflow-configuration.model';
import {EWorkflowConfigurationProperty, WorkflowConfigurationPropertyConfigs} from '../workflow-configuration-property.config';
import {Toaster} from '../../../../../classes/toaster.class';
import {CustomWorkflowComponentModel} from '../../../../../models/api/custom-workflow-component.model';
import {CustomWorkflowTransitionModel} from '../../../../../models/api/custom-workflow-transition.model';
import {CustomWorkflowActionModel} from '../../../../../models/api/custom-workflow-action.model';
import {IListDialogData, ListDialogComponent} from '../../../../../components/dialog/list/list-dialog.component';
import {CustomWorkflowStepModel} from '../../../../../models/api/custom-workflow-step.model';
import {
    IWorkflowConfigurationStepDeletionDialogData,
    WorkflowConfigurationStepDeletionDialogComponent
} from '../workflow-configuration-properties/workflow-configuration-step-deletion-dialog/workflow-configuration-step-deletion-dialog.component';
import {filter, switchMap} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {EJobStatus} from '../../../../../models/api/job.model';
import {
    IWorkflowConfigurationStepFormData,
    WorkflowConfigurationStepFormComponent
} from '../../../../../forms/workflow-configuration-step-form/workflow-configuration-step-form.component';
import {
    IWorkflowConfigurationTransitionFormData,
    WorkflowConfigurationTransitionFormComponent
} from '../../../../../forms/workflow-configuration-transition-form/workflow-configuration-transition-form.component';
import {
    IWorkflowConfigurationActionFormData,
    WorkflowConfigurationActionFormComponent
} from '../../../../../forms/workflow-configuration-action-form/workflow-configuration-action-form.component';
import {
    IWorkflowConfigurationComponentFormData,
    WorkflowConfigurationComponentFormComponent
} from '../../../../../forms/workflow-configuration-component-form/workflow-configuration-component-form.component';
import {WorkflowConfigurationsDetailsService} from '../workflow-configurations-details.service';
import {AppConstants} from '../../../../../app.constants';
import {CustomWorkflowFilterModel} from '../../../../../models/api/custom-workflow-filter.model';
import {
    IWorkflowConfigurationFilterFormData,
    WorkflowConfigurationFilterFormComponent
} from '../../../../../forms/workflow-configuration-filter-form/workflow-configuration-filter-form.component';

export interface IWorkflowDetailPropertyMetadata {
    property: string;
    stepId?: string;
    componentId?: string;
    filterId?: string;
    itemId?: string;
}

@Component({
    selector: 'workflow-configuration-detail-property-component',
    templateUrl: 'workflow-configuration-detail-property.component.html',
    styleUrls: ['workflow-configuration-detail-property.component.scss']
})
export class WorkflowConfigurationDetailPropertyComponent extends RLTableComponent {
    protected readonly ESelectionMode = ESelectionMode;
    public tableId: string; // we should set table id

    @Input({required: true}) public propertyMetadata: Signal<IWorkflowDetailPropertyMetadata>;
    public workflowConfiguration = this.workflowConfigurationsDetailsService.workflow;

    public selectedPropertyConfig = computed(() => {
        const config = new WorkflowConfigurationPropertyConfigs(this.workflowConfiguration())
            .getConfig(this.propertyMetadata().property);

        if (config) {
            config.tableView.actions =
                config.tableView.actionsConfig?.filter((actionConfig) => {
                    return actionConfig.permission ? this.userIsAllowedToPipe.transform(actionConfig.permission) : true;
                }).map((actionConfig) => actionConfig.type);
        }

        return config;
    });

    public items = computed(() => {
        return this.findDataByPropertyMetadata(this.workflowConfiguration(), this.propertyMetadata());
    });

    constructor(userSettingsStorageService: UserSettingsStorageService,
                private transitionItemsService: TransitionItemsService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private workflowConfigurationsDetailsService: WorkflowConfigurationsDetailsService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private fullModalService: FullModalService,
                private dialogCustomContentService: NucDialogCustomContentService,
                private dialogService: NucDialogService,
                private monitoredJobsService: MonitoredJobsService) {
        super(userSettingsStorageService);

        effect(() => this.tableId = `workflow-configurations-${this.propertyMetadata().property}`);
    }

    public handleTableRowAction(actionEvent: IActionClickEvent): void {
        const property = this.propertyMetadata().property;

        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((workflow) => {
                const item = this.findDataByPropertyMetadata(workflow, {
                    ...this.propertyMetadata(),
                    itemId: actionEvent.item._id
                });
                if (actionEvent.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
                    switch (property) {
                        case EWorkflowConfigurationProperty.STEPS:
                            this.openStepModal(item as CustomWorkflowStepModel);
                            break;
                        case EWorkflowConfigurationProperty.COMPONENTS:
                            this.openComponentModal(workflow, item as CustomWorkflowComponentModel);
                            break;
                        case EWorkflowConfigurationProperty.TRANSITIONS:
                            this.openTransitionModal(item as CustomWorkflowTransitionModel);
                            break;
                        case EWorkflowConfigurationProperty.ACTIONS:
                            this.openActionModal(workflow, item as CustomWorkflowActionModel);
                            break;
                        case EWorkflowConfigurationProperty.FILTERS:
                            this.openFilterModal(item as CustomWorkflowFilterModel);
                            break;
                        default:
                            Toaster.notYetImplementedError();
                            break;
                    }
                } else if (actionEvent.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
                    switch (property) {
                        case EWorkflowConfigurationProperty.STEPS:
                            this.deleteStep(item as CustomWorkflowStepModel);
                            break;
                        case EWorkflowConfigurationProperty.COMPONENTS:
                            this.preDeleteComponent(item as CustomWorkflowComponentModel);
                            break;
                        case EWorkflowConfigurationProperty.TRANSITIONS:
                            const usedActions = this.getTransitionActions(item, workflow);
                            this.preDeleteTransition(item as CustomWorkflowTransitionModel, usedActions);
                            break;
                        case EWorkflowConfigurationProperty.ACTIONS:
                            this.preDeleteAction(item as CustomWorkflowActionModel, workflow);
                            break;
                        case EWorkflowConfigurationProperty.FILTERS:
                            this.preDeleteFilter(item as CustomWorkflowFilterModel);
                            break;
                        default:
                            Toaster.notYetImplementedError();
                            break;
                    }
                } else {
                    Toaster.notYetImplementedError();
                }
            });
    }

    private deleteStep(step: ITableItem) {
        const transitionsByStep =
            this.workflowConfiguration().transitions.filter(
                (transition) => transition.from === step._id || transition.to === step._id);

        transitionsByStep.length > 0
            ? this.openStepInUseDialog(transitionsByStep)
            : this.openDeleteStepDialog(step as CustomWorkflowStepModel);
    }

    private openStepInUseDialog(transitions: CustomWorkflowTransitionModel[]): void {
        const dialogData: IListDialogData = {
            listGroups: [{
                list: transitions.map((transition) => transition.name)
            }]
        };
        const stepInUseConfig = new DialogCustomContentConfig('Cannot delete step',
            'The step is currently used in transitions.', dialogData);
        this.dialogCustomContentService.open(ListDialogComponent, stepInUseConfig);
    }

    private openDeleteStepDialog(step: CustomWorkflowStepModel): void {
        const dialogConfig = new DialogCustomContentConfig(
            'Delete step',
            'To delete this step, move the existing publication items to another step.',
            {
                workflowConfiguration: this.workflowConfiguration(),
                step
            } as IWorkflowConfigurationStepDeletionDialogData
        );
        this.dialogCustomContentService
            .open(WorkflowConfigurationStepDeletionDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                filter((job) => !!job),
                switchMap((job) => this.monitoredJobsService.getJobMonitor(job._id)),
                filter((job) => job.status === EJobStatus.DONE),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: () => this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration()
            });
    }

    public openStepModal(workflowConfigurationStep: CustomWorkflowStepModel): void {
        const modalConfig = new FullModalConfig('Edit workflow configuration step',
            'Edit the information of the workflow configuration step.',
            {
                workflowConfigurationId: this.workflowConfiguration()._id,
                workflowConfigurationStep
            } as IWorkflowConfigurationStepFormData);
        modalConfig.confirmClose = true;
        this.fullModalService.open(WorkflowConfigurationStepFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => {
                if (result) {
                    this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                }
            });
    }

    public openTransitionModal(workflowConfigurationTransition: CustomWorkflowTransitionModel): void {
        const modalConfig = new FullModalConfig('Edit workflow configuration transition',
            'Edit the information of the workflow configuration transition.',
            {
                workflowConfiguration: this.workflowConfiguration(),
                workflowConfigurationSteps: this.workflowConfiguration().steps,
                workflowConfigurationTransition
            } as IWorkflowConfigurationTransitionFormData);

        modalConfig.confirmClose = true;
        this.fullModalService
            .open(WorkflowConfigurationTransitionFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    if (result) this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                }
            });
    }

    private preDeleteTransition(item: CustomWorkflowTransitionModel, usedActions: CustomWorkflowActionModel[]) {
        this.transitionItemsService.findActiveTransitions(item._id).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (transitionItemsResponse) => {
                    if (transitionItemsResponse.items.length) {
                        Toaster.error('There are still items in transition for this workflow configuration transition, try again later.');
                        return;
                    }
                    this.openTransitionDialog(item, !!usedActions.length);
                },
                error: Toaster.handleApiError
            });
    }

    public openTransitionDialog(item: CustomWorkflowTransitionModel, deleteActions: boolean): void {
        const dialogConfig = new NucDialogConfigModel(
            `Delete transition '${item.name}'`,
            deleteActions ? 'There are actions assigned to this transition and they will also be deleted. ' +
                'Are you sure you want to delete the transition?' : 'The transition will be deleted. Are you sure?'
        );
        const dialog = this.dialogService.openDialog(dialogConfig);
        dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
        dialogConfig.addAction('Ok', BUTTON_TYPE.PRIMARY).subscribe(() => {
            dialog.close();
            this.deleteTransition(item);
        });
    }

    private deleteTransition(item: CustomWorkflowTransitionModel): void {
        this.workflowConfigurationService.deleteWorkflowConfigurationTransition(this.workflowConfiguration()._id, item._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    Toaster.success('Transition deleted successfully');
                    this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                },
                error: Toaster.handleApiError
            });

    }

    private getTransitionActions(item: CustomWorkflowTransitionModel, configuration: WorkflowConfigurationModel): CustomWorkflowActionModel[] {
        const transitionActions: CustomWorkflowActionModel[] = [];
        // Actions defined in components
        transitionActions.push(...configuration.components.reduce((actions, component) => {
            actions.push(...component.actions.filter(action => action.transition === item._id));
            return actions;
        }, []));
        // Actions defined in actions
        transitionActions.push(...configuration.actions.filter(action => action.transition === item._id));
        // Actions defined in step components
        for (const step of configuration.steps) {
            transitionActions.push(...step.components.reduce((actions, component) => {
                actions.push(...component.actions.filter(action => action.transition === item._id));
                return actions;
            }, []));
        }

        return transitionActions;
    }

    public openActionModal(workflow: WorkflowConfigurationModel, action?: CustomWorkflowActionModel): void {
        const step =
            this.propertyMetadata().stepId
                ? this.findDataByPropertyMetadata(workflow, {
                    property: EWorkflowConfigurationProperty.STEPS,
                    itemId: this.propertyMetadata().stepId
                })
                : null;
        const component =
            this.propertyMetadata().componentId
                ? this.findDataByPropertyMetadata(workflow, {
                    property: EWorkflowConfigurationProperty.COMPONENTS,
                    stepId: this.propertyMetadata().stepId,
                    itemId: this.propertyMetadata().componentId
                })
                : null;

        const modalData = {
            workflowConfiguration: this.workflowConfiguration(),
            step,
            component,
            action
        } as IWorkflowConfigurationActionFormData;

        const modalConfig = action
            ? new FullModalConfig('Edit workflow configuration action',
                'Edit the information of the workflow configuration action.', modalData)
            : new FullModalConfig('Add workflow configuration action',
                'Enter the information to create a new workflow configuration action.', modalData);

        modalConfig.confirmClose = true;
        this.fullModalService
            .open(WorkflowConfigurationActionFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    if (result) this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                }
            });
    }

    public openFilterModal(filter?: CustomWorkflowFilterModel): void {
        const modalData = {
            workflowConfiguration: this.workflowConfiguration(),
            filter
        } as IWorkflowConfigurationFilterFormData;

        const modalConfig = filter
            ? new FullModalConfig('Edit workflow configuration filter',
                'Edit the information of the workflow configuration filter.', modalData)
            : new FullModalConfig('Add workflow configuration filter',
                'Enter the information to create a new workflow configuration filter.', modalData);

        modalConfig.confirmClose = true;
        this.fullModalService
            .open(WorkflowConfigurationFilterFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    if (result) this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                }
            });
    }

    private preDeleteAction(item: CustomWorkflowActionModel, workflow: WorkflowConfigurationModel) {
        const step =
            this.propertyMetadata().stepId
                ? this.findDataByPropertyMetadata(workflow, {
                    property: EWorkflowConfigurationProperty.STEPS,
                    itemId: this.propertyMetadata().stepId
                })
                : null;

        const component =
            this.propertyMetadata().componentId
                ? this.findDataByPropertyMetadata(workflow, {
                    property: EWorkflowConfigurationProperty.COMPONENTS,
                    stepId: this.propertyMetadata().stepId,
                    itemId: this.propertyMetadata().componentId
                })
                : null;

        const dialogConfig = new NucDialogConfigModel(
            `Delete action '${item.name}'`,
            'The action will be deleted. Are you sure?'
        );
        const dialog = this.dialogService.openDialog(dialogConfig);
        dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
        dialogConfig.addAction('Ok', BUTTON_TYPE.PRIMARY).subscribe(() => {
            dialog.close();
            this.deleteAction(item, component?._id, step?._id);
        });
    }

    private deleteAction(item: ITableItem, componentId: string, stepId: string): void {
        this.workflowConfigurationService.deleteWorkflowConfigurationAction(
            this.workflowConfiguration()._id, item._id, componentId, stepId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    Toaster.success('Action deleted successfully');
                    this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                },
                error: Toaster.handleApiError
            });
    }

    private preDeleteFilter(filter: CustomWorkflowFilterModel) {
        const dialogConfig = new NucDialogConfigModel(
            `Delete filter '${filter.name}'`,
            'The filter will be deleted. Are you sure?'
        );
        const dialog = this.dialogService.openDialog(dialogConfig);
        dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
        dialogConfig.addAction('Ok', BUTTON_TYPE.PRIMARY).subscribe(() => {
            dialog.close();
            this.deleteFilter(filter);
        });
    }

    private deleteFilter(filter: CustomWorkflowFilterModel): void {
        this.workflowConfigurationService.deleteWorkflowConfigurationFilter(
            this.workflowConfiguration()._id, filter._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    Toaster.success('Filter deleted successfully');
                    this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                },
                error: Toaster.handleApiError
            });
    }

    private preDeleteComponent(item: CustomWorkflowComponentModel) {
        const dialogConfig = new NucDialogConfigModel(
            `Delete component '${item.name}'`,
            'The component will be deleted. Are you sure?'
        );
        const dialog = this.dialogService.openDialog(dialogConfig);
        dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
        dialogConfig.addAction('Ok', BUTTON_TYPE.PRIMARY).subscribe(() => {
            dialog.close();
            this.deleteComponent(item);
        });
    }

    private deleteComponent(item: CustomWorkflowComponentModel): void {
        this.workflowConfigurationService.deleteWorkflowConfigurationComponent(
            this.workflowConfiguration()._id, item._id,
            this.propertyMetadata().stepId
        )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    Toaster.success('Component deleted successfully');
                    this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                },
                error: Toaster.handleApiError
            });
    }

    public openComponentModal(workflow: WorkflowConfigurationModel, component: CustomWorkflowComponentModel): void {
        const step =
            this.propertyMetadata().stepId
                ? this.findDataByPropertyMetadata(workflow, {
                    property: EWorkflowConfigurationProperty.STEPS,
                    itemId: this.propertyMetadata().stepId
                })
                : null;

        const modalConfig = new FullModalConfig('Edit workflow configuration component',
            'Edit the information of the workflow configuration component.',
            {
                workflowConfiguration: this.workflowConfiguration(),
                workflowConfigurationStep: step,
                component
            } as IWorkflowConfigurationComponentFormData);
        modalConfig.confirmClose = true;
        this.fullModalService.open(WorkflowConfigurationComponentFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => {
                if (result) {
                    this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                }
            });
    }

    public handleComponentAction(actionType: string): void {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (workflow) => {
                    const component = this.findDataByPropertyMetadata(workflow,
                        {
                            property: EWorkflowConfigurationProperty.COMPONENTS,
                            stepId: this.propertyMetadata().stepId,
                            itemId: this.propertyMetadata().componentId
                        });

                    if (actionType === 'EDIT') {
                        this.openComponentModal(workflow, component);
                    } else if (actionType === 'DELETE') {
                        this.preDeleteComponent(component);
                    } else {
                        Toaster.notYetImplementedError();
                    }
                }, error: Toaster.handleApiError
            });
    }

    public addAction(): void {
        this.workflowConfigurationsDetailsService.getWorkflowConfigurationObservable()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (workflow) => {
                    this.openActionModal(workflow);
                }, error: Toaster.handleApiError
            });
    }

    private findDataByPropertyMetadata(workflow: WorkflowConfigurationModel, propertyMetadata: IWorkflowDetailPropertyMetadata): any {
        let step: CustomWorkflowStepModel;
        let component: CustomWorkflowComponentModel;
        let filter: CustomWorkflowFilterModel;
        let item: CustomWorkflowStepModel|CustomWorkflowComponentModel|CustomWorkflowFilterModel;

        if (propertyMetadata.stepId) {
            step = workflow.steps.find((step) => step._id === propertyMetadata.stepId);
            if (!step) Toaster.error(`Could not find workflow configuration step: ${propertyMetadata.stepId}`);
        }

        if (propertyMetadata.componentId) {
            component = (step || workflow).components.find((component) => component._id === propertyMetadata.componentId);
            if (!component) Toaster.error(`Could not find workflow configuration component: ${propertyMetadata.componentId}`);
        }

        if (propertyMetadata.filterId) {
            filter = workflow.filters.find((filter) => filter._id === propertyMetadata.filterId);
            if (!filter) Toaster.error(`Could not find workflow configuration filter: ${propertyMetadata.filterId}`);
        }

        const data = (component || step || filter || workflow)[propertyMetadata.property.toLowerCase()];

        if (propertyMetadata.itemId) {
            item = data.find((item) => item._id === propertyMetadata.itemId);
            // eslint-disable-next-line max-len
            if (!item) {Toaster.error(`Could not find workflow configuration data with "property: ${propertyMetadata.property.toLowerCase()}" && "id: ${propertyMetadata.itemId}"`);}
        }

        return item || data;
    }
}
