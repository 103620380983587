import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    EColumnSize,
    EColumnType,
    ESelectionMode,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    IItemClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {AppConstants} from '../../../../app.constants';
import {IPackageSetupFormData, PackageSetupFormComponent} from '../../../../forms/package-setup-form/package-setup-form.component';
import {PackageSetupModel} from '../../../../models/api/package-setup.model';
import {EPackageSetupJobType, PackageSetupService} from '../../../../api/services/package-setups.service';
import {ARPagedResponseDataModel} from '@relayter/core';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Toaster} from '../../../../classes/toaster.class';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {finalize, takeUntil} from 'rxjs/operators';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {Subject, Subscription} from 'rxjs';
import {MonitoredJobsService} from '../../../../api/services/monitored-jobs.service';
import {PaginatorService} from '../../../../components/paginator/paginator.service';

@Component({
    selector: 'rl-package-setup-overview',
    templateUrl: './package-setup-overview.component.html',
    styleUrls: ['./package-setup-overview.component.scss'],
    providers: [PaginatorService]
})
export class PackageSetupOverviewComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'package-setup-overview-table';

    public columns: ITableColumn[] = [{
        title: 'Name',
        key: 'name',
        type: EColumnType.DEFAULT,
        sortProperty: 'name',
        size: EColumnSize.LARGE
    }, {
        title: 'Description',
        key: 'description',
        sortProperty: 'description',
        size: EColumnSize.LARGE
    }];

    public ESelectionMode = ESelectionMode;
    public total: number;
    public pageIndex: number;
    public pageSize: number;
    public searching: boolean;
    public items: PackageSetupModel[];
    public disableNextPage: boolean;
    public loading: boolean = false;
    public actions: ITableAction[];
    public packageSetupServiceSubscription: Subscription;
    private onDestroySubject = new Subject<void>();

    constructor(private router: Router,
                private route: ActivatedRoute,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private fullModalService: FullModalService,
                private dialogService: NucDialogService,
                private packageSetupService: PackageSetupService,
                private monitoredJobsService: MonitoredJobsService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.setTableActions();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(pagination => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.updateUrl();

                this.getPackageSetups();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PATCH_PACKAGE_SETUP)) {
            this.actions = [AppConstants.TABLE_ACTION_TYPES.EDIT];
        }
        if (this.userIsAllowedToPipe.transform([AppConstants.PERMISSIONS.POST_PACKAGE_SETUP_JOB,
            AppConstants.PERMISSIONS.COPY_PACKAGE_SETUP])) {
            this.actions = this.actions ? this.actions.concat(AppConstants.TABLE_ACTION_TYPES.COPY) : [AppConstants.TABLE_ACTION_TYPES.COPY];
        }
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_PACKAGE_SETUP)) {
            this.actions = this.actions ? this.actions.concat(AppConstants.TABLE_ACTION_TYPES.DELETE) : [AppConstants.TABLE_ACTION_TYPES.DELETE];
        }
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.sortProperty = params['sortProperty'] ? params['sortProperty'] : '';
        this.sortOrder = params['sortOrder'] && (params['sortOrder'] === 'asc' || params['sortOrder'] === 'desc') ?
            params['sortOrder'] : '';
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;

        this.setPageIndex(pageIndex);
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    public onCreatePackageSetupClicked(): void {
        this.openPackageSetupModal();
    }

    public openPackageSetupModal(packageSetup?: PackageSetupModel): void {
        const modalConfig = packageSetup
            ? new FullModalConfig(
                'Edit package setup', 'Edit the information of the package setup.', {packageSetup} as IPackageSetupFormData)
            : new FullModalConfig(
                'Add package setup', 'Enter the information to create a new package setup.');

        modalConfig.confirmClose = true;
        const modalRef = this.fullModalService.open(PackageSetupFormComponent, modalConfig);
        modalRef.afterClosed().subscribe((setup) => setup ? this.getPackageSetups() : null);
    }

    public getPackageSetups(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_PACKAGE_SETUPS)) {
            return;
        }

        this.packageSetupServiceSubscription?.unsubscribe();

        this.packageSetupServiceSubscription = this.packageSetupService.getPackageSetups(this.pageSize,
            (this.pageIndex - 1) * this.pageSize, this.sortProperty, this.sortOrder, this.searchValue)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (results: ARPagedResponseDataModel<PackageSetupModel>) => {
                    ({items: this.items, total: this.total} = results);
                    this.disableNextPage = this.total <= this.pageIndex * this.pageSize;
                },
                error: Toaster.handleApiError
            });
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const packageSetup = event.item as PackageSetupModel;
        if (event.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
            this.packageSetupService.getPackageSetup(event.item._id).subscribe({
                next: (setup: PackageSetupModel) => this.openPackageSetupModal(setup),
                error: Toaster.handleApiError
            });
        }
        if (event.action === AppConstants.TABLE_ACTION_TYPES.COPY) {
            this.openCopyDialog(packageSetup);
        }
        if (event.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
            this.openDeleteDialog(event.item as PackageSetupModel);
        }
    }

    private updateUrl(): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.EXPORT_SETUPS,
            AppConstants.CONTEXT_URL.CAMPAIGN_PACKAGE_SETUPS,
            new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder)
        ]);
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        if (sortEvent.column?.sortProperty) {
            this.sortProperty = sortEvent.column.sortProperty;
            this.sortOrder = sortEvent.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = '';
            this.sortOrder = '';
        }

        this.setPageIndex();
    }

    public onPackageSetupClicked(tableItem: IItemClickEvent): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_PACKAGE_SETUPS)) {
            this.router.navigate([AppConstants.CONTEXT_URL.EXPORT_SETUPS, AppConstants.CONTEXT_URL.CAMPAIGN_PACKAGE_SETUPS, tableItem.item._id],
                {skipLocationChange: false});
        }
    }

    private openCopyDialog(packageSetup: PackageSetupModel): void {
        const copyDialogConfig = new NucDialogConfigModel(`Duplicate ${packageSetup.name}`,
            'You are about to duplicate a package setup with all the content. Are you sure?');
        const copyDialog = this.dialogService.openDialog(copyDialogConfig);
        copyDialogConfig.addAction('No', BUTTON_TYPE.SECONDARY).subscribe(() => copyDialog.close());
        copyDialogConfig.addAction('Yes', BUTTON_TYPE.PRIMARY).subscribe(() => {
            copyDialog.close();
            this.copyPackageSetup(packageSetup._id);
        });
    }

    private copyPackageSetup(packageSetupId: string): void {
        const jobData = {packageSetupId};

        this.loading = true;
        this.packageSetupService.postJob(EPackageSetupJobType.PACKAGE_SETUP_COPY_JOB, jobData)
            .pipe(
                finalize(() => this.loading = false),
                takeUntil(this.onDestroySubject)
            )
            .subscribe({
                next: (job: JobModel) => this.monitoredJobsService.getJobMonitor(job._id)
                    .pipe(takeUntil(this.onDestroySubject))
                    .subscribe((jobModel: JobModel) => {
                        if (jobModel.status === EJobStatus.DONE) {
                            this.getPackageSetups();
                        }
                    }),
                error: Toaster.handleApiError
            });
    }

    private openDeleteDialog(packageSetup: PackageSetupModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete package setup',
            `Please confirm that you wish to delete ${packageSetup.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deletePackageSetup(packageSetup);
        });
    }

    private deletePackageSetup(packageSetup: PackageSetupModel): void {
        this.loading = true;
        this.packageSetupService.deletePackageSetup(packageSetup._id)
            .pipe(
                finalize(() => this.loading = false),
                takeUntil(this.onDestroySubject)
            )
            .subscribe({
                next: () => {
                    Toaster.success('Package setup removed successfully');
                    this.getPackageSetups();
                },
                error: Toaster.handleApiError
            });
    }

    public onSearchBarValueUpdated(searchValue: string): void {
        if (this.searchValue !== searchValue) {
            this.searchValue = searchValue;
            this.searching = !!this.searchValue;

            this.setPageIndex();
        }
    }
}
