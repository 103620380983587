<div class="header">
    <h1>{{ font?.family }}</h1>
</div>

<div class="information-columns">
    <p class="body-strong">Typeface / Font family</p>
    <p>{{ font?.family }}</p>

    <p class="body-strong">Styles</p>
    <p>{{ numberStyles }}</p>
</div>
<hr>

<h2>Font Styles</h2>
@for (file of font?.files; track file) {
    <div>
        @for (style of file.styles; track style) {
            <p class="body-strong style-name">{{style.name}}</p>
            <p class="body filename">{{ file.name }}</p>
            <img alt="font preview image" src="{{style.preview.url}}"/>
            <hr>
        }
    </div>
}
