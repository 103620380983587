<div *ngIf="permissions.GET_ROLE_USERS | userIsAllowedTo; else noPermissionEmptyState" class="rule-users-container">
    <nuc-table
        [emptyStateTitle]="searchValue ? 'No item matches your search criteria.' : null"
        [items]="items"
        [columns]="columns"
        [tableId]="tableId"
        [prefixStorageKey]="storageKey"
        [loading]="!usersSubscription?.closed"
        (sortOptionChange)="onSortOptionChanged($event)">

        <nuc-input button-bar-left
                   class="search"
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="setPageIndex()"
                   placeholder="Search users">
        </nuc-input>

        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="!usersSubscription?.closed">
        </paginator>
    </nuc-table>

</div>

<ng-template #noPermissionEmptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="No permission"
                               subtitle="You do not have the permission to view this page.">
    </nuc-empty-state-component>
</ng-template>
