import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {AppConstants} from '../../app.constants';
import {DOCUMENT} from '@angular/common';
import {MenuDataService} from './menu.data-service';
import {SocketService} from '../../api/services/socket/socket.service';
import {RLBaseComponent} from '../../components/rl-base-component/rl-base.component';
import {NotificationsDataService} from '../../api/services/notifications.data-service';
import {NotificationsService} from '../../api/services/notifications.service';
import {PageNavigationService} from '../../services/page-navigation.service';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {LastViewedItemsService} from '../../api/services/last-viewed-items.service';

@Component({
    selector: 'rl-relayter-container',
    encapsulation: ViewEncapsulation.None,
    templateUrl: 'relayter-container.component.html',
    styleUrls: ['relayter-container.component.scss'],
    providers: [NotificationsService, NotificationsDataService]
})

export class RelayterContainerComponent extends RLBaseComponent implements OnInit, OnDestroy {
    public hidden: boolean = false;

    private onDestroySubject = new Subject<void>();

    constructor(private router: Router,
                public menuDataService: MenuDataService,
                @Inject(DOCUMENT) private document: any,
                private socketService: SocketService,
                private pageNavigationService: PageNavigationService,
                private lastViewedItemsService: LastViewedItemsService) {
        super();

        this.hidden = true;

        this.socketService.connect();
    }

    public ngOnInit(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            takeUntil(this.onDestroySubject)
        ).subscribe((event: NavigationStart) => {
            this.document.querySelector('body').scrollTop = 0;
            this.pageNavigationService.setPageNavigationRoute(event.url.toString());
        });

        const pathName = window.location.pathname;
        if (pathName === AppConstants.PATH_PREFIX) {
            // TODO: This should be fixed by guards and check which page is available (permissions etc.)
            this.router.navigate([AppConstants.HOME_PAGE]);
        }

        this.lastViewedItemsService.setInitialStates();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }
}
