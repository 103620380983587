import {Component, computed, DestroyRef, effect, inject, signal, WritableSignal} from '@angular/core';
import {TabBarItemModel} from '../../../../../models/ui/tab-bar-item.model';
import {WorkflowConfigurationsDetailsService} from '../workflow-configurations-details.service';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {CustomWorkflowComponentModel} from '../../../../../models/api/custom-workflow-component.model';
import {ActivatedRoute} from '@angular/router';
import {FullModalConfig, FullModalService} from '@relayter/rubber-duck';
import {
    IWorkflowConfigurationComponentFormData,
    WorkflowConfigurationComponentFormComponent
} from '../../../../../forms/workflow-configuration-component-form/workflow-configuration-component-form.component';
import {map} from 'rxjs/operators';
import {
    IWorkflowDetailPropertyMetadata
} from '../workflow-configuration-detail-property/workflow-configuration-detail-property.component';
import {EWorkflowConfigurationProperty} from '../workflow-configuration-property.config';

@Component({
    selector: 'workflow-configuration-step-details-component',
    templateUrl: 'workflow-configuration-step-details.component.html',
    styleUrls: ['workflow-configuration-step-details.component.scss']
})
export class WorkflowConfigurationStepDetailsComponent {
    private destroyRef = inject(DestroyRef);

    public tabBarItems: TabBarItemModel<CustomWorkflowComponentModel>[];
    public selectedTab: TabBarItemModel<CustomWorkflowComponentModel>;

    public workflowConfiguration = this.workflowConfigurationsDetailsService.workflow;
    public stepId = toSignal(this.route.params.pipe(map((params: { stepId: string }) => params.stepId)), {initialValue: null});

    public step = computed(() => {
        return this.workflowConfiguration().steps.find((step) => step._id === this.stepId());
    });

    public propertyMetadata: WritableSignal<IWorkflowDetailPropertyMetadata> = signal(undefined);

    constructor(private route: ActivatedRoute,
                private fullModalService: FullModalService,
                private workflowConfigurationsDetailsService: WorkflowConfigurationsDetailsService) {

        effect(() => {
            // we should change the tabbars but this will reset the
            this.tabBarItems = this.step().components.map((component, index) =>
                new TabBarItemModel<CustomWorkflowComponentModel>(component.name, index));
            // when refreshing workflow, we should still be able to have the correct tab name
            // when switching the workflow step, we should be able to set the tab bar to the original one
            const foundTabItem = this.tabBarItems.find((tab) => tab.title === this.selectedTab?.title);
            this.selectedTab = this.tabBarItems[foundTabItem?.index || 0];
        }, {allowSignalWrites: true});
    }

    public onActiveTabChange(tab: TabBarItemModel<CustomWorkflowComponentModel>): void {
        this.selectedTab = tab;
        const component = this.step().components[tab.index];
        this.propertyMetadata.set({property: EWorkflowConfigurationProperty.ACTIONS, stepId: this.step()._id, componentId: component._id});
    }

    public addComponent(): void {
        const modalData = {
            workflowConfiguration: this.workflowConfiguration(),
            workflowConfigurationStep: this.step()
        } as IWorkflowConfigurationComponentFormData;

        const modalConfig = new FullModalConfig(
            'Add workflow configuration component',
            'Enter the details of workflow configuration component. ',
            modalData);

        modalConfig.confirmClose = true;
        this.fullModalService.open(WorkflowConfigurationComponentFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => {
                if (result) {
                    this.workflowConfigurationsDetailsService.refreshWorkflowConfiguration();
                }
            });
    }
}
