import {ApiService} from './api.service';
import {ApiConstants} from '../api.constant';
import {Injectable} from '@angular/core';
import {ContentRuleModel} from '../../models/api/content-rule.model';

@Injectable({
    providedIn: 'root'
})
export default class ContentRuleApiService extends ApiService<ContentRuleModel> {
    constructor() {
        super([ApiConstants.API_GROUP_CONTENT_RULES], ContentRuleModel);
    }
}
