<div class="header-row">
    <h1>Animated content rulesets</h1>

    <nuc-button-bar>
        @if (permissions.POST_ANIMATED_CONTENT_RULESET | userIsAllowedTo) {
            <nuc-button-primary text="Create ruleset"
                    (click)="openForm()">
            </nuc-button-primary>
        }
    </nuc-button-bar>
</div>

@if (permissions.GET_ANIMATED_CONTENT_RULESETS) {
    <nuc-table [items]="items"
               [columns]="columns"
               [loading]="loading"
               [tableId]="tableId"
               [actions]="actions"
               (itemClicked)="onTableRowClicked($event)"
               (actionClicked)="handleTableRowAction($event)"
               [prefixStorageKey]="storageKey"
               (sortOptionChange)="onSortOptionChanged($event)">
        <nuc-input button-bar-left
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="onSearchBarValueUpdated($event)"
                   placeholder="Search">
        </nuc-input>

        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="loading">
        </paginator>
    </nuc-table>
} @else {
    <no-permission-state-component></no-permission-state-component>
}
