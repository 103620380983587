import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UserModel} from '../../../../models/api/user.model';
import {Toaster} from '../../../../classes/toaster.class';
import {
    BUTTON_TYPE,
    ButtonConfig,
    EColumnSize,
    ESelectionMode,
    FullModalActionModel,
    FullModalService,
    ITableColumn,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {GroupService} from '../../../../api/services/group.service';
import {GroupModel, GroupPatchModel, GroupPostModel} from '../../../../models/api/group.model';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {SelectionModel} from '@angular/cdk/collections';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {RoleModel} from '../../../../models/api/role.model';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {UserDataProvider} from '../../../../api/data-providers/user.data-provider';
import {EFormStatus} from '../../../../app.enums';

export interface IGroupFormComponentData {
    group?: GroupModel;
}

@Component({
    selector: 'rl-group-form-component',
    templateUrl: 'group-form.component.html',
    styleUrls: ['group-form.component.scss'],
    providers: [PaginatorService]
})

export class GroupFormComponent extends RLTableComponent implements OnInit {
    public tableId = 'group-form-table';
    public formGroup = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required)
    });

    public columns: ITableColumn[] = [
        {
            title: 'Full name',
            key: 'fullName',
            size: EColumnSize.LARGE,
            sortProperty: 'fullName'
        },
        {
            title: 'Email',
            key: 'email',
            size: EColumnSize.BASE,
            sortProperty: 'email'
        },
        {
            title: 'Roles',
            key: 'roles',
            size: EColumnSize.LARGE,
            format: (roles: RoleModel[]) => roles?.map(role => role.name).join(', ')
        }
    ];

    private saveConfig: ButtonConfig;
    public ESelectionMode = ESelectionMode;

    public usersDataProvider = new UserDataProvider(this.tableSortOptions);

    constructor(private groupService: GroupService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private fullModalService: FullModalService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IGroupFormComponentData) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.usersDataProvider.selection = new SelectionModel<UserModel>(true)

        this.initButtons();
        this.initForm();

        this.paginatorService.getPagination(this.tableId).subscribe((result: { pageIndex: number; pageSize: number }) => {
            this.usersDataProvider.pageIndex = result.pageIndex;
            this.usersDataProvider.pageSize = result.pageSize;

            this.getUsers();
        });

        this.setPageIndex();
    }

    private initButtons(): void {
        this.saveConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', false, false, true);
        const cancelConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancelAction = new FullModalActionModel(cancelConfig);
        const saveAction = new FullModalActionModel(this.saveConfig);

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => this.saveGroup());

        const actions = [cancelAction, saveAction];
        this.fullModalService.setModalActions(actions);
    }

    private initForm(): void {
        this.formGroup.statusChanges.pipe(
            map((status) => status === EFormStatus.VALID),
            distinctUntilChanged()
        ).subscribe((valid) => this.saveConfig.disabled = !valid);

        if (this.modalData.group) {
            this.formGroup.patchValue(this.modalData.group);
            this.usersDataProvider.setSelection(...this.modalData.group.users.map(user => {return {_id: user} as UserModel}));
        }
    }

    /**
     * Set page index, default the first page
     *
     * @param {number} [pageIndex]
     */
    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex); // reset pageIndex
    }

    public getUsers(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_USERS)) return;

        this.usersDataProvider.retrieveData();
    }

    public saveGroup(): void {
        const selected = this.usersDataProvider.selected.map(item => item._id);
        // When updating existing group do PATCH, else do POST
        if (this.modalData.group) {
            const group = new GroupPatchModel(this.formGroup.value.name, selected);
            this.groupService.patchGroup(this.modalData.group._id, group).subscribe({
                next: (result) => {
                    Toaster.success('Content group updated successfully');
                    this.fullModalService.close(result, false);
                },
                error: Toaster.handleApiError
            });
        } else {
            const group = new GroupPostModel(this.formGroup.value.name, selected);
            this.groupService.postGroup(group).subscribe({
                next: (result) => {
                    Toaster.success('Content group created successfully');
                    this.fullModalService.close(result, false);
                },
                error: Toaster.handleApiError
            });
        }
    }
}
