import {
    animate,
    animateChild,
    AnimationTriggerMetadata,
    group,
    keyframes,
    query,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';

export const DEFAULT_CONTAINER_ANIMATION_SPEED = 250;
export const DEFAULT_CONTENT_ANIMATION_SPEED = DEFAULT_CONTAINER_ANIMATION_SPEED * .6;
export const DEFAULT_CONTENT_ANIMATION_DELAY = DEFAULT_CONTAINER_ANIMATION_SPEED * .6;

const containerDuration = DEFAULT_CONTAINER_ANIMATION_SPEED;
const contentDuration = DEFAULT_CONTENT_ANIMATION_SPEED;
const contentDelay = DEFAULT_CONTENT_ANIMATION_DELAY;

const TRANSFORM_OPERATION_100PX = 'translateX(-100px)';
const TRANSFORM_OPERATION_15PX = 'translateX(-15px)';

const containerAnimation = [
    state('void, close', style({opacity: 0, transform: TRANSFORM_OPERATION_100PX})),
    transition('* => open', group([
        query('@contentAnimation', animateChild()),
        animate(`{{speed}}ms ease-out`, keyframes([
            style({opacity: 0, transform: TRANSFORM_OPERATION_100PX, offset: 0}),
            style({opacity: 1, transform: 'none', offset: 1})
        ])),
    ]), {params: {speed: `${containerDuration}`}}),
    transition('* => close', group([
        query('@contentAnimation', animateChild()),
        animate(`{{speed}}ms {{delay}}ms ease-out`, keyframes([
            style({opacity: 1, transform: 'none', offset: 0}),
            style({opacity: 0, transform: TRANSFORM_OPERATION_100PX, offset: 1})
        ])),
    ]), {params: {speed: `${containerDuration}`, delay: `${contentDelay}`}})
];

const contentAnimation = [
    transition('* => open', [
        animate(`{{speed}}ms {{delay}}ms ease-out`, keyframes([
            style({opacity: 0, transform: TRANSFORM_OPERATION_15PX, offset: 0}),
            style({opacity: 1, transform: 'none', offset: 1})
        ]))
    ], {params: {speed: `${contentDuration}`, delay: `${contentDelay}`}}),
    transition('* => close', [
        animate(`{{speed}}ms ease-out`, keyframes([
            style({opacity: 1, transform: 'none', offset: 0}),
            style({opacity: 0, transform: TRANSFORM_OPERATION_15PX, offset: 1})
        ])),
    ], {params: {speed: `${contentDuration}`}}),
];

const changeAnimation = [
    transition('* => changing-out', [
            animate(`150ms ease-out`, keyframes([
                style({opacity: 1, offset: 0}),
                style({opacity: 0, offset: 1})
            ]))
        ]),
    transition('* => changing-in', [
        animate(`150ms ease-out`, keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 1, offset: 1})
        ])),
    ]),
];

export const subMenuAnimations: {
    readonly container: AnimationTriggerMetadata;
    readonly content: AnimationTriggerMetadata;
    readonly change: AnimationTriggerMetadata;
} = {
    container: trigger('containerAnimation', containerAnimation),
    content: trigger('contentAnimation', contentAnimation),
    change: trigger('changeAnimation', changeAnimation),
};
