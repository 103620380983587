import {autoserialize} from 'cerialize';
import {ITableItem} from '@relayter/rubber-duck';

export class AuditTrailModel implements ITableItem {
    @autoserialize public _id: string;
    @autoserialize public path: string;
    @autoserialize public method: string;
    @autoserialize public payload: string;
    @autoserialize public user: string;
    @autoserialize public userFullName: string;
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;
}
