import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {AfterEffectsProjectFilesApiService} from '../../../api/services/after-effects-project-files.api.service';
import {
    BUTTON_TYPE,
    ButtonConfig, DropdownComponent,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA, NUCComponentsModule, NUCInputsModule
} from '@relayter/rubber-duck';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {EAnimatedContentEngineType} from '../../../models/api/animated-content-template.model';
import {AfterEffectsProjectFileModel} from '../../../models/api/after-effects-project-file.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../classes/toaster.class';
import {AnimatedContentRulesetModel} from '../../../models/api/animated-content-ruleset.model';
import {ComponentsModule} from '../../../components/components.module';
import {distinctUntilChanged, finalize, map} from 'rxjs/operators';
import {ModelUtil} from '../../../classes/model.util';
import {AnimatedContentRulesetApiService} from '../../../api/services/animated-content-ruleset.api.service';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {EFormStatus} from '../../../app.enums';

export interface IAnimatedContentRulesetFormComponentData {
    animatedContentRuleset?: AnimatedContentRulesetModel;
}

export interface AnimatedContentRulesetFormGroup {
    name: FormControl<string>
    engineType: FormControl<DropdownItem<EAnimatedContentEngineType>>;
    afterEffectsProjectFiles: FormControl<AfterEffectsProjectFileModel[]>;
}

@Component({
  selector: 'animated-content-ruleset-form',
  templateUrl: './animated-content-ruleset-form.component.html',
  styleUrl: './animated-content-ruleset-form.component.scss',
  imports: [
      ComponentsModule,
      NUCComponentsModule,
      NUCInputsModule,
      ReactiveFormsModule
  ],
  standalone: true
})
export class AnimatedContentRulesetFormComponent implements OnInit {
    private modalData: IAnimatedContentRulesetFormComponentData = inject(NUC_FULL_MODAL_DATA);
    private destroyRef = inject(DestroyRef);
    private afterEffectsProjectFilesApiService = inject(AfterEffectsProjectFilesApiService);
    private animatedContentRulesetService = inject(AnimatedContentRulesetApiService);
    private fullModalService = inject (FullModalService);

    public formGroup: FormGroup<AnimatedContentRulesetFormGroup>;
    private saveButtonConfig: ButtonConfig;

    public animatedContentRuleset: AnimatedContentRulesetModel;

    public engineTypes = [
        new DropdownItem(EAnimatedContentEngineType.AFTER_EFFECTS, EAnimatedContentEngineType.AFTER_EFFECTS)
    ];

    public afterEffectsProjectFiles: AfterEffectsProjectFileModel[] = [];
    public afterEffectsProjectFilesTotalItems = 0;
    public afterEffectsProjectFilesOffset = 0;

    public ngOnInit(): void {
        if (this.modalData?.animatedContentRuleset) this.animatedContentRuleset = this.modalData.animatedContentRuleset;

        this.initForm();
        this.initButtons();
        this.getAfterEffectsProjectFiles();
    }

    private initForm() {
        this.formGroup = new FormGroup<AnimatedContentRulesetFormGroup>({
            name: new FormControl(this.animatedContentRuleset?.name || '', Validators.required),
            engineType: new FormControl({ value: this.engineTypes[0], disabled: true }, Validators.required),
            afterEffectsProjectFiles: new FormControl(undefined, Validators.required)
        });

        if (this.animatedContentRuleset?.afterEffectsProjectFiles) {
            this.afterEffectsProjectFiles = this.afterEffectsProjectFiles.concat(this.animatedContentRuleset.afterEffectsProjectFiles)
            this.formGroup.controls.afterEffectsProjectFiles.patchValue(this.animatedContentRuleset.afterEffectsProjectFiles)
        }
    }

    private initButtons(): void {
        this.saveButtonConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, this.animatedContentRuleset ? 'Save' : 'Create', false, false, true);
        const cancelButtonConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const saveAction = new FullModalActionModel(this.saveButtonConfig);
        const cancelAction = new FullModalActionModel(cancelButtonConfig);
        const actions = [
            cancelAction,
            saveAction,
        ];

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveAnimatedContentRuleset());

        this.fullModalService.setModalActions(actions);

        this.formGroup.statusChanges.pipe(
            map((status) => status === EFormStatus.VALID),
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((valid) => this.saveButtonConfig.disabled = !valid);
    }

    private saveAnimatedContentRuleset() {
        const formValue = this.formGroup.getRawValue();
        const body = ModelUtil.createApiBody({
            name: formValue.name,
            engineType: formValue.engineType.getValue(),
            afterEffectsProjectFiles: formValue.afterEffectsProjectFiles.map((afterEffectsProject) => afterEffectsProject._id)
        }, this.animatedContentRuleset?._id)

        if (this.animatedContentRuleset) {
            this.animatedContentRulesetService.patch(this.animatedContentRuleset._id, body)
                .pipe(
                    finalize(() => this.saveButtonConfig.loading = false),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: () => {
                        Toaster.success('Animated content ruleset updated successfully');
                        this.fullModalService.close(true);
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.animatedContentRulesetService.create(body)
                .pipe(
                    finalize(() => this.saveButtonConfig.loading = false),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: () => {
                        Toaster.success('Animated content ruleset created successfully');
                        this.fullModalService.close(true);
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    public getAfterEffectsProjectFiles(search?: string): void {
        this.afterEffectsProjectFilesApiService
            .find(DropdownComponent.DEFAULT_LIMIT, this.afterEffectsProjectFilesOffset, null, null, search)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.afterEffectsProjectFilesTotalItems = result.total;
                    this.afterEffectsProjectFilesOffset += result.items.length;

                    this.afterEffectsProjectFiles = this.afterEffectsProjectFiles
                        .filter(item => !result.items.some(newItem => newItem._id === item._id))
                        .concat(result.items);
                },
                error: Toaster.handleApiError
            });
    }

    public requestAfterEffectsProjectFiles(event?: IDropdownRequestDataEvent): void {
        if (event.reset) {
            this.afterEffectsProjectFiles = [];
            this.afterEffectsProjectFilesTotalItems = 0;
            this.afterEffectsProjectFilesOffset = 0;
        }

        this.getAfterEffectsProjectFiles(event.search);
    }
}
