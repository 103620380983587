<form [formGroup]="formGroup">
    <nuc-form-field label="Name">
        <nuc-input formControlName="name" placeholder="Name of the After Effects project file" required></nuc-input>
    </nuc-form-field>

    <rl-upload-file-component
        title="Source file"
        (onUploadUpdate)="uploadUpdateSubject.next($event)"
        [fileTypeCategories]="fileTypeCategories">
    </rl-upload-file-component>
</form>
