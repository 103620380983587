<div class="header-row">
    <h1>Workflow automation overview</h1>

    <nuc-button-bar>
        <nuc-button-primary *ngIf="permissions.POST_WORKFLOW_AUTOMATION | userIsAllowedTo"
                            text="Add automation"
                            (click)="onAddClicked()">
        </nuc-button-primary>
    </nuc-button-bar>
</div>

<ng-container *ngIf="permissions.GET_WORKFLOW_AUTOMATIONS | userIsAllowedTo; else noPermissionState">
    <nuc-table emptyStateTitle="No workflow automations"
               [items]="items"
               [columns]="columns"
               [loading]="!subscription?.closed"
               [actions]="actions"
               [selectionMode]="ESelectionMode.EMIT"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               (itemClicked)="onItemClicked($event)"
               (actionClicked)="handleTableRowAction($event)"
               (sortOptionChange)="onSortOptionChanged($event)">
        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="!hasNext"
                   [loading]="!subscription?.closed">
        </paginator>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
