import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {combineLatest, Subject, Subscription} from 'rxjs';
import {ProductModel} from '../../../../models/api/product.model';
import {EProductJobTypes, IProductImportJobData, ProductService} from '../../../../api/services/products.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Toaster} from '../../../../classes/toaster.class';
import {AppConstants} from '../../../../app.constants';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {RLBaseComponent} from '../../../../components/rl-base-component/rl-base.component';
import {EColumnDataType, FullModalConfig, FullModalService} from '@relayter/rubber-duck';
import {filter, takeUntil} from 'rxjs/operators';
import {EDataFieldCollectionName, EDataFieldTypes} from '../../../../app.enums';
import {EAssetDisplayProperties} from '../../../../pipes/asset-display.pipe';
import {ProductFormComponent} from '../../../../forms/product-form/product-form.component';
import {DataFieldModel} from '../../../../models/api/data-field.model';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {EPropertySettingsContext} from '../../../../components/property-settings/property-settings.service';
import {AdvancedFiltersDataService} from '../../../../api/services/advanced-filters.data-service';
import {CursorArray} from '../../../../api/api-cursor';
import {EStateContext, StateService} from '../../../../classes/state.service';
import {ARLogger} from '@relayter/core';
import {PageNavigationService} from '../../../../services/page-navigation.service';
import {IImportDataFormComponentData, ImportDataFormComponent} from '../../../../forms/import-data-form/import-data-form.component';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {MonitoredJobsService} from '../../../../api/services/monitored-jobs.service';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {ApiConstants} from '../../../../api/api.constant';
import {MatDrawer} from '@angular/material/sidenav';
import {animate, style, transition, trigger} from '@angular/animations';
import {DataFieldsApiService} from '../../../../api/services/data-fields.api.service';
import {AssetModel} from '../../../../models/api/asset.model';
import {
    ExportDataFieldCollectionComponent,
    IExportDataFieldCollectionData
} from '../../../../components/export-data-field-collection/export-data-field-collection.component';

@Component({
    selector: 'rl-products-overview',
    templateUrl: 'products-overview.component.html',
    styleUrls: ['products-overview.component.scss'],
    animations: [
        trigger('myRemoveTrigger', [
            transition(':leave', [
                animate('0.2s cubic-bezier(0.22, 0.88, 0.49, 1.27)', style({transform: 'translateX(100%)'}))
            ])
        ]),
    ],
    providers: [AdvancedFiltersDataService, PaginatorService]
})
export class ProductsOverviewComponent extends RLBaseComponent implements OnInit, OnDestroy {
    private static readonly DEFAULT_SORT_OPTIONS = [
        new DropdownItem('Date modified', 'updatedAt'),
        new DropdownItem('Date created', 'createdAt')
    ];
    private static readonly SEARCH_SORT_OPTIONS = [new DropdownItem<string>('Relevance', 'relevance')];

    public readonly tableId = 'products-overview-table';
    public DEFAULT_SIDEBAR_WIDTH = 450;

    public EAssetDisplayProperties = EAssetDisplayProperties;
    public EPropertySettingsContext = EPropertySettingsContext;
    public EDataFieldTypes = EDataFieldTypes;

    public pageIndex: number;
    public sortingOptions = ProductsOverviewComponent.DEFAULT_SORT_OPTIONS;

    public sort: DropdownItem<string>;
    public beforeSearchSort: DropdownItem<string>;
    public sortDescending: boolean;
    public beforeSearchSortDescending: boolean;
    public pageSize: number;
    public searchValue: string;

    public _selectedProduct: ProductModel;
    public productAssets: AssetModel[] = [];
    public get selectedProduct(): ProductModel {
        return this._selectedProduct;
    };
    public set selectedProduct(product: ProductModel) {
        this._selectedProduct = product;
        // Sort assets descending on updatedAt
        this.productAssets = Array.from(this._selectedProduct?.assets || []).sort((asset1, asset2) => {
            return asset2.updatedAt.getTime() - asset1.updatedAt.getTime();
        });

    };

    public products: ProductModel[] = [];
    public dataFields: DataFieldModel[];
    public productsSubscription: Subscription;
    public productSubscription: Subscription;

    private onDestroySubject = new Subject<void>();
    private cursorArray: CursorArray;
    public filterValues: Record<string, any>;
    public disableNextPage = true;
    private firstLoad = true;
    private sortDuplicates = true;

    @ViewChild('drawer')
    public drawer: MatDrawer;

    public get isSearching(): boolean {
        return !!this.searchValue || Object.keys(this.filterValues || {}).length > 0;
    }

    constructor(private router: Router,
                private route: ActivatedRoute,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private productService: ProductService,
                private dataFieldsService: DataFieldsApiService,
                private filtersDataService: AdvancedFiltersDataService,
                private fullModalService: FullModalService,
                private stateService: StateService,
                private monitoredJobsService: MonitoredJobsService,
                private pageNavigationService: PageNavigationService,
                private paginatorService: PaginatorService) {
        super();
    }

    public ngOnInit(): void {
        this.initFromRoute();

        this.pageNavigationService.pageNavigationSubject$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((lastRoutes) => {
                // Check we return from the product details page to this overview
                if (lastRoutes[0] && lastRoutes[0].match(`^${AppConstants.HOME_PAGE}/${AppConstants.OBJECT_ID_REGEX}$`)) {
                    // Get pageIndex and cursorArray from previous state or initiate new value
                    const newPageIndex = this.stateService.getPageIndex(EStateContext.PRODUCT_OVERVIEW) || 1;
                    this.cursorArray = this.stateService.getCursorArray(EStateContext.PRODUCT_OVERVIEW) ||
                        new CursorArray(newPageIndex, this.sortDuplicates);
                    this.paginatorService.setPageIndex(this.tableId, newPageIndex);
                } else {
                    // Initiate new value
                    if (!this.cursorArray) {
                        const newPageIndex = this.pageIndex || 1;
                        this.cursorArray = this.cursorArray || new CursorArray(newPageIndex, this.sortDuplicates);
                        this.paginatorService.setPageIndex(this.tableId, newPageIndex);
                    }
                }
            });

        combineLatest([this.filtersDataService.getFilterValues(), this.paginatorService.getPagination(this.tableId)])
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(([filterValues, paginator]) => {
                if (this.filterValues !== filterValues) {
                    this.filterValues = filterValues;
                    if (!this.firstLoad) {
                        this.paginatorService.setPageIndex(this.tableId, 1);
                        return;
                    }
                }

                this.firstLoad = false;

                if (this.pageSize !== paginator.pageSize) {
                    this.pageSize = paginator.pageSize;
                }

                if (this.pageIndex !== paginator.pageIndex) {
                    this.pageIndex = paginator.pageIndex;
                }

                if (this.pageIndex === 1) {
                    this.resetCursorArray();
                }

                this.stateService.setPageIndex(EStateContext.PRODUCT_OVERVIEW, this.pageIndex);

                this.updateUrl();

                this.getProducts();
            });

        this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.PRODUCT)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((dataFields) => {
                this.dataFields = dataFields;
            });
    }

    /**
     * On destroy, remove subscriptions
     */
    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    /**
     * Gets the sorting options, search value from url or sets to default values.
     */
    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        const sortValue = params['sortProperty'] ? params['sortProperty'] : this.sortingOptions[0].getValue();
        this.sort = this.sortingOptions.find((item) => item.getValue() === sortValue);
        this.beforeSearchSort = this.sort;
        this.sortDescending = params['sortOrder'] ? params['sortOrder'] === 'desc' : true;
        this.beforeSearchSortDescending = this.sortDescending;
        this.searchValue = params['search'] ? params['search'] : '';

        this.updateSortOptions();
    }

    private getProducts(): void {
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_PRODUCTS)) return;

        // prevent showing results from older slower calls
        this.productsSubscription?.unsubscribe();

        const cursor = this.cursorArray.getCursor(this.pageIndex);

        this.productsSubscription = this.productService.getData(
            this.sortDescending, !this.searchValue ? this.sort.getValue() : null, this.searchValue,
            this.pageSize, 0, this.filterValues, cursor)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result) => {
                    this.products = result.items;
                    this.disableNextPage = !result.hasNext;

                    if (result.items.length > 0) {
                        this.setCursor();
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private createMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(null, null, this.sort.getValue(), this.sortDescending ? 'desc' : 'asc', this.searchValue || '');
    }

    /**
     * Updates the url in the browser with filters, sorting, page size. Doesn't update the history stack.
     * Does not set pageIndex in url because we do not support directly navigating to a page with cursor paging.
     */
    public updateUrl(): void {
        this.router.navigate([AppConstants.PRODUCTS_PATH, {...this.createMatrixUrl(), ...this.filterValues}], {replaceUrl: true});
    }

    private resetCursorArray(pageIndex = this.pageIndex): void {
        this.cursorArray.reset(pageIndex, this.sortDuplicates, this.searchValue ? EColumnDataType.NUMBER : undefined);
        this.stateService.setCursorArray(EStateContext.PRODUCT_OVERVIEW, this.cursorArray);
    }

    private setCursor(): void {
        if (this.searchValue) {
            this.cursorArray.setCursor(this.pageIndex, ApiConstants.SEARCH_INDEX_SCORE, this.products[this.products.length - 1]);
        } else {
            this.cursorArray.setCursor(this.pageIndex, this.sort.getValue(), this.products[this.products.length - 1]);
        }
        this.stateService.setCursorArray(EStateContext.PRODUCT_OVERVIEW, this.cursorArray);
    }

    /**
     * search input changed
     * @param {string} value searched for
     */
    public onSearch(value: string): void {
        if (this.searchValue !== value) {
            this.searchValue = value;
            this.paginatorService.setPageIndex(this.tableId, 1);
        }

        this.updateSortOptions();
    }

    private updateSortOptions(): void {
        if (this.searchValue) {
            // Preserve values
            this.beforeSearchSort = this.sort;
            this.beforeSearchSortDescending = this.sortDescending;
            // Show sort on relevance
            this.sortingOptions = ProductsOverviewComponent.SEARCH_SORT_OPTIONS;
            this.sort = this.sortingOptions[0];
            this.sortDescending = true;
        } else {
            this.sortingOptions = ProductsOverviewComponent.DEFAULT_SORT_OPTIONS;
            this.sortDescending = this.beforeSearchSortDescending;
            this.sort = this.sortingOptions.find(option => option.getValue() === this.beforeSearchSort?.getValue()) ||
                this.sortingOptions[0];
        }
    }

    /**
     * On collection-view item double clicked go to selected product detail page
     * @param {ProductModel} product
     */
    public onCollectionViewItemDoubleClicked(product: ProductModel): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_PRODUCT)) {
            this.router.navigate([AppConstants.PRODUCTS_PATH, product._id]);
        }
    }

    /**
     * Open product preview on the right and get product details (se we get all the assets populated)
     * @param {ProductModel} product
     */
    public onCollectionViewItemClicked(product: ProductModel): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_PRODUCT)) {
            // prevent showing results from older slower calls
            if (this.productSubscription) {
                this.productSubscription.unsubscribe();
            }
            this.selectedProduct = product;
            this.productSubscription = this.productService.getProductById(product._id)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe({
                    next: (res: ProductModel) => {
                        this.selectedProduct = res;
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    /**
     * on sort field, type and paging
     */
    public onCollectionViewOptionsChanged(): void {
        this.paginatorService.setPageIndex(this.tableId, 1);
    }

    public onAddProductClicked(): void {
        const modalConfig = new FullModalConfig('Add Product', 'You can edit basic product information.');
        modalConfig.hideHeaderDivider = true;

        const modal = this.fullModalService.open(ProductFormComponent, modalConfig);
        modal.afterClosed().pipe(filter((refresh: boolean) => refresh)).subscribe(() => {
            this.getProducts();
        });
    }

    public openImportProductsModal(): void {
        const defaultFields = {};
        const modalData: IImportDataFormComponentData = {identifierContext: EDataFieldCollectionName.PRODUCT, defaultFields};
        const modalConfig = new FullModalConfig('Import products', 'Import a .csv/.xlv to fill your product data.', modalData);
        modalConfig.confirmClose = true;
        const modalRef = this.fullModalService.open(ImportDataFormComponent, modalConfig);
        modalRef.afterClosed().pipe(filter((data) => !!data)).subscribe((data: any) => this.scheduleImportJob(data));
    }

    public scheduleImportJob(data: any): void {
        const jobData = {
            identifier: data.formValue.identifier.field.getValue(),
            s3Key: data.s3Key
        } as IProductImportJobData;

        this.productService.postJob(EProductJobTypes.PRODUCT_IMPORT, jobData)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                    next: (scheduleJob: JobModel) => {
                        ARLogger.debug('Job scheduled: ' + scheduleJob._id);
                        this.monitorJob(scheduleJob._id);
                    },
                    error: Toaster.handleApiError
                });
    }

    public openExportProductsModal(): void {
        const modalData: IExportDataFieldCollectionData = {
            sortProperty: [this.sort?.getValue()],
            sortOrder: this.sortDescending ? 'desc' : 'asc',
            cursorDuplicates: this.sortDuplicates,
            dataTypes: [EColumnDataType.DATE],
            dataFieldCollection: EDataFieldCollectionName.PRODUCT
        };

        const modalConfig = new FullModalConfig('Download products', 'Select file type.', modalData);
        this.fullModalService.open(ExportDataFieldCollectionComponent, modalConfig);
    }

    public onAssetDeleted(): void {
        this.selectedProduct = null;
        this.getProducts();
    }
    private monitorJob(jobId: string): void {
        this.monitoredJobsService.getJobMonitor(jobId)
            .subscribe((jobModel: JobModel) => {
                if (jobModel.status === EJobStatus.DONE) {
                    this.paginatorService.setPageIndex(this.tableId, 1);
                }
            });
    }
}
