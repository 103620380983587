import {ITableItem} from '@relayter/rubber-duck';
import {autoserialize} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {ApiConstants} from '../../../../api/api.constant';

export class StaticContentRulesetItemGroupModel implements ITableItem, IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public limit: number;
    @autoserialize public stack: string;
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;

    constructor(name: string,
                limit: number,
                stack: string) {
        this.name = name;
        this.limit = limit;
        this.stack = stack;
    }

    getTitle(): string {
        return this.name;
    }

    getValue(): string {
        return this._id;
    }

    public getApiPath(): string {
        return ApiConstants.API_METHOD_ITEM_GROUPS;
    }
}
