<div *ngIf="(permissions.GET_ROLE | userIsAllowedTo) && (permissions.GET_PERMISSIONS | userIsAllowedTo); else noPermissionEmptyState" class="role-permissions-container">
    <nuc-table [items]="items"
               [emptyStateTitle]="searchValue ? 'No item matches your search criteria.' : null"
               [columns]="columns"
               [loading]="!permissionsSubscription?.closed || !requestSubscription?.closed"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               (sortOptionChange)="onSortOptionChanged($event)"
               [selectionMode]="ESelectionMode.MULTI"
               [selection]="selection">
        <ng-container button-bar-left>
            <nuc-input class="search"
                       [(data)]="searchValue"
                       prefix="nucicon_search"
                       (debounceValueChanged)="setPageIndex()"
                       placeholder="Search permissions">
            </nuc-input>
        </ng-container>
        <ng-container button-bar-right>
            <nuc-button-primary
                *ngIf="!canDeactivate() && (permissions.PUT_ROLE | userIsAllowedTo)"
                [disabled]="!permissionsSubscription?.closed || !requestSubscription?.closed"
                (click)="openConfirmDialog()"
                text="Save changes">
            </nuc-button-primary>
            <paginator [viewId]="tableId"
                       [disableNextPage]="pageSize * pageIndex >= total"
                       [loading]="!permissionsSubscription?.closed || !requestSubscription?.closed">
            </paginator>
        </ng-container>
    </nuc-table>
</div>

<ng-template #noPermissionEmptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="No permission"
                               subtitle="You do not have the permission to view this page.">
    </nuc-empty-state-component>
</ng-template>
