<ng-container *ngIf="permissions.GET_USERS | userIsAllowedTo else noPermissionState">
    <nuc-table [tableDataProvider]="usersDataProvider"
               [tableSortOptions]="usersDataProvider.sortOptions"
               [columns]="columns"
               [actions]="actions"
               (actionClicked)="handleTableRowAction($event)"
               [selectionMode]="selectionMode"
               (itemClicked)="onRowClicked($event)"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               (tableSortOptionsChange)="setPageIndex()">
        <nuc-input button-bar-left
                   class="search"
                   [(data)]="usersDataProvider.searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="setPageIndex()"
                   placeholder="Search users">
        </nuc-input>

        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="usersDataProvider.disableNextPage"
                   [loading]="usersDataProvider.loading">
        </paginator>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
