import {Component, OnInit} from '@angular/core';
import {UserDetailModel} from '../../../models/api/user.model';
import {UserService} from '../../../api/services/users.service';
import {Toaster} from '../../../classes/toaster.class';
import {ChangePasswordFormComponent} from '../../../forms/change-password-form/change-password-form.component';
import {TeamAccountService} from '../../../api/services/team-account.service';
import {TeamModel} from '../../../models/api/team.model';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {TransferOwnershipFormComponent} from '../../../forms/transfer-ownership-form/transfer-ownership-form.component';
import {RLBaseComponent} from '../../../components/rl-base-component/rl-base.component';
import {FullModalConfig, FullModalService} from '@relayter/rubber-duck';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {forkJoin, of} from 'rxjs';

@Component({
    selector: 'rl-account-component',
    templateUrl: 'account.component.html',
    styleUrls: ['account.component.scss']
})

export class AccountComponent extends RLBaseComponent implements OnInit {
    public user: UserDetailModel;
    public team: TeamModel;

    constructor(private userService: UserService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private fullModalService: FullModalService,
                private teamService: TeamAccountService) {
        super();
    }

    public ngOnInit(): void {
        this.getData();
    }

    public onChangePasswordButtonClicked(): void {
        const config = new FullModalConfig(
            'Change your password',
            'You have requested to change the password for your account.');
        config.confirmClose = true;

        this.fullModalService.open(ChangePasswordFormComponent, config);
    }

    public onTransferOwnershipButtonClicked(): void {
        const modalData = {owner: this.user};
        const config = new FullModalConfig(
            'Transfer ownership',
            'Fill in the information below to transfer ownership to another user. When transferred it can only be changed back by the new owner.',
            modalData);
        config.confirmClose = true;

        this.fullModalService.open(TransferOwnershipFormComponent, config)
            .afterClosed().subscribe((res) => res ? this.getData() : null);
    }

    /**
     * on Init get user and team details
     */
    public getData(): void {
        forkJoin([
            this.userService.getMe(),
            this.userIsAllowedToPipe.transform(this.permissions.GET_TEAM_DETAILS) ?
                this.teamService.getTeamDetails() : of(null)
        ])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([user, team]) => {
                    this.user = user;
                    this.team = team;
                },
                error: Toaster.handleApiError
            });
    }
}
