import {Component, OnInit} from '@angular/core';
import {ConnectionModel, EConnectionType} from '../../../../../../models/api/connection.model';
import {ActivatedRoute} from '@angular/router';
import {ConnectionService} from '../../../../../../api/services/connection.service';
import {BUTTON_TYPE, FullModalConfig, FullModalService, NucDialogConfigModel, NucDialogService} from '@relayter/rubber-duck';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../../../../classes/toaster.class';
import {RLBaseComponent} from '../../../../../../components/rl-base-component/rl-base.component';
import {ConnectionFormComponent} from '../../../../../../forms/connection-form/connection-form.component';
import {
    IConnectionSynchronisationFormComponentData,
    SuperunieAdamImageSynchronisationFormComponent
// eslint-disable-next-line max-len
} from '../../../../../../forms/connection-form/superunie-adam/superunie-adam-image-synchronisation-form/superunie-adam-image-synchronisation-form.component';
import {
    SuperunieAdamProductSynchronisationFormComponent
// eslint-disable-next-line max-len
} from '../../../../../../forms/connection-form/superunie-adam/superunie-adam-product-synchronisation-form/superunie-adam-product-synchronisation-form.component';
import {ConnectionApiService} from '../../../../../../api/services/connection.api.service';

interface IMenuItem {
    title: string;
    property: EConnectionSynchronisation;
}

export enum EConnectionSynchronisation {
    IMAGE = 'Image',
    PRODUCT = 'Product'
}

@Component({
    selector: 'connection-detail-superunie-adam',
    templateUrl: './connection-detail-superunie-adam.component.html',
    styleUrls: ['./connection-detail-superunie-adam.component.scss']
})
export class ConnectionDetailSuperunieAdamComponent extends RLBaseComponent implements OnInit {
    private connectionId: string;
    public connection: ConnectionModel;

    public loading: boolean;
    public logo = ConnectionModel.getLogo(EConnectionType.SUPERUNIE_ADAM);

    public menuItems: IMenuItem[] = [
        { title: 'image', property: EConnectionSynchronisation.IMAGE },
        { title: 'product', property: EConnectionSynchronisation.PRODUCT }
    ];

    constructor(private activeRoute: ActivatedRoute,
                private connectionService: ConnectionService,
                private connectionApiService: ConnectionApiService,
                private fullModalService: FullModalService,
                private dialogService: NucDialogService) {
        super();
    }

    public ngOnInit(): void {
        this.activeRoute.params.subscribe((params) => {
            this.connectionId = params.connectionId;
            this.getConnection();
        });
    }

    private getConnection(): void {
        this.connectionApiService.findOne(this.connectionId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (connection: ConnectionModel) => {
                    this.connection = connection;
                },
                error: Toaster.handleApiError
            });
    }

    public editConnection() {
        // Get fresh copy of the connection
        this.connectionApiService.findOne(this.connectionId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: connection => {
                    this.connection = connection;

                    const modalConfig = new FullModalConfig('Edit connection',
                        'You can edit basic connection information.', {connection});
                    modalConfig.confirmClose = true;

                    const modalRef = this.fullModalService.open(ConnectionFormComponent, modalConfig);
                    modalRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
                        return res ? this.getConnection() : null;
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public openCreateImageSynchronisation(): void {
        const modalConfig = new FullModalConfig('Add image synchronisation',
            'Enter the information to create a new image synchronisation.',
            { connectionId: this.connectionId });

        this.fullModalService.open(SuperunieAdamImageSynchronisationFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => {
                return result ? this.getConnection() : null;
            });
    }

    public openCreateProductSynchronisation(): void {
        const modalConfig = new FullModalConfig('Add product synchronisation',
            'Enter the information to create a new product synchronisation.',
            { connectionId: this.connectionId });

        this.fullModalService.open(SuperunieAdamProductSynchronisationFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => {
                return result ? this.getConnection() : null;
            });
    }

    public onMenuItemClicked(type: EConnectionSynchronisation): void {
        switch (type) {
            case EConnectionSynchronisation.IMAGE:
                this.openCreateImageSynchronisation();
                return;
            case EConnectionSynchronisation.PRODUCT:
                this.openCreateProductSynchronisation();
                return;
            default:
                return;
        }
    }

    public onEditSynchronisationClicked (synchronisationId: string) {
        this.connectionApiService.findOne(this.connectionId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: connection => {
                    this.connection = connection;

                    const currentImageSync =
                        connection.synchronisations.images?.find(sync => sync._id === synchronisationId);
                    const currentProductSync =
                        connection.synchronisations.products?.find(sync => sync._id === synchronisationId);

                    if (!currentImageSync && !currentProductSync) {
                        Toaster.error('Synchronisation not found');
                        return;
                    }

                    let component;
                    if (currentImageSync) {
                        component = SuperunieAdamImageSynchronisationFormComponent;
                    } else if (currentProductSync) {
                        component = SuperunieAdamProductSynchronisationFormComponent;
                    }

                    const modalConfig = new FullModalConfig('Edit synchronisation',
                        'Configure synchronisation.', {
                            connectionId: this.connectionId,
                            synchronisationData: currentImageSync || currentProductSync
                        } as IConnectionSynchronisationFormComponentData);
                    const modalRef = this.fullModalService.open(component, modalConfig);
                    modalRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((result) => {
                        return result ? this.getConnection() : null;
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public openDeleteDialog(synchronisationId: string): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete synchronisation',
            'Please confirm that you wish to delete this synchronisation.');
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteSynchronisation(synchronisationId);
        });
    }

    private deleteSynchronisation(synchronisationId: string) {
        const currentImageSync =
            this.connection.synchronisations.images?.find(sync => sync._id === synchronisationId);

        const currentProductSync =
            this.connection.synchronisations.products?.find(sync => sync._id === synchronisationId);
        if (!currentImageSync && !currentProductSync) {
            Toaster.error('Synchronisation not found');
            return;
        }

        if (currentImageSync) {
            this.connectionService.deleteImageSynchronisation(this.connectionId, synchronisationId)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: () => {
                        Toaster.success('Synchronisation removed');
                        this.getConnection();
                    },
                    error: Toaster.handleApiError
                });
        } else if (currentProductSync) {
            this.connectionService.deleteProductSynchronisation(this.connectionId, synchronisationId)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: () => {
                        Toaster.success('Synchronisation removed');
                        this.getConnection();
                    },
                    error: Toaster.handleApiError
                });
        }

    }
}
