<form [formGroup]="form">
    <nuc-checkbox
        formControlName="enabled"
        text="Enable image syncing">
    </nuc-checkbox>
    <nuc-form-field label="Regular expression">
        <nuc-regex-input
            formControlName="regexFilter">
        </nuc-regex-input>
    </nuc-form-field>
    <nuc-form-field label="Product data field">
        <nuc-dropdown
            formControlName="productDataField"
            placeholder="Select a product data field which is used to link the image to"
            [searchable]="true"
            [nullOption]="false"
            [items]="dataFieldOptions"
            [required]="true"
            (requestData)="searchProductDataFields($event)">
        </nuc-dropdown>
    </nuc-form-field>
    <nuc-checkbox
        formControlName="removeLeadingZeros"
        text="Remove leading zeros from image identifier">
    </nuc-checkbox>
</form>
